import styled from 'styled-components';
import { Base, Theme } from 'styled';

type ImageWithCaptionProps = {
  inlineWidthInPercentage: number;
};

export const ImageWithCaption = styled(Base)<ImageWithCaptionProps>`
  margin-bottom: 1.5rem;

  ${Theme.mediaQueries.sm} {
    width: ${({ inlineWidthInPercentage }) => `${inlineWidthInPercentage}%`};
    float: left;
    clear: right;
    margin-right: 1.5rem;
    margin-bottom: 0;
  }
`;

export const Caption = styled.p`
  ${({ theme }) => theme.typography.variants.primary.xs};
  color: ${({ theme }) => theme.palette.gray[90]};
  margin-top: 0.75rem;
`;
