import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import { PreloadContainer, Container, Placeholder } from './style';

import getImageRatio from 'utils/getImageRatio';
import { ImageDimensions, ImageCrop } from 'types';

const defaultProps = {
  useLazyLoadComponent: true,
  children: null,
  className: '',
  preloadContainerClassName: '',
  style: {},
};

export type Props = {
  useLazyLoadComponent: boolean;
  alt: string;
  className: string;
  preloadContainerClassName: string;
  dimensions?: ImageDimensions;
  sizes?: string;
  src: string;
  srcSet?: string;
  style: React.CSSProperties;
  crop?: ImageCrop | null;
  useOriginalImageSize?: boolean;
  preloadFullWidth?: boolean;
  preloadContainerStyle?: React.CSSProperties;
} & typeof defaultProps;

const Img = (props: Props) => {
  const {
    useLazyLoadComponent,
    className,
    preloadContainerClassName,
    src,
    alt,
    style,
    srcSet,
    sizes,
    dimensions,
    crop,
    useOriginalImageSize,
    preloadFullWidth,
    preloadContainerStyle,
  } = props;

  let formatted_src =
    src.includes('cdn.sanity.io') && !src.includes('?')
      ? `${src}?auto=format`
      : src;

  if (dimensions) {
    formatted_src =
      src.includes('cdn.sanity.io') && !src.includes('&')
        ? `${formatted_src}&w=${dimensions.width}&h=${dimensions.height}`
        : formatted_src;
    return (
      <PreloadContainer
        className={preloadContainerClassName}
        position="relative"
        preloadFullWidth={preloadFullWidth}
        style={preloadContainerStyle}
      >
        <Placeholder
          className={className}
          height={useOriginalImageSize ? dimensions.height : undefined}
          width={useOriginalImageSize ? dimensions.width : undefined}
          pt={
            !useOriginalImageSize
              ? `${getImageRatio(dimensions, crop)}%`
              : undefined
          }
        />
        <Container
          as={useLazyLoadComponent ? LazyLoadImage : 'img'}
          className={className}
          position="absolute"
          top={0}
          right={0}
          left={0}
          height="100%"
          style={style}
          src={formatted_src}
          srcSet={srcSet}
          sizes={sizes}
          alt={alt}
        />
      </PreloadContainer>
    );
  }

  return (
    <Container
      as={useLazyLoadComponent ? LazyLoadImage : 'img'}
      className={className}
      style={style}
      src={formatted_src}
      srcSet={srcSet}
      sizes={sizes}
      alt={alt}
    />
  );
};

Img.defaultProps = defaultProps;

export default Img;
