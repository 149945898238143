const linkIsExternal = (link: string): boolean => {
  if (process.browser) {
    const element = window.document.createElement('a');
    element.href = link;

    return window.location.host !== element.host;
  }

  const linkIsInternal = link.startsWith('/');
  return !linkIsInternal;
};

export default linkIsExternal;
