import { LAUNCHDARKLY_USERID } from 'constants/Experimentation';
import { EventSchema, trackCustomEvent } from './snowplow';

type Exposure = {
  testName: string;
  testVariant: boolean | number | string;
};

export function getLaunchDarklyUserId(cookie?: string) {
  if (!cookie) {
    return null;
  }

  const matcher = new RegExp(`${LAUNCHDARKLY_USERID.cookieName}=([^;]+);?`);
  const match = cookie.match(matcher);

  return match?.[1] || null;
}

export function logExposure(options: Exposure) {
  if (typeof window !== 'undefined') {
    const { testName, testVariant } = options;
    const userId = getLaunchDarklyUserId(document.cookie);
    if (userId) {
      trackCustomEvent(EventSchema.ExperimentationExposure, {
        segmentationAttr: userId,
        segmentationAttrName: LAUNCHDARKLY_USERID.cookieName,
        testName: testName,
        variant: testVariant,
      });
    }
  }
}
