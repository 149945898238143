import memoize from 'lodash/memoize';
import get from 'utils/get';

import sanitizeDrugReview from 'state/sanitizers/sanitizeDrugReview';

import { DrugReview, DrugReviews } from 'types';

export default memoize((response: unknown, pageNumber: number): DrugReviews => {
  const sanitizedReviews = get(response, 'results', []).map(
    (drugReview: unknown): DrugReview =>
      sanitizeDrugReview(drugReview, pageNumber),
  );

  return {
    totalCount: get(response, 'count', 0),
    lastFetchedPageNumber: pageNumber,
    paginatedReviews: {
      [pageNumber]: sanitizedReviews,
    },
    reviewsById: sanitizedReviews.reduce(
      (reviewsById: DrugReviews['reviewsById'], review: DrugReview) => {
        reviewsById[review.id] = review;
        return reviewsById;
      },
      {},
    ),
  };
});
