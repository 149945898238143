import styled, { css } from 'styled-components';
import { BasePortableTextProps, ArticlePortableTextProps } from './types';
import Theme from '.././theme';
import { Base } from './base';

export const PortableTextButton = styled(Base)``;
export const PortableTextLink = styled(Base)``;
export const PortableTextH1 = styled.p``;
export const PortableTextH2 = styled.p``;
export const PortableTextH3 = styled.p``;
export const PortableTextH4 = styled.p``;

export const ArticleHeadingStyles = css`
  h1,
  ${PortableTextH1} {
    ${Theme.typography.variants.primary.xl};

    ${Theme.mediaQueries.md} {
      font-size: ${Theme.typography.size.xxl};
      line-height: ${Theme.typography.lineHeight.xxl};
    }
  }

  h2,
  ${PortableTextH2} {
    ${Theme.typography.variants.primary.lg};
  }

  h3,
  ${PortableTextH3} {
    ${Theme.typography.variants.primary.md};
  }

  h4,
  ${PortableTextH4} {
    ${Theme.typography.variants.primary.sm};
    font-size: 1.25rem;
    line-height: 1.5rem;
  }
`;

export const BasePortableText = styled.div<BasePortableTextProps>`
  h1,
  h2,
  h3,
  h4,
  p,
  ul,
  ol,
  li,
  ${PortableTextH1}, ${PortableTextH2}, ${PortableTextH3}, ${PortableTextH4} {
    color: ${({ textColor }) => textColor || 'inherit'};
  }

  div p {
    margin-bottom: 1rem;
  }

  ul,
  ol {
    margin-bottom: 1.5rem;
  }

  ul > li {
    margin-bottom: 0.5rem;
    padding-left: 1.5rem;
    position: relative;

    ::before {
      content: '•';
      transform: translate3d(-1rem, 0, 0);
      position: absolute;
      left: 1rem;
    }
  }

  /* Nested li */
  li {
    ul {
      margin-top: 0.75rem;
      margin-left: 0rem;
    }
  }

  ol:first-child {
    counter-reset: customlistcounter;
  }

  ol > li {
    counter-increment: customlistcounter;
    margin-bottom: 0.75rem;
    padding-left: 1.5rem;
    position: relative;

    ::before {
      content: counter(customlistcounter) '.';
      transform: translate3d(-1rem, 0, 0);
      position: absolute;
      left: 1rem;
    }
  }

  /* Nested li */
  li {
    ol {
      margin-top: 0.75rem;
      margin-left: 0rem;
    }
  }

  /* Counter style for nested ols */
  ol {
    ol {
      /* Level 2: "a" */
      li::before {
        content: counter(customlistcounter, lower-alpha) '.';
      }

      /* Level 3: "i" */
      ol {
        li::before {
          content: counter(customlistcounter, lower-roman) '.';
        }

        /* Level 4 = "1" */
        ol li::before {
          content: counter(customlistcounter) '.';
        }
      }
    }
  }

  em {
    font-style: italic;
  }

  strong {
    font-weight: ${Theme.typography.weight.bold};
  }

  /* Text links to be blue by default */
  ${PortableTextButton},
  ${PortableTextLink} {
    display: inline;
    color: ${({ theme, textColor }) => textColor || theme.palette.primary.main};

    > span {
      ${({ theme, textColor }) =>
        theme.hoverStyles.linkBorderBottom(
          textColor || theme.palette.primary.main,
        )};
    }

    :hover,
    :focus {
      > span {
        ${({ theme, textColor }) =>
          theme.hoverStyles.linkBorderBottomHidden(
            textColor || theme.palette.primary.main,
          )};
      }
    }
  }

  p > .text-sans-serif {
    ${Theme.typography.variants.primary.sm};
  }
`;

export const ArticlePortableText = styled(
  BasePortableText,
)<ArticlePortableTextProps>`
  ${ArticleHeadingStyles};

  ${({ fontStyleIsSecondaryMd = false }) =>
    fontStyleIsSecondaryMd
      ? Theme.typography.variants.secondary.md
      : Theme.typography.variants.secondary.sm};

  h1,
  ${PortableTextH1} {
    margin-bottom: 4.5rem;
  }

  h2,
  ${PortableTextH2} {
    margin-bottom: 2rem;
    margin-top: 3rem;
  }

  h3,
  ${PortableTextH3}, h4,
  ${PortableTextH4} {
    margin-bottom: 1.25rem;
    margin-top: 2rem;
  }

  p {
    &:not(:last-child) {
      margin-bottom: 1.5rem;
    }
  }

  p > .text-sans-serif {
    ${Theme.typography.variants.primary.sm};
  }
`;

export const DrawerPortableText = styled(BasePortableText)`
  ${Theme.typography.variants.secondary.sm};

  h1,
  h2,
  h3,
  h4 {
    margin-bottom: 0.5rem;
    ${Theme.typography.variants.primary.sm};
  }

  p {
    margin-bottom: 1rem;
  }
`;

export const TextModulePortableText = styled(BasePortableText)`
  ${ArticleHeadingStyles};

  h1,
  ${PortableTextH1} {
    margin-bottom: 4.5rem;
  }

  h2,
  ${PortableTextH2} {
    margin-bottom: 2rem;
    margin-top: 3rem;
  }

  h3,
  ${PortableTextH3}, h4,
  ${PortableTextH4} {
    margin-bottom: 1.25rem;
    margin-top: 2rem;
  }

  p {
    &:not(:last-child) {
      margin-bottom: 1.5rem;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  ul,
  ol {
    margin-bottom: 0;
  }

  ${PortableTextButton},
  ${PortableTextLink} {
    display: inline;
    /* Hero Text Module has "textColor" prop, regular Text Modules do not */
    color: ${({ textColor }) => textColor};

    > span {
      ${({ theme, textColor }) =>
        textColor && theme.hoverStyles.linkBorderBottom(textColor)};
    }

    :hover,
    :focus {
      > span {
        ${({ theme, textColor }) =>
          textColor && theme.hoverStyles.linkBorderBottomHidden(textColor)};
      }
    }
  }
`;

export const QuizTextModulePortableText = styled(BasePortableText)`
  ${ArticleHeadingStyles};

  h1,
  ${PortableTextH1} {
    margin-bottom: 3.5rem;
  }

  h2,
  ${PortableTextH2} {
    margin-bottom: 2rem;
  }

  h3,
  ${PortableTextH3}, h4,
  ${PortableTextH4} {
    margin-bottom: 1.25rem;
  }

  p {
    &:not(:last-child) {
      margin-bottom: 1.5rem;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  ul,
  ol {
    margin-bottom: 0;
  }

  ${PortableTextButton},
  ${PortableTextLink} {
    display: inline;
    /* Hero Text Module has "textColor" prop, regular Text Modules do not */
    color: ${({ textColor }) => textColor};

    > span {
      ${({ theme, textColor }) =>
        textColor && theme.hoverStyles.linkBorderBottom(textColor)};
    }

    :hover,
    :focus {
      > span {
        ${({ theme, textColor }) =>
          textColor && theme.hoverStyles.linkBorderBottomHidden(textColor)};
      }
    }
  }
`;

export const CareOverviewPortableText = styled(BasePortableText)`
  ${Theme.typography.variants.secondary.sm};

  && {
    ul,
    p {
      margin-bottom: 0;
    }
  }
`;

export const CareSubtypesPortableText = styled(BasePortableText)`
  && {
    h4 {
      ${Theme.typography.variants.primary.sm};
      margin-bottom: 0.25rem;
    }

    ul {
      margin: 1.5rem 0;
    }
  }
`;

export const CtaModulePortableText = styled(ArticlePortableText)`
  && {
    h2,
    ${PortableTextH2}, h3,
    ${PortableTextH3} {
      margin-top: 0;
      margin-bottom: 1.5rem;
    }

    p:last-child {
      padding-bottom: 0;
      margin-bottom: 0;
    }

    p {
      &:not(:last-child) {
        margin-bottom: 1rem;
      }
    }

    ul,
    ol {
      margin-bottom: 0;
    }
  }
`;

export const ArticleCalloutPortableText = styled(BasePortableText)`
  ${Theme.typography.variants.secondary.sm};

  && {
    p {
      padding-bottom: 0;
    }

    ol,
    ul {
      margin-bottom: 0;
    }

    h1,
    ${PortableTextH1}, h2,
    ${PortableTextH2}, h3,
    ${PortableTextH3}, h4,
    ${PortableTextH4} {
      margin-top: 0;
      margin-bottom: 0;
    }

    ${PortableTextH2}, h2 {
      ${Theme.typography.variants.primary.md};

      ${Theme.mediaQueries.lg} {
        ${Theme.typography.variants.primary.lg};
      }
    }
  }

  /* Callout Text Modules to have links that conform with text color */
  ${PortableTextButton},
  ${PortableTextLink} {
    display: inline;
    color: ${({ theme, textColor }) => textColor || theme.palette.text.primary};

    > span {
      ${({ theme, textColor }) =>
        theme.hoverStyles.linkBorderBottom(
          textColor || theme.palette.text.primary,
        )};
    }

    :hover,
    :focus {
      > span {
        ${({ theme, textColor }) =>
          theme.hoverStyles.linkBorderBottomHidden(
            textColor || theme.palette.text.primary,
          )};
      }
    }
  }
`;

export const ArticleHighlightedTextModulePortableText = styled(
  BasePortableText,
)`
  ${Theme.typography.variants.secondary.md}
  ${ArticleHeadingStyles};

  h1,
  ${PortableTextH1} {
    margin-bottom: 2rem;
  }

  h2,
  ${PortableTextH2}, h3,
  ${PortableTextH3}, h4,
  ${PortableTextH4} {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  && {
    p {
      padding-bottom: 1rem;
    }

    ul,
    ol {
      margin-bottom: 0;
    }
  }
`;

export const AccordionListModulePortableText = styled(BasePortableText)`
  ${ArticleHeadingStyles};

  p {
    padding-bottom: 1.5rem;
  }

  h1,
  ${PortableTextH1}, h2,
  ${PortableTextH2}, h3,
  ${PortableTextH3}, h4,
  ${PortableTextH4} {
    margin-top: 1rem;
  }
`;

export const MarketingPortableText = styled(BasePortableText)`
  p {
    margin-bottom: 1.5rem;
  }

  h1,
  ${PortableTextH1}, h2,
  ${PortableTextH2} {
    ${Theme.typography.variants.primary.xl};
    margin-bottom: 4.5rem;
  }

  h2,
  ${PortableTextH2} {
    margin-top: 3rem;
  }

  h3,
  ${PortableTextH3} {
    ${Theme.typography.variants.primary.lg};
    margin-bottom: 1.5rem;
    margin-top: 2.25rem;
  }

  h4,
  ${PortableTextH4} {
    ${Theme.typography.variants.primary.md};
    margin-bottom: 1.5rem;
  }

  ${PortableTextButton},
  ${PortableTextLink} {
    display: inline;
    color: ${({ theme }) => theme.palette.common.white};

    > span {
      ${({ theme }) =>
        theme.hoverStyles.linkBorderBottom(theme.palette.common.white)};
    }

    :hover,
    :focus {
      > span {
        ${({ theme }) =>
          theme.hoverStyles.linkBorderBottomHidden(theme.palette.common.white)};
      }
    }
  }
`;

export const DrugPagePortableText = styled(BasePortableText)`
  ${Theme.typography.variants.secondary.sm};

  ${PortableTextH1},
  ${PortableTextH2} {
    ${({ theme }) => theme.typography.variants.primary.md};

    ${Theme.mediaQueries.md} {
      ${({ theme }) => theme.typography.variants.primary.lg};
    }
  }

  ${PortableTextH3}, ${PortableTextH4} {
    ${({ theme }) => theme.typography.variants.primary.md};
  }

  ${PortableTextH1},
  ${PortableTextH2} {
    margin-top: 3rem;
    margin-bottom: 2rem;
  }

  ${PortableTextH3},
  ${PortableTextH4} {
    margin-top: 2rem;
    margin-bottom: 1.5rem;
  }

  p {
    &:not(:last-child) {
      margin-bottom: 1.5rem;
    }
  }

  p > .text-sans-serif {
    ${Theme.typography.variants.primary.sm};
  }

  ul,
  ol {
    margin-left: 1rem;
    margin-bottom: 0;
  }

  ul > li {
    &:last-child {
      margin-bottom: 0;
    }
  }

  ${PortableTextButton},
  ${PortableTextLink} {
    display: inline;

    > span {
      color: ${({ theme }) => theme.palette.primary.main};

      ${({ theme }) =>
        theme.hoverStyles.linkBorderBottom(theme.palette.primary.main)};
    }

    :hover,
    :focus {
      > span {
        ${({ theme }) =>
          theme.hoverStyles.linkBorderBottomHidden(theme.palette.primary.main)};
      }
    }
  }
`;

export const VerifiedByExpertsPortableText = styled.div<BasePortableTextProps>`
  h1,
  h2,
  h3,
  h4,
  p,
  ul,
  ol,
  li,
  ${PortableTextH1}, ${PortableTextH2}, ${PortableTextH3}, ${PortableTextH4} {
    color: ${({ theme, textColor }) => textColor || theme.palette.text.primary};
    ${Theme.typography.variants.primary.sm};
  }

  div p {
    &:not(:last-child) {
      margin-bottom: 1.5rem;
    }
  }

  em {
    font-style: italic;
  }

  strong {
    font-weight: ${Theme.typography.weight.bold};
  }

  /* Text links to be blue by default */
  ${PortableTextButton},
  ${PortableTextLink} {
    display: inline;
    color: ${({ theme }) => theme.palette.primary.main};

    > span {
      ${({ theme }) =>
        theme.hoverStyles.linkBorderBottom(theme.palette.primary.main)};
    }

    :hover,
    :focus {
      > span {
        ${({ theme }) =>
          theme.hoverStyles.linkBorderBottomHidden(theme.palette.primary.main)};
      }
    }
  }
`;

export const BaseWithHeaderStylesPortableText = styled(BasePortableText)`
  ${ArticleHeadingStyles};
`;
