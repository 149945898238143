import React, { FC } from 'react';
import { Svg } from 'styled/components/base';

type Props = {
  className?: string;
};

const TwoPeopleDiscussingTreatment: FC<Props> = ({ className = '' }) => (
  <Svg
    width="100%"
    height="100%"
    viewBox="0 0 400 400"
    fill="transparent"
    className={className}
  >
    <desc>Illustration of two people discussing treatment.</desc>
    <path
      fill="#4C3731"
      d="M173.7,213.4c-0.1,1.9,0.4,4.6,2.3,4.5c1,0,1.8-1,2.3-1.9c7.3-12.3,13-27.9,2.4-56.7c0,0-9.1,12.5-13.2,9.9c-4.3-2.7-1.6-9.1-6.7-8.9c-5.8,0.2-16.8,0.7-19.1,7.7c-2.5,7.8,6.4,10.5,11.6,13.5C165.4,188.2,174.3,198.7,173.7,213.4z"
    />
    <path
      fill="#415546"
      d="M216.1,360.1H28c-0.8-21.2,0.7-53.1,15.9-70c18-20.1,40.9-26.8,65-27.5c1.3,0,2.6-0.1,3.9-0.1c11.8-0.5,34.2-0.2,53.9,0.3c18.4,0.5,34.4,1.2,37.4,1.8c30.3,5.3,20.8,35.8,27.1,50.7C236.6,327.6,216.1,360.1,216.1,360.1z"
    />
    <path
      fill="#F3AB96"
      d="M173.5,174.9c0-16.9-17.4-1.7-19.4-3.5c-6.7-6.2-17-13.2-25.8-10.8c-16.3,4.4-31.4,7.9-33.9,24.6c-2.4,16,8.2,54.9,21.6,64.8c0.3,0.2,0.6,0.4,0.9,0.6c0,4.5,0.2,8.8,0.3,11.7c-1.5,0-3,0.1-4.3,0.1c-1.3,0.1-2.6,0.1-3.9,0.1c-0.9,3.8-0.1,7.9,1.5,11.6c3.9,8.6,12.9,15.6,23.4,17.3c10.5,1.7,22.2-2.3,27.9-10.1c3.6-5,4.8-11,5-16.8c0-0.6,0-1.2,0-1.8c-1.9-0.1-3.8-0.1-5.7-0.1c0-3.1,0-6.4,0-9.6c0.4-0.2,0.8-0.3,1.1-0.5C191.1,238.7,173.5,185.1,173.5,174.9z"
    />
    <path
      fill="#F7DED7"
      d="M151.3,74c-4.3-22.4-24.9-31-46-27.1c-12.2,2.3-24.7,14.3-25.9,28.6C77.6,96.4,89,105.4,95.8,109c1.7,0.9,3.5,1.7,5.4,2.4c0.4,4.1,1.6,8.6,4,12.1c2.9,4.4,11.3,10,21.4,10c-6.3-6.9-7.6-13.1-6.6-19.3c4.9-0.3,9.7-1.5,14-3.7C145.4,104.5,154.1,88.6,151.3,74z"
    />
    <path
      fill="#F8EDEB"
      d="M142.3,208.9c0-3.9-3.8-7.1-8.6-7.5c-0.3,0-0.6,0-1,0c-5.3,0-9.6,3.4-9.6,7.5c0,4.1,4.3,7.5,9.6,7.5c0.3,0,0.7,0,1,0C138.5,216,142.3,212.8,142.3,208.9z"
    />
    <path
      fill="#4C3731"
      d="M142.3,208.9c0-3.9-3.8-7.1-8.6-7.5c-1.8,1.9-2.8,4.5-2.8,7.5c0,2.9,1.1,5.6,2.9,7.5C138.5,216,142.3,212.8,142.3,208.9z"
    />
    <path
      fill="#F8EDEB"
      d="M174.5,208.9c0-3.9-3.8-7.1-8.6-7.5c-0.3,0-0.6,0-1,0c-5.3,0-9.6,3.4-9.6,7.5c0,4.1,4.3,7.5,9.6,7.5c0.3,0,0.7,0,1,0C170.7,216,174.5,212.8,174.5,208.9z"
    />
    <path
      fill="#4C3731"
      d="M174.5,208.9c0-3.9-3.8-7.1-8.6-7.5c-1.8,1.9-2.8,4.5-2.8,7.5c0,2.9,1.1,5.6,2.9,7.5C170.7,216,174.5,212.8,174.5,208.9z"
    />
    <path
      fill="#C6806C"
      d="M239.5,226.1v-12.3c0-2.4-2-4.4-4.4-4.4h-1.3c-2.4,0-4.4,2-4.4,4.4v10.8c-0.2,0.5-0.2,1.1-0.2,1.6c-0.1-2.8-2.5-5.1-5.3-5c-2.8,0.1-5.1,2.5-5,5.3c-0.1-2.8-2.5-5.1-5.3-5c-2.5,0.1-4.5,1.9-4.9,4.3c-0.6-2.4-2.9-4.1-5.5-3.9c-2.8,0.3-4.7,2.8-4.6,5.6l1.3,38.7h40L239.5,226.1z"
    />
    <path
      fill="#C6806C"
      d="M342.4,238.4c3.9-10.3,2.9-23.6,1.3-34.3c-2.4-16.1-14.8-30.2-30.5-34.5c-8.5-2.3-18.5-1.5-25,4.5c-1.9,1.7-12.1,2.9-12.1,19.2c0,9.7-7.2,11-8,14.1c-1.3,4.8,4.6,5.3,5.3,7.8c0.8,3-0.5,10.2,0.3,16.2c1,7.7,2.1,11.1,7.5,16.7c2.1,2.2,5.2,3.9,8.9,5.1c0.2,8-0.2,15-2.3,14.8c14.2,1,28.4,1.5,42.6,1.3c3.1,0,8.2,31.6,10.6,29.5c2.7-2.3,1.5-38,1.7-41.5C342.9,251,342.8,244.7,342.4,238.4z"
    />
    <path
      fill="#415546"
      d="M360.4,150.2c-5-5.2-13.1-7.1-19.9-4.8c-4.8,1.6-9,5.4-10.2,10.3c0,0.1,0,0.1,0,0.2c-15-5.6-33.7-3.8-46.3,0.2c-16,5-19.8,17.1-17.9,23.8c6.4,22.8,35.2,12,42.5,14.7c5.7,2.1,7.8,10.6,6.2,21.2c-1.1,7-9.6,26.8,6.9,27.6c35,1.7,43.3-33.8,32.4-62.3c4.8-2,8.7-6,10.6-10.9C367.1,163.5,365.4,155.4,360.4,150.2z"
    />
    <path
      fill="#F7DED7"
      d="M230.8,44.1c-19.4-4.7-45.7,4.9-56.9,25.1c-16.3,29.4-5.5,50.3,2.2,60c8.7,11.1,21.8,20.5,36,24.8c1.5,7,7.8,15.1,15.9,20.4c6.7,4.4,16.6,8.4,24.5,9.9c-8.3-8.9-11.1-19.1-11-29.8c17.7-5.3,34.8-20.9,39.4-40.5C289.1,78.3,264.3,52.2,230.8,44.1z"
    />
    <circle fill="#DD9581" cx="289.9" cy="202.1" r="14.6" />
    <path
      fill="#F8EDEB"
      d="M278.8,202.1c0-3.7,3.6-6.8,8.3-7.2c0.3,0,0.6,0,0.9,0c5.1,0,9.2,3.2,9.2,7.2c0,4-4.1,7.2-9.2,7.2c-0.3,0-0.6,0-0.9,0C282.5,208.9,278.8,205.8,278.8,202.1z"
    />
    <path
      fill="#4C3731"
      d="M278.8,202.1c0-3.7,3.6-6.8,8.3-7.2c1.7,1.8,2.7,4.4,2.7,7.2c0,2.8-1.1,5.4-2.7,7.2C282.5,208.9,278.8,205.8,278.8,202.1z"
    />
    <path
      fill="#F7DED7"
      d="M372.8,359.1c0,0-5.4-43.2-9.1-57.3c-3.6-14.1-11.3-27.7-23.7-35.9c-16.3-10.9-39-10.7-56.6-2c-6.3,3.1-42.1,28.6-42.1,28.6l-0.3-42.4l-45,5.9l0.3,66.2l-0.1,0c0,0,0.3,14.2,0.7,21.4c0.7,12,9.8,16.8,19.6,16.3c0,0,0.1,0,0.1,0C258,359.7,372.8,359.1,372.8,359.1z"
    />
    <path
      fill="#4C6551"
      d="M244.7,359.5l-0.2-1c22.9-4.2,44.6-14.1,62.8-28.7l0.6,0.8C289.6,345.2,267.7,355.2,244.7,359.5z"
    />
    <polygon
      fill="#DD9581"
      points="261.7,120.4 258.3,117.1 253.1,122.2 243.8,112.9 253,103.7 249.6,100.3 240.4,109.5 240.4,109.5 244.8,105.1 227.1,87.4 214.8,99.7 232.5,117.4 236.9,113 236.9,113 227.7,122.2 231.1,125.6 240.3,116.4 249.6,125.7 244.5,130.9 247.8,134.3 253,129.1 253,129.1 256.5,125.6 256.5,125.6 			"
    />
    <rect
      x="190"
      y="70.9"
      transform="matrix(0.7071 0.7071 -0.7071 0.7071 109.5947 -120.0301)"
      fill="#415546"
      width="19.4"
      height="2.8"
    />
    <polygon
      fill="#415546"
      points="214.8,99.7 227.1,87.4 214.8,75.1 200.5,73.1 202.5,87.4 				"
    />
    <rect
      x="220.1"
      y="86.8"
      transform="matrix(0.7071 0.7071 -0.7071 0.7071 132.818 -129.6495)"
      fill="#F8EDEB"
      width="5.6"
      height="17.4"
    />
    <polygon
      fill="#415546"
      points="127.9,66.5 109.9,84.5 103,77.7 95.3,85.4 102.1,92.3 109.9,100 117.6,92.3 135.6,74.3 		"
    />
    <path
      fill="#4C3731"
      d="M104.1,232.9c0,0-1.5-4.2-2.8-10.3c-1.1-5.1-2.1-12.1-0.9-14.2c4.8-8.1,10.7,5.7,11.2,1.6c1-8.8-1.2-21.5,6.9-27.4c10.7-7.8,24.7-1.7,38.4-1.4c14.6,0.3,17.7-2.3,19.4-4.2c2.8-3,4.7,0.7,5.5-6.8c1-9.7-5.5-28.4-15.3-28.2c-2.4,0-5.1,0.1-8.1,0.1c-8.1,0-35.8,1.1-48.8,7.9c-7.6,4-16.6,14.4-17.3,31C91.1,208.4,95.6,232.2,104.1,232.9z"
    />
  </Svg>
);

export default TwoPeopleDiscussingTreatment;
