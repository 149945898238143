import { Action, DrugPage } from 'types';

export interface DrugPageReducer {
  [slug: string]: DrugPage;
}

const initialState: DrugPageReducer = {};

const drugPageReducer = (
  state = initialState,
  action: Action
): DrugPageReducer => {
  switch (action.type) {
    case 'FETCH_DRUG_PAGE_FULFILLED':
      if (!action.payload) return state;

      return {
        ...state,
        [action.payload.id]: action.payload,
      };
    case 'UPVOTE_DRUG_PAGE_FULFILLED':
      if (!action.payload) return state;

      return {
        ...state,
        [action.payload.id]: {
          ...state[action.payload.id],
          ratings: {
            ...state[action.payload.id].ratings,
            likes: action.payload.likes,
          },
        },
      };
    case 'FETCH_DRUG_REVIEWS_FULFILLED':
      const reviews =
        action.payload.paginatedReviews[action.payload.lastFetchedPageNumber];

      if (!action.payload || !reviews.length) return state;

      const drugPageId = reviews[0].drugPageId;

      return {
        ...state,
        [drugPageId]: {
          ...state[drugPageId],
          reviews: {
            ...state[drugPageId].reviews,
            paginatedReviews: {
              ...state[drugPageId].reviews.paginatedReviews,
              ...action.payload.paginatedReviews,
            },
            reviewsById: {
              ...state[drugPageId].reviews.reviewsById,
              ...action.payload.reviewsById,
            },
          },
        },
      };
    case 'UPVOTE_DRUG_REVIEW_FULFILLED':
      const { id, likes, pageId } = action.payload;

      if (!id || !likes || !pageId) return state;

      return {
        ...state,
        [pageId]: {
          ...state[pageId],
          reviews: {
            ...state[pageId].reviews,
            paginatedReviews: {
              ...state[pageId].reviews.paginatedReviews,
              [state[pageId].reviews.reviewsById[id].pageNumber]: state[
                pageId
              ].reviews.paginatedReviews[
                state[pageId].reviews.reviewsById[id].pageNumber
              ].map((review) => {
                if (review.id === id)
                  return {
                    ...review,
                    ratings: {
                      likes,
                    },
                  };

                return review;
              }),
            },
            reviewsById: {
              ...state[pageId].reviews.reviewsById,
              [id]: {
                ...state[pageId].reviews.reviewsById[id],
                ratings: {
                  likes,
                },
              },
            },
          },
        },
      };
    default:
      return state;
  }
};

export default drugPageReducer;
