import React, { FC } from 'react';
import theme from '@buoyhealth/common.buoy-theme';
import { Svg } from 'styled/components/base';
import { Color } from 'styled/theme/colors';

type Props = {
  className?: string;
  color?: Color;
  width?: string;
  height?: string;
};

const RightArrow: FC<Props> = ({
  className = '',
  color = theme.palette.primary.main,
  width = '14',
  height = '12',
}) => (
  <Svg
    width={width}
    height={height}
    viewBox="0 0 14 12"
    fill="none"
    className={className}
  >
    <path
      d="M13.3392 6.16667L7.76816 11.8333L6.52397 10.6101L10.3308 6.9582H0.00585938V5.37513H10.3308L6.52397 1.72328L7.76816 0.5L13.3392 6.16667Z"
      fill={color}
    />
  </Svg>
);

export default RightArrow;
