import React from 'react';
import Script from 'next/script';
import useFeatureFlags from 'hooks/useFeatureFlags';

function Ads() {
  const featureFlags = useFeatureFlags();

  const adsEnabled = featureFlags?.['growth-public-site-mediavine-enabled'];

  return (
    adsEnabled && (
      <Script
        async
        data-cfasync="false"
        data-noptimize="1"
        src="//scripts.mediavine.com/tags/buoy-health-1.js"
      />
    )
  );
}

export default Ads;
