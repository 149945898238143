import { useContext } from 'react';
import PageContext from 'contexts/PageContext';

function usePage() {
  const page = useContext(PageContext);

  return page;
}

export default usePage;
