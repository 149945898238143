import sanityImgUtil from 'utils/sanityImgUtil';

import { Image } from 'types';

const sanityImgSrcSetUtil = (
  image: Image,
  smImageWidth: number,
  mdImageWidth: number,
  lgImageWidth: number,
  quality = 70
): string => {
  if (!image) return '';

  const smImagePath = sanityImgUtil(image, smImageWidth, undefined, quality);
  const mdImagePath = sanityImgUtil(image, mdImageWidth, undefined, quality);
  const lgImagePath = sanityImgUtil(image, lgImageWidth, undefined, quality);

  return `${smImagePath} ${smImageWidth}w, ${mdImagePath} ${mdImageWidth}w, ${lgImagePath} ${lgImageWidth}w`;
};

export default sanityImgSrcSetUtil;
