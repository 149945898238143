import React from 'react';
import Script from 'next/script';
import { GDPR_COUNTRIES } from 'constants/CountryCodes';
import useFeatureFlags from 'hooks/useFeatureFlags';

/**
 * The following outlines the regulations governing cookies where Buoy does business (as of 07/2024):
 *   GDPR (EU): "Opt-out" by default, requires "opt-in" before using cookies *except* strictly necessary cookies.
 *   CCPA (California): "Opt-in" by default, requires a mechanism for "opt-out" and disclosure of data collection practices.
 *
 * Cookiebot, our Consent Management Platform (CMP), facilitates compliance with GDPR and CCPA by:
 *   - displaying a consent banner to users who visit our website
 *   - capturing and record user consent
 *   - communicating user preferences to advertising partners (e.g. Playwire) and vendors through
 *     the IAB Transparency & Consent Framework (TCF)
 */
function ConsentManagement() {
  const featureFlags = useFeatureFlags();

  const cookiebotConfig =
    featureFlags?.['growth-public-site-cookiebot-configuration'] || {};

  if (!cookiebotConfig?.enabled) {
    return null;
  }

  return (
    <Script
      data-blockingmode="auto"
      data-cbid="20a05637-e6a2-4482-bddf-ea400787b4f3"
      data-framework="TCFv2.2"
      // FIXME: disable Cookiebot for GDPR countries for now in favor of Google CMP managed by Playwire
      // data-georegions={`{'region':'${GDPR_COUNTRIES.join(', ')}','cbid':'7111f24d-4152-4a21-a63e-ea4069a93544'}`}
      id="Cookiebot"
      src="https://consent.cookiebot.com/uc.js"
      strategy="afterInteractive"
      type="text/javascript"
      {...cookiebotConfig}
    />
  );
}

export default ConsentManagement;
