import styled from 'styled-components';
import { Base } from 'styled/components/base';
import { Props } from './index';

type PreloadContainerProps = Pick<Props, 'preloadFullWidth'>;

export const PreloadContainer = styled(Base)<PreloadContainerProps>`
  ${({ theme }) => theme.transitions['transition-opacity-short']};
  /* Equivalent to preloadContainerClassName set to "w100" */
  width: ${({ preloadFullWidth }) => (preloadFullWidth ? '100%' : undefined)};
  break-inside: avoid;
`;

export const Container = styled(Base).withConfig({
  shouldForwardProp: (prop, defaultValidatorFn) =>
    !['height'].includes(prop) && defaultValidatorFn(prop),
})`
  ${({ theme }) => theme.transitions['transition-opacity-short']};
`;

export const Placeholder = styled(Base)`
  pointer-events: none;
`;
