import get from 'lodash/get';
import { Action, BlogPost } from 'types';

export interface BlogReducer {
  [slug: string]: BlogPost;
}
const initialState: BlogReducer = {};

function blog(state: BlogReducer = initialState, action: Action): BlogReducer {
  switch (action.type) {
    case 'FETCH_BLOG_POST_FULFILLED':
      if (!action.payload) {
        return state;
      }

      return {
        ...state,
        [get(action, 'payload.slug', '')]: action.payload,
      };
    default:
      return state;
  }
}

export default blog;
