import getHost from 'utils/getHost';

const isBuoyDev = () => {
  /*
   * Server
   */
  if (typeof window === 'undefined') {
    return process.env.ENVIRONMENT === 'dev';
  }

  /*
   * Browser
   */
  const host = getHost();

  return host.includes('dev.buoyhealth.com');
};

export default isBuoyDev;
