import { keyframes } from 'styled-components';

export const leftToRight = keyframes`
  0% {
    width: 0;
  }

  100% {
    width: 100%;
  }
`;

export const fadeOut = keyframes`
  0% {
    opacity: 1;
  }

  99% {
    opacity: 0;
  }

  100% {
    opacity: 0;
    display: none;
  }
`;

export const fadeOutDown = keyframes`
  0% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }

  99% {
    transform: translate3d(0, 10rem, 0);
    opacity: 0;
  }

  100% {
    opacity: 0;
    display: none;
  }
`;

export const fadeIn = keyframes`
  0% {
    display: none;
    opacity: 0;
  }

  1% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
`;

export const fadeInUp = keyframes`
  0% {
    display: none;
    opacity: 0;
    transform: translate3d(0, 10rem, 0);
  }

  1% {
    opacity: 0;
  }

  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
`;

export const fadeInUpShortDistance = keyframes`
  0% {
    visibility: hidden;
    opacity: 0;
    transform: translate3d(0, 1rem, 0);
  }

  1% {
    opacity: 0;
  }

  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
`;

export const open = keyframes`
  0% {
    max-height: 0;
    display: none;
  }

  99% {
    max-height: none;
  }

  100% {
    max-height: none;
  }
`;

export const close = keyframes`
  0% {
    max-height: none;
  }

  99% {
    max-height: 0;
  }

  100% {
    max-height: 0;
    display: none;
  }
`;

export const growInWidth = keyframes`
  0% {
    width: 0;
  }

  100% {
    width: 100%;
  }
`;

export const slideUpWithJump = keyframes`
  0% {
    visibility: hidden;
    transform: translateY(10rem);
  }

  1% {
    opacity: 0;
  }

  25% {
    opacity: 1;
  }

  90% {
    transform: translateY(-0.5rem);
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
`;

export default {
  leftToRight,
  fadeOut,
  fadeOutDown,
  fadeIn,
  fadeInUp,
  fadeInUpShortDistance,
  open,
  close,
  growInWidth,
  slideUpWithJump,
};
