import styled, { css } from 'styled-components';
import { AnchorLabel, Base } from 'styled/components/base';
import Theme from 'styled/theme';
import { Color } from 'styled/theme/colors';
import { Props } from './index';

type AnchorProps = Pick<Props, 'disabled' | 'variant'>;
type AnchorInnerProps = Pick<Props, 'variant' | 'wrap'>;

export const Anchor = styled(Base)<AnchorProps>`
  text-decoration: none;
  pointer-events: ${({ disabled }) => (disabled ? 'none' : undefined)};
  color: ${({ theme }) => theme.palette.text.primary};
  ${({ variant }) => getStylesByVariant(variant)};
`;

export const AnchorInner = styled(Base).withConfig({
  shouldForwardProp: (prop, defaultValidatorFn) =>
    !['wrap'].includes(prop) && defaultValidatorFn(prop),
})<AnchorInnerProps>`
  cursor: pointer;
  text-decoration: none;
  white-space: ${({ wrap }) => (wrap ? 'normal' : 'nowrap')};
  ${({ theme }) => theme.transitions['transition-opacity-short']};
  ${({ variant }) => getInnerStylesByVariant(variant)};

  ${AnchorLabel} {
    white-space: ${({ wrap }) => (wrap ? 'normal' : 'nowrap')};
  }
`;

const underlineColor = (color: Color) => css`
  color: ${color};

  ${AnchorLabel} {
    ${Theme.hoverStyles.linkBorderBottom(color)};
    white-space: normal;

    :hover {
      ${Theme.hoverStyles.linkBorderBottomHidden(color)};
    }
  }
`;

const NavTabWithBorderStyles = css`
  width: 100%;

  ${AnchorInner} {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  :active {
    background-color: ${({ theme }) => theme.palette.gray[0]};
  }
`;

const getStylesByVariant = (variant: AnchorProps['variant']) => {
  switch (variant) {
    case 'primary':
    case 'primary-lg':
    case 'primary-md':
    case 'primary-md-white':
    case 'rectangle-blue':
    case 'rectangle-white':
    case 'hero':
      return css`
        display: inline-flex;
      `;
    case 'nav-menu':
      return css`
        width: 100%;
        display: flex;

        ${AnchorInner} {
          cursor: default;
        }

        &:link {
          ${AnchorInner} {
            cursor: pointer;
          }
        }
      `;
    case 'symptom-checker-white':
    case 'hero':
      return css`
        :active,
        :focus {
          ${AnchorInner} {
            border-color: ${Theme.palette.primary.main};
          }
        }
      `;
    case 'hover-underline-blue':
      return css`
        ${Theme.typography.variants.primary.sm};
        ${Theme.transitions.transition};
        color: ${Theme.palette.primary.main};

        ${AnchorLabel} {
          ${Theme.hoverStyles.linkBorderBottomHidden(
            Theme.palette.primary.main
          )};
        }

        :hover,
        :focus {
          ${AnchorLabel} {
            ${Theme.hoverStyles.linkBorderBottom(Theme.palette.primary.main)};
          }
        }
      `;
    case 'underline-black':
      return css`
        ${({ theme }) => underlineColor(theme.palette.gray[100])}
        ${Theme.typography.variants.link};
      `;
    case 'underline-black-xs':
      return css`
        display: inline;
        align-items: center;
        ${({ theme }) => underlineColor(theme.palette.gray[100])}
        ${Theme.typography.variants.primary.xs};
      `;
    case 'underline-blue':
      return css`
        ${underlineColor(Theme.palette.primary.main)}
        ${Theme.typography.variants.link};
        &:disabled {
          color: ${Theme.palette.text.disabled};
          ${underlineColor(Theme.palette.text.disabled)};
        }
      `;
    case 'underline-blue-xs':
      return css`
        display: inline-flex;
        ${Theme.typography.variants.primary.xs};
        ${underlineColor(Theme.palette.primary.main)}
      `;
    case 'underline-blue-xs-sm-secondary':
      return css`
        display: inline-flex;
        ${Theme.typography.variants.secondary['xs-sm']};
        ${underlineColor(Theme.palette.primary.main)}
      `;
    case 'hover-underline-black':
      return css`
        ${Theme.typography.variants.link};
        color: inherit;

        ${AnchorLabel} {
          ${({ theme }) =>
            theme.hoverStyles.linkBorderBottomHidden(theme.palette.gray[100])};
          white-space: normal;
        }

        :hover,
        :focus {
          ${AnchorLabel} {
            ${({ theme }) =>
              theme.hoverStyles.linkBorderBottom(theme.palette.gray[100])};
          }
        }
      `;
    case 'underline-white':
      return css`
        ${Theme.typography.variants.link};
        color: ${Theme.palette.common.white};
        ${underlineColor(Theme.palette.common.white)}
      `;
    case 'underline-white-xs':
      return css`
        ${Theme.typography.variants.link};
        color: ${Theme.palette.common.white};
        ${underlineColor(Theme.palette.common.white)};
        font-size: ${Theme.typography.size.xs};
      `;
    case 'hover-underline-white':
      return css`
        ${Theme.typography.variants.link};
        color: ${Theme.palette.common.white};

        ${AnchorLabel} {
          ${Theme.hoverStyles.linkBorderBottomHidden(
            Theme.palette.common.white
          )};
          white-space: normal;
        }

        :hover,
        :focus {
          ${AnchorLabel} {
            ${Theme.hoverStyles.linkBorderBottom(Theme.palette.common.white)};
          }
        }
      `;
    case 'hover-underline-gray':
      return css`
        ${Theme.typography.variants.primary.xs};
        color: ${Theme.palette.gray[90]};

        ${AnchorLabel} {
          ${Theme.hoverStyles.linkBorderBottomHidden(Theme.palette.gray[50])};
          white-space: normal;
        }

        :hover,
        :focus {
          ${AnchorLabel} {
            ${Theme.hoverStyles.linkBorderBottom(Theme.palette.gray[50])};
          }
        }
      `;
    case 'notification-bar-close':
      return css`
        position: absolute;
        top: 0;
        right: 0;
        margin: 1rem 1.5rem;

        ${Theme.mediaQueries.md} {
          margin: 0.75rem 2.25rem;
        }

        :focus {
          outline: ${({ theme }) => `2px solid ${theme.palette.common.white}`};
        }

        :hover {
          path {
            stroke: ${({ theme }) => theme.palette.gray[10]};
          }
        }
      `;
    case 'nav-tab':
      return css`
        width: 100%;
        margin: 0.25rem;
        border-radius: 0.25rem;

        :active {
          background-color: ${({ theme }) => theme.palette.gray[0]};
        }
      `;
    case 'nav-tab-with-border':
      return css`
        ${NavTabWithBorderStyles};
        border-top: ${({ theme }) => `0.375rem solid ${theme.palette.gray[0]}`};
      `;
    case 'nav-tab-with-border-active':
      return css`
        ${NavTabWithBorderStyles};
        border-top: ${({ theme }) =>
          `0.375rem solid ${theme.palette.primary.main}`};
      `;
    case 'nav-tab-sections-menu-anchor':
      return css`
        text-align: start;
        padding: 0.75rem 1.25rem;
        ${({ theme }) => theme.typography.variants.primary.xs};
        color: ${({ theme }) => theme.palette.primary.main};

        :active {
          background-color: ${({ theme }) => theme.palette.gray[0]};
        }
      `;
    case 'skip-link':
      return css`
        padding: 1.1875rem 2rem;
        border: ${Theme.borders['white']};
        border-radius: 0.25rem;
        background-color: ${Theme.palette.blue[90]};
        color: ${Theme.palette.common.white};
        z-index: 999;
        position: absolute;
        top: ${({ theme }) =>
          `calc(1.5rem + ${theme.sizes.topBarHeightMobile})`};
        left: 50%;
        transform: translateX(-50%);
        pointer-events: none !important;
        opacity: 0;

        :focus {
          opacity: 1;
        }

        ${Theme.mediaQueries.sm} {
          top: ${({ theme }) =>
            `calc(1.5rem + ${theme.sizes.topBarHeightTablet})`};
        }
      `;
  }
};

const PrimaryInnerStyles = css`
  background-color: ${Theme.palette.primary.main};
  color: ${Theme.palette.common.white};
  border-radius: 5.25rem;

  :hover,
  :focus {
    background-color: ${Theme.palette.blue[60]};
  }
`;

const PrimaryMdInnerStyles = css`
  ${PrimaryInnerStyles};
  ${Theme.typography.variants.primary.xs};
  border: ${Theme.borders['gray-10']};
  display: inline-flex;
  padding: 0.75rem 1rem;

  ${Theme.mediaQueries.md} {
    font-size: ${Theme.typography.size.sm};
    line-height: ${Theme.typography.lineHeight.sm};
  }
`;

const getInnerStylesByVariant = (variant: AnchorInnerProps['variant']) => {
  switch (variant) {
    case 'primary':
      return css`
        ${PrimaryInnerStyles};
        ${Theme.typography.variants.primary.sm};
        padding: 1rem 2.25rem;
      `;
    case 'primary-lg':
      return css`
        ${PrimaryInnerStyles};
        ${Theme.typography.variants.primary.sm};
        padding: 1.5rem;
      `;
    case 'primary-lg-with-logo':
      return css`
        display: inline-flex;
        ${PrimaryInnerStyles};
        ${Theme.typography.variants.primary.sm};
        border: ${Theme.borders['gray-10']};
        padding: 1.5rem;

        ${Theme.mediaQueries.sm} {
          padding: 1.5rem 2.375rem 1.5rem 1.5rem;
        }
      `;
    case 'primary-md':
      return PrimaryMdInnerStyles;
    case 'primary-md-white':
      return css`
        ${PrimaryMdInnerStyles};
        background-color: ${Theme.palette.common.white};
        color: ${Theme.palette.text.primary};

        :focus,
        :hover {
          background-color: initial;
          border-color: ${Theme.palette.blue[60]};
        }
      `;
    case 'tag':
      return css`
        ${Theme.typography.variants.primary.xs};
        border: ${Theme.borders['gray-10']};
        display: inline-flex;
        border-radius: 5.25rem;
        background-color: ${Theme.palette.common.white};
        color: ${Theme.palette.text.primary};
        padding: 0.25rem 0.875rem;

        :focus,
        :active {
          border-color: ${Theme.palette.primary.main};
        }

        :hover {
          color: ${Theme.palette.primary.main};
        }
      `;
    case 'nav-menu':
      return css`
        width: 100%;
        display: flex;
        justify-content: start;
        align-items: center;
        ${Theme.typography.variants.primary.md};
        color: ${Theme.palette.primary.main};

        ${AnchorLabel} {
          ${({ theme }) =>
            theme.hoverStyles.linkBorderBottomHidden(
              theme.palette.primary.main
            )};
        }

        svg {
          margin-left: 0.76rem;
          width: 0.75rem;
        }

        :hover,
        :active {
          background-color: ${({ theme }) => theme.palette.gray[0]};

          ${AnchorLabel} {
            ${Theme.hoverStyles.linkBorderBottom(Theme.palette.primary.main)};
          }
        }

        ${Theme.mediaQueries.lg} {
          border-radius: 0.25rem;

          svg {
            margin-left: 0.625rem;
            flex-shrink: 0;
          }
        }
      `;
    case 'symptom-checker-white':
      return css`
        ${Theme.typography.variants.primary.xs};
        ${Theme.transitions.transition};
        border: ${Theme.borders['gray-10']};
        display: flex;
        align-items: center;
        height: 2.75rem;
        border-radius: 5.25rem;
        color: ${Theme.palette.text.primary};
        background-color: ${Theme.palette.common.white};
        padding: 0 0.75rem 0 1rem;

        ${Theme.mediaQueries.sm} {
          height: 3.125rem;
          padding: 0 1.4375rem;
        }

        :hover,
        :focus {
          color: ${Theme.palette.primary.main};
        }
      `;
    case 'symptom-checker-blue':
      return css`
        ${Theme.typography.variants.primary.sm};
        ${Theme.transitions.transition};
        border: ${Theme.borders['gray-10']};
        display: flex;
        align-items: center;
        height: 3.5rem;
        border-radius: 5.25rem;
        color: ${Theme.palette.common.white};
        background-color: ${Theme.palette.primary.main};
        padding: 0 1rem;

        :hover,
        :focus {
          background-color: ${Theme.palette.blue[80]};
        }
      `;
    case 'hero':
      return css`
        ${Theme.typography.variants.primary.xs};
        border: ${Theme.borders['gray-10']};
        background-color: ${Theme.palette.common.white};
        color: ${Theme.palette.text.primary};
        border-radius: 5.25rem;
        padding: 0.75rem 1rem;

        ${Theme.mediaQueries.sm} {
          padding: 1.1875rem 1.5rem;
        }

        :hover,
        :focus {
          color: ${Theme.palette.primary.main};
        }
      `;
    case 'nav-tab':
      return css`
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      `;
    case 'notification-bar-close':
      return css`
        padding: 0.5rem;
        display: flex;
        justify-content: center;
        align-items: center;
      `;
    case 'skip-link':
      return css`
        ${Theme.typography.variants.primary.sm};
        color: ${Theme.palette.common.white};
        /* Underline */
        background-size: 100% 1px;
        background-image: linear-gradient(white, white);
        background-repeat: no-repeat;
        background-position: left 90%;
      `;
    case 'rectangle-blue':
      return css`
        ${PrimaryInnerStyles};
        ${Theme.typography.variants.primary.sm};
        border-radius: 0.25rem;
        padding: 1rem 1.5rem;
      `;
    case 'rectangle-white':
      return css`
        ${PrimaryInnerStyles};
        ${Theme.transitions.transition};
        ${Theme.typography.variants.primary.sm};
        background-color: ${Theme.palette.common.white};
        outline: ${Theme.borders['gray-10']};
        border-radius: 0.25rem;
        color: ${Theme.palette.primary.main};
        padding: 1rem;

        :focus,
        :hover {
          background-color: initial;
          outline-color: ${Theme.palette.blue[60]};
        }
      `;
  }
};
