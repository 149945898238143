import React, { useCallback, useEffect, useState } from 'react';
import { AccordionContext } from './contexts';
import { useDispatch } from 'react-redux';
import { useGlobalSettings } from 'state/selectors/globalSettings';
import { fetchTrendingArticlesImages } from 'state/actions/globalSettingsActions';
import { useRouter } from 'next/router';
import useLanguage from 'hooks/useLanguage';
import NavCloseIcon from 'styled/components/svg/NavCloseIcon';
import NavOpenIcon from 'styled/components/svg/NavOpenIcon';
import toCamelCase from 'utils/toCamelCase';
import MobileArticles from './MobileArticles';
import MobileMenuDropdown from './MobileMenuDropdown';
import MobileMenuSlider from './MobileMenuSlider';
import MobileNavDrawer from './MobileNavDrawer';
import { NavButton, NavLink } from './MobileNavBar.style';
import {
  trackUserClicksSecondLevelItem,
  trackUserClicksThirdLevelItem,
  trackUserClicksTopLevelItem,
  trackUserExpandsSecondLevelItem,
  trackUserExpandsThirdLevelItem,
  trackUserExpandsTopLevelSection,
  trackUserOpensMobileNav,
} from '../analytics';

import type { NavigationProps } from '../types';

function MobileNavBar(props: NavigationProps) {
  const { navigation = {}, orderedMainNavigation = [] } = props;

  const Language = useLanguage();
  const router = useRouter();
  const [activeAccordionId, setActiveAccordionId] = useState('');
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const { trendingArticleImages } = useGlobalSettings();

  const handleMenuClose = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const handleMenuToggle = useCallback(() => {
    if (!open) {
      trackUserOpensMobileNav();

      if (!trendingArticleImages?.length) {
        const action = fetchTrendingArticlesImages();
        dispatch(action);
      }
    }
    setOpen(!open);
  }, [dispatch, open, trendingArticleImages?.length]);

  useEffect(() => {
    router.events.on('routeChangeStart', handleMenuClose);

    return () => {
      router.events.off('routeChangeStart', handleMenuClose);
    };
  }, [handleMenuClose, router]);

  return (
    <>
      <NavButton
        ariaLabel={
          open ? Language.t('Nav.closeMenu') : Language.t('Nav.openMenu')
        }
        onClick={handleMenuToggle}
      >
        {open ? <NavCloseIcon /> : <NavOpenIcon />}
      </NavButton>

      <MobileNavDrawer
        id="MobileNavCard"
        open={open}
        onClose={handleMenuToggle}
      >
        {orderedMainNavigation.map((key) => {
          const firstLevel = navigation[key];

          if (!firstLevel) {
            return null;
          }

          if (!firstLevel.children?.length) {
            return (
              <NavLink
                key={firstLevel._id}
                ariaLabel={firstLevel.label}
                label={firstLevel.label}
                to={firstLevel.slug}
                onClick={() =>
                  trackUserClicksTopLevelItem(firstLevel.label, firstLevel.slug)
                }
              />
            );
          }

          return (
            <AccordionContext.Provider
              key={firstLevel._id}
              value={{ activeAccordionId, setActiveAccordionId }}
            >
              <MobileMenuSlider
                label={firstLevel.label}
                trackSliderOpen={() =>
                  trackUserExpandsTopLevelSection(firstLevel.label)
                }
              >
                <MobileArticles
                  articles={firstLevel.trendingArticles}
                  label={firstLevel.label}
                  labelFormat={firstLevel.labelFormat}
                  onViewAllClick={() =>
                    trackUserClicksTopLevelItem(
                      firstLevel.label,
                      firstLevel.slug,
                    )
                  }
                  slug={firstLevel.slug}
                />

                {firstLevel.children.map((child) => {
                  const secondLevel = {
                    ...child,
                    ...navigation[toCamelCase(child.label)],
                  };

                  if (!secondLevel) {
                    return null;
                  }

                  if (!secondLevel?.children?.length) {
                    return (
                      <MobileMenuDropdown
                        key={secondLevel._id}
                        id={secondLevel._id}
                        label={secondLevel.label}
                        trackMenuOpen={() =>
                          trackUserExpandsSecondLevelItem(secondLevel.label)
                        }
                      >
                        <MobileArticles
                          articles={secondLevel.trendingArticles}
                          label={secondLevel.label}
                          labelFormat={secondLevel.labelFormat}
                          onViewAllClick={() =>
                            trackUserClicksSecondLevelItem(
                              secondLevel.label,
                              secondLevel.slug,
                            )
                          }
                          slug={secondLevel.slug}
                        />
                      </MobileMenuDropdown>
                    );
                  }

                  return (
                    <MobileMenuSlider
                      key={secondLevel._id}
                      label={secondLevel.label}
                      trackSliderOpen={() =>
                        trackUserExpandsSecondLevelItem(secondLevel.label)
                      }
                    >
                      <MobileArticles
                        articles={secondLevel.trendingArticles}
                        label={secondLevel.label}
                        labelFormat={secondLevel.labelFormat}
                        onViewAllClick={() =>
                          trackUserClicksSecondLevelItem(
                            secondLevel.label,
                            secondLevel.slug,
                          )
                        }
                        slug={secondLevel.slug}
                      />
                      {secondLevel.children.map((thirdLevel) => {
                        if (!thirdLevel) {
                          return null;
                        }

                        return (
                          <MobileMenuDropdown
                            id={thirdLevel._id}
                            key={thirdLevel._id}
                            label={thirdLevel.label}
                            trackMenuOpen={() =>
                              trackUserExpandsThirdLevelItem(thirdLevel.label)
                            }
                          >
                            <MobileArticles
                              articles={thirdLevel.trendingArticles}
                              label={thirdLevel.label}
                              labelFormat={thirdLevel.labelFormat}
                              onViewAllClick={() =>
                                trackUserClicksThirdLevelItem(
                                  thirdLevel.label,
                                  thirdLevel.slug,
                                )
                              }
                              slug={thirdLevel.slug}
                            />
                          </MobileMenuDropdown>
                        );
                      })}
                    </MobileMenuSlider>
                  );
                })}
              </MobileMenuSlider>
            </AccordionContext.Provider>
          );
        })}
      </MobileNavDrawer>
    </>
  );
}

export default MobileNavBar;
