import React from 'react';
import Head from 'next/head';
import baseSchema, { type BaseSchema } from './baseSchema';
import type {
  IBlogCategoryPage,
  CampaignPage,
  DrugPage,
  GenericPage,
  Quiz,
} from 'types';
import { useRouter } from 'next/router';

const DefaultStructuredDataSchema = ({
  page,
}: {
  page?: IBlogCategoryPage | CampaignPage | DrugPage | GenericPage | Quiz;
}) => {
  const { seo } = page || {};
  const { asPath } = useRouter();

  const schema: BaseSchema = {
    ...baseSchema(asPath),
    headline: seo?.title,
    description: seo?.description,
    ...(seo?.image?.src ? { image: [seo?.image.src] } : undefined),
  };

  return (
    <Head>
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: JSON.stringify(schema),
        }}
      />
    </Head>
  );
};

export default DefaultStructuredDataSchema;
