import theme from '@buoyhealth/common.buoy-theme';
import transitions from './transitions';
import animations from './animations';
import typography from './typography';
import {
  breakpoints,
  mediaQueries,
  space,
  sizes,
  borders,
  zIndex,
} from './config';
import hoverStyles from './hoverStyles';
import global from './global';

export default {
  palette: theme.palette,
  transitions,
  animations,
  typography,
  breakpoints,
  mediaQueries,
  space,
  sizes,
  borders,
  hoverStyles,
  zIndex,
  global,
};
