import memoize from 'lodash/memoize';
import { vercelStegaSplit } from '@vercel/stega';
import type {
  ISupplierCardConfig,
  MiniInterviewType,
} from '@buoy-components/polaris/types';
import type { IEmbeddedInterview } from 'types';
import sanitizeInterviewQuestion from 'state/sanitizers/sanitizeInterviewQuestion';

function sanitizeSupplierCtaCardConfig(config: any): ISupplierCardConfig {
  return {
    id: config.id || '',
    variant: config.variant,
    header: config.header || '',
    subheader: config.subheader || '',
    description: config.description || '',
    bulletPoints: config.bulletPoints
      ? config.bulletPoints.map((bulletPoint: any) => ({
          icon: bulletPoint.icon || '',
          text: bulletPoint.text || '',
        }))
      : [],
    ctaButton: {
      label: config.ctaLabel || '',
      url: config.ctaAction?.url || '',
    },
  };
}

export default memoize((module: any): IEmbeddedInterview => {
  return {
    type: module._type,
    id: module._key,
    title: module.title || '',
    description: module.description || '',
    interviews: [
      ...(module.interviews || []),
      ...(module.customInterviews || []),
    ].map((interview: any) => {
      return {
        ...interview,
        interview: vercelStegaSplit(interview.interview || '')
          .cleaned as MiniInterviewType,
        intro: interview.intro || '',
        questions: interview.questions
          ? interview.questions.map(sanitizeInterviewQuestion)
          : [],
        supplierCtaCardConfigs: interview.supplierCtaCardConfigs
          ? interview.supplierCtaCardConfigs.map(sanitizeSupplierCtaCardConfig)
          : [],
        treatment: interview.treatment || {},
        outcomeCopy: interview.customOutcomeCopy || [],
      };
    }),
  };
});
