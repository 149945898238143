import React, { useContext } from 'react';
import RightArrow from 'styled/components/svg/RightArrow';
import toCamelCase from 'utils/toCamelCase';
import { MenuContext, NavigationContext } from '../contexts';
import {
  GridMenuItemContainer,
  GridMenuItems,
  MenuItem,
  MenuItems,
} from './DesktopNavLinks.style';

import type { NavList, NavListItem, NavListItemChild } from 'types';
import DesktopNavLink from '../DesktopNavLink';
import {
  trackUserClicksSecondLevelItem,
  trackUserClicksThirdLevelItem,
  trackUserExpandsSecondLevelItem,
} from '../../analytics';

type ItemsProps = {
  items: NavListItemChild[];
  navItem: NavListItem;
  navigation: NavList;
};

function DesktopNavLinks(props: ItemsProps) {
  const { items, navItem, navigation } = props;

  const { open } = useContext(MenuContext);
  const { activeMenu } = useContext(NavigationContext);

  return (
    <>
      <MenuItems>
        {items.map((i) => {
          const secondLevel = {
            ...navigation[toCamelCase(i.label)],
            ...i,
          };

          const parentMenuIsActive = activeMenu.includes(navItem._id);
          const id = `${navItem._id}.${secondLevel._id}`;
          const isActive = activeMenu.includes(id);

          return (
            <MenuItem key={id}>
              <DesktopNavLink
                articles={secondLevel.trendingArticles}
                icon={isActive ? RightArrow : null}
                id={id}
                label={secondLevel.label}
                onClick={
                  secondLevel.slug
                    ? () =>
                        trackUserClicksSecondLevelItem(
                          secondLevel.label,
                          secondLevel.slug,
                        )
                    : undefined
                }
                onHover={() =>
                  trackUserExpandsSecondLevelItem(secondLevel.label)
                }
                slug={secondLevel.slug}
                tabIndex={open && parentMenuIsActive ? 0 : -1}
              />
            </MenuItem>
          );
        })}
      </MenuItems>
      <GridMenuItems>
        {items.map((i) => {
          const secondLevel = {
            ...navigation[toCamelCase(i.label)],
            ...i,
          };

          const parentMenuIsActive = activeMenu.includes(i._id);

          /**
           * The third-level nav links are render top-to-bottom in a dropdown that opens
           * to the right.
           */
          return (
            <GridMenuItemContainer key={i._id} $isActive={parentMenuIsActive}>
              {secondLevel.children.map((thirdLevel) => (
                <MenuItem key={thirdLevel.label}>
                  <DesktopNavLink
                    articles={thirdLevel.trendingArticles}
                    id={`${navItem._id}.${secondLevel._id}.${thirdLevel._id}`}
                    label={thirdLevel.label}
                    onClick={() =>
                      trackUserClicksThirdLevelItem(
                        thirdLevel.label,
                        thirdLevel.slug,
                      )
                    }
                    slug={thirdLevel.slug}
                    tabIndex={open && parentMenuIsActive ? 0 : -1}
                  />
                </MenuItem>
              ))}
            </GridMenuItemContainer>
          );
        })}
      </GridMenuItems>
    </>
  );
}

export default DesktopNavLinks;
