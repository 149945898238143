import React, { useCallback, useEffect, useState } from 'react';
import type Player from '@vimeo/player';
import type { VimeoVideoQuality } from '@vimeo/player';
import Button from 'styled/components/Button';
import { VimeoPlayerContainer } from 'styled/components/base';
import PlayIcon from 'styled/components/svg/PlayIcon';

import {
  Container,
  Inner,
  PauseButtonContainer,
  PlayButtonContainer,
  CaptionContainer,
} from './style';

import useLanguage from 'hooks/useLanguage';

import type { Video as IVideo } from 'types';

interface Props {
  autoPause?: boolean;
  autoPlay?: boolean;
  caption?: string;
  className?: string;
  isFullWidth?: boolean;
  isInsidePortableText?: boolean;
  isMuted?: boolean;
  loop?: boolean;
  quality?: VimeoVideoQuality;
  showCustomVideoControl?: boolean;
  showVimeoVideoControl?: boolean;
  stopVideo?: boolean;
  video: IVideo | null;
}

function Video(props: Props) {
  const {
    caption,
    className,
    isFullWidth = false,
    isInsidePortableText = false,
    showCustomVideoControl = true,
    showVimeoVideoControl = false,
    video,
  } = props;

  const Language = useLanguage();

  const [error, setError] = useState(false);
  const [player, setPlayer] = useState<Player | null>(null);
  const [ratio, setRatio] = useState<number | null>(null);
  const [videoIsPlaying, setVideoIsPlaying] = useState(false);

  const onPlay = useCallback(() => {
    setVideoIsPlaying(true);
  }, []);

  const onStop = useCallback(() => {
    setVideoIsPlaying(false);
  }, []);

  const handlePlay = useCallback(() => {
    if (!player) {
      return null;
    }

    const promise = player.play();

    if (promise) {
      promise.catch((error: any) => {
        setError(true);
        console.error(error);
      });
    }
  }, [player]);

  const handlePause = useCallback(() => {
    if (!player) {
      return null;
    }

    if (videoIsPlaying) {
      player.pause();
    }
  }, [player, videoIsPlaying]);

  const getRatio = useCallback((height: number, width: number) => {
    setRatio((height / width) * 100);
  }, []);

  const initializePlayer = useCallback(async () => {
    const {
      video,
      showVimeoVideoControl,
      autoPlay,
      autoPause,
      isMuted,
      loop,
      quality,
    } = props;
    const videoId = video && video.vimeoId;

    if (!videoId) return null;

    const Player = (await import('@vimeo/player')).default;
    const newPlayer = new Player(videoId, {
      id: Number(videoId),
      autoplay: autoPlay,
      autopause: autoPause,
      loop: loop,
      playsinline: true,
      controls: showVimeoVideoControl,
      muted: isMuted,
      texttrack: 'en',
      quality: quality || undefined,
    });

    setPlayer(newPlayer);

    if (newPlayer) {
      newPlayer
        .ready()
        .then(async () => {
          const width = await newPlayer.getVideoWidth();
          const height = await newPlayer.getVideoHeight();

          if (typeof width !== 'number' || typeof height !== 'number') {
            setError(true);
          }

          getRatio(height, width);
        })
        .catch((error: any) => {
          console.error('block video error: ', error);
          setError(true);
        });

      newPlayer.on('play', onPlay);
      newPlayer.on('pause', onStop);
      newPlayer.on('ended', onStop);
    }
  }, [getRatio, onPlay, onStop, props]);

  useEffect(() => {
    const { video } = props;

    const videoId = video && video.vimeoId;

    if (typeof window !== 'undefined' && videoId) {
      initializePlayer();
    } else {
      setError(true);
    }

    return () => {
      if (player) {
        player.destroy();
      }
    };
  }, []);

  useEffect(() => {
    if (props.stopVideo) {
      try {
        handlePause();
      } catch (err) {
        console.error(err);
      }
    }
  }, [props, handlePause]);

  if (!video || error) {
    return null;
  }

  return (
    <Container
      className={className}
      isFullWidth={isFullWidth}
      isInsidePortableText={isInsidePortableText}
    >
      <Inner
        position="relative"
        width="100%"
        height="100%"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        {!showVimeoVideoControl && !!showCustomVideoControl && (
          <>
            <PauseButtonContainer
              videoIsPlaying={videoIsPlaying}
              position="absolute"
              width="100%"
              height="100%"
            >
              <Button
                ariaLabel={Language.t('Video.pauseButtonAriaLabel')}
                onClick={handlePause}
              />
            </PauseButtonContainer>
            <PlayButtonContainer
              videoIsPlaying={videoIsPlaying}
              ratio={ratio}
              position="absolute"
            >
              <Button
                ariaLabel={Language.t('Video.playButtonAriaLabel')}
                onClick={handlePlay}
              >
                <PlayIcon />
              </Button>
            </PlayButtonContainer>
          </>
        )}
        <VimeoPlayerContainer
          id={video.vimeoId}
          ratio={ratio}
          data-vimeo-id={video.vimeoId}
          data-vimeo-defer
        />
      </Inner>
      {caption && (
        <CaptionContainer
          isFullWidth={isFullWidth}
          isInsidePortableText={isInsidePortableText}
          mt={0.75}
        >
          <span>{caption}</span>
        </CaptionContainer>
      )}
    </Container>
  );
}
export default Video;
