import { useContext } from 'react';
import PolarisPopUpContext from 'contexts/PolarisPopUpContext';

function usePolarisPopUpContext() {
  const context = useContext(PolarisPopUpContext);

  return context;
}

export default usePolarisPopUpContext;
