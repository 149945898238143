export enum QuestionFamilies {
  SINGLE_CHOICE = 'single_choice',
  MULTIPLE_CHOICE = 'multiple_choice',
  MATRIX = 'matrix',
}

export enum QuestionSubTypes {
  VERTICAL = 'vertical',
  RATING = 'rating',
}

export enum SurveyResponseStatuses {
  COMPLETED = 'completed',
  PARTIAL = 'partial',
}

// Subject to user change
export enum DefaultChoiceText {
  NONE_OF_THE_ABOVE = 'None of the above',
}
