function buoyInternalPushState() {
  if (typeof window !== 'undefined') {
    const currentUrl = window.location.href;

    if (currentUrl.includes('data_quality_flag=buoy_internal')) {
      const _pushState = window.history.pushState;

      window.history.pushState = function (data, title, url: string) {
        let queryString = 'data_quality_flag=buoy_internal';

        if (url.includes(queryString)) {
          // don't re-add this query if it already exists
          queryString = '';
        } else if (url?.toString()?.includes('?')) {
          queryString = `&${queryString}`;
        } else {
          queryString = `?${queryString}`;
        }

        url += queryString;
        _pushState.apply(this, [data, title, url]);
      };
    }
  }
}

export default buoyInternalPushState();
