import React, { useContext, useRef } from 'react';
import { NextRouter, useRouter } from 'next/router';
import { createContext } from 'react';

export const PreviousRouteContext = createContext<NextRouter | null>(null);

export const PreviousRouteContextProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const router = useRouter();
  const previousRoute = useRef<NextRouter | null>(null);

  router.events?.on('routeChangeStart', () => {
    previousRoute.current = router;
  });

  return (
    <PreviousRouteContext.Provider value={previousRoute.current}>
      {children}
    </PreviousRouteContext.Provider>
  );
};

export const usePreviousRouteContext = () => useContext(PreviousRouteContext);
