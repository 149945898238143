import styled from 'styled-components';
import { Base, Theme } from 'styled';

type ContainerProps = {
  isFullWidth: boolean;
  isInsidePortableText: boolean;
};

type PauseButtonContainerProps = {
  videoIsPlaying: boolean;
};

type PlayButtonContainerProps = {
  videoIsPlaying: boolean;
  ratio: number | null;
};

type CaptionContainerProps = {
  isFullWidth: boolean;
  isInsidePortableText: boolean;
};

export const Container = styled(Base)<ContainerProps>`
  height: 100%;
  width: 100%;
  ${({ isFullWidth, isInsidePortableText, theme }) =>
    isFullWidth &&
    isInsidePortableText &&
    theme.global.articleInnerTextFullWidthMdUpExtraWide};
  padding-top: ${({ isInsidePortableText }) =>
    isInsidePortableText ? '3rem' : undefined};
  padding-bottom: ${({ isInsidePortableText }) =>
    isInsidePortableText ? '3rem' : undefined};

  ${Theme.mediaQueries.md} {
    padding-top: ${({ isFullWidth, isInsidePortableText }) =>
      isFullWidth && isInsidePortableText ? '3.75rem' : undefined};
    padding-bottom: ${({ isFullWidth, isInsidePortableText }) =>
      isFullWidth && isInsidePortableText ? '3.75rem' : undefined};
  }
`;

export const Inner = styled(Base)``;

export const PauseButtonContainer = styled(Base)<PauseButtonContainerProps>`
  ${({ theme }) => theme.zIndex('3')};
  pointer-events: ${({ videoIsPlaying }) => (videoIsPlaying ? 'auto' : 'none')};

  > a,
  > button {
    width: 100%;
    height: 100%;
  }
`;

export const PlayButtonContainer = styled(Base)<PlayButtonContainerProps>`
  ${({ theme }) => theme.zIndex('3')};
  ${({ theme }) => theme.transitions['transition-short']};
  pointer-events: ${({ videoIsPlaying, ratio }) =>
    videoIsPlaying || !ratio ? 'none' : 'auto'};
  opacity: ${({ videoIsPlaying, ratio }) => (videoIsPlaying || !ratio ? 0 : 1)};

  > a,
  > button {
    width: 100%;
    height: 100%;
  }
`;

export const CaptionContainer = styled(Base)<CaptionContainerProps>`
  ${({ theme }) => theme.typography.variants.primary.xs};
  ${({ theme }) => theme.palette.text.primary};
  ${({ isFullWidth, isInsidePortableText, theme }) =>
    isFullWidth && isInsidePortableText && theme.global.articleContentPadding};
`;
