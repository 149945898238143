import { z } from 'zod';
import { Result, toResult, ResultAsync, toResultAsync } from 'utils/results';
import { captureException } from 'utils/sentry';

export default function validateSchema<
  T extends z.infer<any>,
>(validationObject: {
  schema: z.Schema<T>;
  data: unknown;
  defaultValue?: any;
  shouldLog?: boolean;
}): T | any {
  const { schema, data, shouldLog = true } = validationObject;
  const parsedData = schema.safeParse(data);
  if (parsedData.success) {
    return parsedData.data;
  } else {
    if (shouldLog) {
      console.error('Validation error:', parsedData.error.issues);
      captureException(parsedData.error);
    }
    return 'defaultValue' in validationObject
      ? validationObject.defaultValue
      : parsedData.error;
  }
}

/**
 * Synchronously validates data against a zod schema
 */
export const validateSchemaResult =
  <T extends z.infer<any>>(zSchema: z.Schema<T>) =>
  (data: unknown): Result<T> =>
    toResult(zSchema.parse)(data);

/**
 * Asynchronously validates data against a zod schema
 */
export const validateSchemaResultAsync =
  <T extends z.infer<any>>(zSchema: z.Schema<T>) =>
  (data: unknown): ResultAsync<T> =>
    toResultAsync(zSchema.parseAsync)(data);
