import styled from 'styled-components';
import { MenuItem } from '../DesktopNavLinks/DesktopNavLinks.style';

export const ArticleItems = styled.ul`
  display: flex;
  flex-direction: column;
  min-width: 280px;
`;

export const ArticleItem = styled(MenuItem)`
  .Link {
    justify-content: flex-start;
    padding: 0.5rem 1rem;

    &:last-of-type {
      margin-bottom: 0.5rem;
    }

    &:hover {
      > span {
        background-image: none;
      }
    }

    > span {
      padding-left: 1rem;
      transition: none;
      white-space: normal;
      font-size: 1rem;
    }
  }
`;
