import memoize from 'lodash/memoize';
import get from 'utils/get';

import { AccordionListWithBlueAccentsModule, AccordionListItem } from 'types';

export default memoize(
  (module: unknown): AccordionListWithBlueAccentsModule => {
    const items: AccordionListItem[] = get(module, 'listItems', []).map(
      (item: unknown) => {
        return {
          id: get(item, '_key', ''),
          button: {
            label: get(item, 'label', ''),
            description: '',
          },
          content: get(item, 'content', []),
        };
      },
    );

    return {
      type: get(module, '_type', 'accordionListWithAnimation'),
      id: get(module, '_key', ''),
      header: get(module, 'header', ''),
      listItems: items,
    };
  },
);
