import styled from 'styled-components';
import { Base } from 'styled/components/base';
import NavChevron from 'styled/components/svg/NavChevron';

export const MenuIconBackward = styled(Base).attrs({ as: NavChevron })`
  margin-left: 0 !important;
  margin-right: 1rem !important;
  transform: rotate(90deg);
`;

export const MenuIconForward = styled(Base).attrs({ as: NavChevron })`
  margin-left: auto !important;
  transform: rotate(-90deg);
`;
