import get from 'utils/get';
import getPortableTextAsString from 'utils/getPortableTextAsString';
import { PortableText } from 'types';

// extract Question from text module
export const getQuestion = (text: PortableText): string => {
  const question = text.filter(
    (textItem) =>
      get(textItem, 'style') === 'h2' || get(textItem, 'style') === 'h3',
  );
  return getPortableTextAsString(question);
};

// extract Answer from text module
export const getAnswer = (text: PortableText): string => {
  const answer = text.filter(
    (textItem) =>
      get(textItem, 'style') === 'normal' ||
      get(textItem, 'style') === 'smallText',
  );
  return getPortableTextAsString(answer);
};
