import memoize from 'lodash/memoize';
import get from 'utils/get';

import sanitizeModuleOptions from 'state/sanitizers/modules/sanitizeModuleOptions';
import sanitizeModuleHeader from 'state/sanitizers/modules/sanitizeModuleHeader';
import sanitizeCarouselItems from 'state/sanitizers/modules/sanitizeCarouselItems';

import { CarouselModule } from 'types';

export default memoize((module: unknown): CarouselModule => {
  return {
    type: get(module, '_type', 'carousel'),
    id: get(module, '_key', ''),
    moduleHeader: sanitizeModuleHeader(get(module, 'moduleHeader', {})),
    moduleOptions: sanitizeModuleOptions(module),
    carouselItems: sanitizeCarouselItems(get(module, 'carouselItems', [])),
  };
});
