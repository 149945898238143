import memoize from 'lodash/memoize';
import get from 'utils/get';

import sanitizeImage from 'state/sanitizers/sanitizeImage';
import sanitizeModuleOptions from 'state/sanitizers/modules/sanitizeModuleOptions';

import {
  InterviewDialog,
  InterviewImage,
  InterviewModule,
  InterviewQuestion,
} from 'types';
import { FontSizes } from 'styled/theme/typography';

export default memoize((module: unknown): InterviewModule => {
  return {
    id: get(module, '_key', ''),
    type: get(module, '_type', 'interview'),
    moduleOptions: sanitizeModuleOptions(module),
    contents: get(module, 'contents', []).map((item: unknown) => {
      switch (get(item, '_type')) {
        case 'contentImageWithCaption':
          return {
            id: get(item, '_key', ''),
            type: 'interviewImage',
            image: sanitizeImage(get(item, 'contentImage')),
            inlineImageWidth: get(item, 'inlineImageWidth', 40),
            caption: get(item, 'caption', ''),
          } as InterviewImage;

        case 'question':
          return {
            id: get(item, '_key', ''),
            type: 'interviewQuestion',
            text: get(item, 'text', ''),
            desktopFontSize: get(item, 'questionFontSize', FontSizes.lg),
          } as InterviewQuestion;

        case 'dialog':
          return {
            id: get(item, '_key', ''),
            type: 'interviewDialog',
            text: get(item, 'text', []),
            speakerImage: sanitizeImage(get(item, 'speakerImage')),
          } as InterviewDialog;

        default:
          return null;
      }
    }),
  };
});
