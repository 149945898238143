const TRANSITION_DURATION_VALUE = 600;
const TRANSITION_DURATION = `${TRANSITION_DURATION_VALUE}ms`;
const EASE_OUT_EXPO = 'cubic-bezier(0.19, 1, 0.22, 1)';
const TRANSITION_HALF = `${TRANSITION_DURATION_VALUE / 2}ms`;
const TRANSITION_LONG = `${TRANSITION_DURATION_VALUE * 2}ms`;

export default {
  'transition-duration': TRANSITION_DURATION,
  'transition-half': TRANSITION_HALF,
  'transition-long': TRANSITION_LONG,
  'transition-opacity-short': {
    transition: `opacity ${TRANSITION_HALF}`,
  },
  'transition-opacity': {
    transition: `opacity ${TRANSITION_DURATION}`,
  },
  'transition-short': {
    transition: `all ${TRANSITION_HALF} ${EASE_OUT_EXPO}`,
  },
  transition: {
    transition: `all ${TRANSITION_DURATION}`,
  },
  'transition-underline': {
    transition: `background-size 0.3s ${EASE_OUT_EXPO}`,
  },
  'transition-dropdown': {
    transition: `max-height ${TRANSITION_DURATION} ${EASE_OUT_EXPO}`,
  },
};
