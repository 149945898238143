import memoize from 'lodash/memoize';
import get from 'utils/get';

import sanitizeImage from 'state/sanitizers/sanitizeImage';

import { VideoCtaModule } from 'types';

export default memoize((module: unknown): VideoCtaModule => {
  return {
    type: get(module, '_type', 'videoCtaModule'),
    id: get(module, '_key', ''),
    headerText: get(module, 'headerText', ''),
    headerIcon: sanitizeImage(get(module, 'headerIcon', '')),
    description: get(module, 'description', ''),
    ctaButton: {
      label: get(module, 'ctaButton.label', ''),
      link: get(module, 'ctaButton.link', ''),
    },
    video: {
      vimeoId: get(module, 'video.vimeoId', ''),
      youtubeId: get(module, 'video.youtubeId', ''),
    },
  };
});
