import React, { FC } from 'react';
import theme from '@buoyhealth/common.buoy-theme';
import { Svg } from 'styled/components/base';
import { Color } from 'styled/theme/colors';

type Props = {
  className?: string;
  color?: Color;
};

const BuoyLogo: FC<Props> = ({
  className = '',
  color = theme.palette.primary.main,
}) => (
  <Svg
    width="79px"
    height="42px"
    viewBox="0 0 79 42"
    className={className}
    fill="transparent"
  >
    <desc>Buoy Logo.</desc>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M49.3302 25.802C46.5425 25.802 44.2738 23.5568 44.2738 20.7976C44.2738 18.0388 46.5425 15.7935 49.3302 15.7935C52.1173 15.7935 54.386 18.0388 54.386 20.7976C54.386 23.5568 52.1173 25.802 49.3302 25.802ZM10.0104 25.8012C7.22151 25.8012 4.95284 23.5568 4.95284 20.7976C4.95284 20.7377 4.95539 20.6784 4.95736 20.6191H4.98546V20.2371C5.26809 17.741 7.41333 15.7935 10.0104 15.7935C12.7976 15.7935 15.066 18.0388 15.066 20.7976C15.066 23.5568 12.7976 25.8012 10.0104 25.8012ZM76.5584 11.2411L76.5576 11.2442C76.5395 11.2438 76.5224 11.2411 76.5043 11.2411C75.1315 11.2411 74.0234 12.4988 74.0244 14.0361L74.0143 20.7727H74.0147L74.0145 20.7731C74.0002 23.5043 71.7525 25.7215 68.9892 25.7215C66.218 25.7215 63.965 23.4905 63.965 20.748L63.9585 11.2411H58.979V18.1652C57.8137 13.9723 53.9365 10.891 49.3302 10.891C44.7184 10.891 40.838 13.9778 39.6763 18.1761V11.3057H37.0517L37.0499 11.3133C35.7378 11.3903 34.6855 12.6129 34.6867 14.1007L34.6904 20.8373H34.691L34.6908 20.8377C34.6763 23.5687 32.4289 25.786 29.6663 25.786C26.8937 25.786 24.6407 23.5549 24.6407 20.8128L24.6195 11.3057H19.6557V18.152C18.4858 13.9659 14.6124 10.891 10.0104 10.891C8.17826 10.891 6.46266 11.3804 4.98546 12.2309L4.99155 2.79481C4.99155 1.25766 3.86851 0 2.49587 0H2.49568H0V20.7976C0 26.2688 4.48192 30.7037 10.0104 30.7037C14.8911 30.7037 18.9532 27.2462 19.8382 22.6711C20.7235 27.246 24.785 30.7037 29.6663 30.7037C34.5533 30.7037 38.6201 27.2355 39.4969 22.6505C40.374 27.2357 44.4412 30.7037 49.3302 30.7037C54.2254 30.7037 58.2974 27.2246 59.1655 22.6289C60.0599 27.1927 64.1161 30.6393 68.9892 30.6393C70.8227 30.6393 72.5395 30.1491 74.0173 29.2974V32.1628C73.9913 34.6925 72.1287 37.2321 67.2871 37.2321C66.6498 37.2321 66.0428 37.1932 65.4915 37.1232V39.5975H65.4945C65.5269 40.926 66.5464 42.0016 68.0745 42C68.0745 42 69.1515 41.9986 69.2305 41.9969H69.239V41.9967C74.593 41.8689 78.9002 37.5807 78.9941 32.2719L79 11.2411H76.5584Z"
      fill={color}
    />
  </Svg>
);

export default BuoyLogo;
