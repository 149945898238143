import { useCallback, useRef, useState } from 'react';
import useMutationObserver from 'hooks/useMutationObserver';
import { zIndex } from 'styled/theme/config';

export enum AD_TYPES {
  BOTTOM_RAIL_MOBILE = 'bottom_rail_mobile',
  BOTTOM_RAIL_DESKTOP = 'bottom_rail_desktop',
  BOTTOM_RAIL_ALT = 'bottom_rail_alt',
  CORNER_VIDEO = 'corner_video',
}

const AD_TYPE_SELECTORS: Record<AD_TYPES, string> = {
  [AD_TYPES.BOTTOM_RAIL_MOBILE]: '#adhesion_mobile_wrapper',
  [AD_TYPES.CORNER_VIDEO]: '#universalPlayer_wrapper',
  [AD_TYPES.BOTTOM_RAIL_DESKTOP]: '#adhesion_desktop_wrapper',
  [AD_TYPES.BOTTOM_RAIL_ALT]: 'div[id^="ym"]',
};

// These are ads that have their z-indexes set in the style attribute
const Z_INDEX_SELECTORS: AD_TYPES[] = [AD_TYPES.BOTTOM_RAIL_ALT];

function getTotalClientHeight(
  elements?: Array<HTMLElement | null | undefined>,
) {
  if (typeof document === 'undefined' || !elements) {
    return 0;
  }
  return elements.reduce((total, element) => {
    if (element instanceof HTMLElement) {
      return total + element.clientHeight;
    }
    return total;
  }, 0);
}

function getPlacements(): Partial<Record<AD_TYPES, HTMLElement[]>> {
  if (typeof document === 'undefined') {
    return {};
  }

  return Object.entries(AD_TYPE_SELECTORS).reduce(
    (acc, [key, selector]) => {
      const elements: NodeListOf<HTMLElement> =
        document.querySelectorAll(selector);

      acc[key as AD_TYPES] = [...(acc[key as AD_TYPES] || []), ...elements];

      elements.forEach((element) => {
        if (Z_INDEX_SELECTORS.includes(key as AD_TYPES)) {
          element.style.setProperty(
            'z-index',
            `${zIndex('ads')?.zIndex}`,
            'important',
          );
        }
      });

      return acc;
    },
    {} as Record<AD_TYPES, HTMLElement[]>,
  );
}

function useAdPlacements(): {
  pageAds: Partial<Record<AD_TYPES, HTMLElement[]>>;
  bottomRailAdHeight: number;
} {
  const target = useRef(
    typeof document !== 'undefined' ? document?.body : null,
  );
  const [pageAds, setPageAds] = useState(getPlacements());
  const [bottomRailAdHeight, setBottomRailAdHeight] = useState(0);

  const callback = useCallback(() => {
    const placements = getPlacements();
    setPageAds(placements);

    const bottomRailAdHeight = getTotalClientHeight([
      ...(placements[AD_TYPES.BOTTOM_RAIL_DESKTOP] || []),
      ...(placements[AD_TYPES.BOTTOM_RAIL_ALT] || []),
      ...(placements[AD_TYPES.BOTTOM_RAIL_MOBILE] || []),
    ]);
    setBottomRailAdHeight(bottomRailAdHeight);
  }, []);

  useMutationObserver(target, callback, {
    attributes: false,
    characterData: false,
    childList: true,
    subtree: true,
  });

  return { pageAds, bottomRailAdHeight };
}

export default useAdPlacements;
