import memoize from 'lodash/memoize';
import sanitizeImage from './sanitizeImage';
import type { TrendingArticleImage } from 'types';

export default memoize((response: any[]): TrendingArticleImage[] => {
  const trendingArticleImageData = (response || [])
    ?.filter((trendingArticle) =>
      Boolean(trendingArticle && trendingArticle.articleId),
    )
    ?.map((trendingArticle: any) => ({
      articleId: trendingArticle.articleId,
      heroImage: sanitizeImage(trendingArticle.heroImage),
      heroColor: trendingArticle.heroColor || '',
    }));

  return trendingArticleImageData || [];
});
