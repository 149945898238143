import { AwsRum, AwsRumConfig } from 'aws-rum-web';

function initRum() {
  const config: AwsRumConfig = {
    allowCookies: true,
    enableXRay: true,
    endpoint: 'https://dataplane.rum.us-east-1.amazonaws.com',
    guestRoleArn:
      'arn:aws:iam::638759647074:role/RUM-Monitor-us-east-1-638759647074-1747263465761-Unauth',
    identityPoolId: 'us-east-1:165ca5b0-0386-4614-b0f4-0184f4c29811',
    sessionSampleRate: 0.01,
    telemetries: ['performance', 'errors', 'http'],
  };

  const APPLICATION_ID = '2fa16772-19b3-4c97-a934-88a7545650e1';
  const APPLICATION_REGION = 'us-east-1';
  const APPLICATION_VERSION = '1.0.0';

  const awsRum: AwsRum = new AwsRum(
    APPLICATION_ID,
    APPLICATION_VERSION,
    APPLICATION_REGION,
    config,
  );

  return awsRum;
}

export { initRum };
