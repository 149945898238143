import { createContext } from 'react';
import noop from 'lodash/noop';

import type {
  MenuContext as MenuContextType,
  NavigationContext as NavigationContextType,
} from '../types';

export const MenuContext = createContext<MenuContextType>({
  open: false,
});

export const NavigationContext = createContext<NavigationContextType>({
  activeMenu: '',
  setActiveMenu: noop,
  setTrending: noop,
  trending: {
    articles: [],
    label: '',
    slug: '',
  },
});
