import memoize from 'lodash/memoize';
import sanitizeEmbeddedInterview from './sanitizeEmbeddedInterview';
import sanitizeTextModule from './sanitizeTextModule';
import type { QuizModule } from 'types';

export default memoize(function (modules?: unknown[]): QuizModule[] {
  return (modules || [])
    .map((module: any): QuizModule | null => {
      const type = module._type;

      if (!type) {
        return null;
      }

      switch (type) {
        case 'embeddedInterview':
          return sanitizeEmbeddedInterview(module) as QuizModule;
        case 'textModule':
          return {
            ...sanitizeTextModule(module),
          } as QuizModule;
        default:
          return null;
      }
    })
    .filter((module): module is QuizModule => module !== null);
});
