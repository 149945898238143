import usePage from './usePage';

function usePageSlug() {
  const page = usePage();
  const blogSlug = page?.blogPost?.slug
    ? `${page.blogPost.categories?.[0] || ''}${page.blogPost.slug}`
    : '';
  return page?.article?.slug || blogSlug || page?.quiz?.slug;
}

export default usePageSlug;
