import { z } from 'zod';

const ImageDimensionsSchema = z.object({
  width: z.number(),
  height: z.number(),
  aspectRatio: z.number(),
});

const ImageCropSchema = z.object({
  top: z.number(),
  bottom: z.number(),
  left: z.number(),
  right: z.number(),
});

export const ImageSchema = z.object({
  id: z.string(),
  src: z.string(),
  alt: z.string(),
  crop: ImageCropSchema.nullable(),
  hotspot: z.object({}).nullable(),
  metadata: z
    .object({
      dimensions: ImageDimensionsSchema,
    })
    .nullable(),
});

export type ImageSchema = z.infer<typeof ImageSchema>;
