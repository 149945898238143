import memoize from 'lodash/memoize';

import type { IconConfig } from 'types';

export default memoize((moduleWithIcon: any): IconConfig => {
  return {
    name: moduleWithIcon?.icon?.name?.replace('Fi', '') || '',
    fill: moduleWithIcon?.iconFillColor || '#000',
    stroke: moduleWithIcon?.iconStrokeColor || '#000',
  };
});
