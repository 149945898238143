import memoize from 'lodash/memoize';
import get from 'utils/get';
import type { PortableTextBlock } from '@portabletext/types';
import { ArticleLink } from 'types';

export interface IDrugLink extends Partial<ArticleLink> {
  extendedDescription?: PortableTextBlock;
  isDrugLink: boolean;
}

export default memoize(
  (drugLink: unknown): IDrugLink => ({
    type: get(drugLink, '_type', 'drug'),
    isDrugLink: true,
    id: get(drugLink, '_id'),
    title: get(drugLink, 'name', ''),
    slug: get(drugLink, 'slug', ''),
    moduleDescription: get(drugLink, 'headline', ''),
    extendedDescription: get(drugLink, 'basicsDescription', undefined),
  }),
);
