import memoize from 'lodash/memoize';
import get from 'utils/get';

import sanitizeModuleRepeater from 'state/sanitizers/sanitizeModuleRepeater';
import sanitizePageHero from 'state/sanitizers/sanitizePageHero';
import sanitizeImage from 'state/sanitizers/sanitizeImage';

import { GenericPage } from 'types';

export default memoize((genericPage: any): GenericPage | null => {
  const id = get(genericPage, '_id');

  if (!id) {
    return null;
  }

  return {
    id,
    type: get(genericPage, '_type', 'genericPage'),
    slug: get(genericPage, 'slug', ''),
    showIntercom: get(genericPage, 'showIntercom', false),
    title: get(genericPage, 'title', ''),
    hero: sanitizePageHero(genericPage),
    modules: sanitizeModuleRepeater(get(genericPage, 'moduleRepeater', [])),
    seo: {
      title: get(genericPage, 'metaTitle', ''),
      description: get(genericPage, 'metaDescription', ''),
      image: sanitizeImage(get(genericPage, 'metaImage')),
      structuredData: get(genericPage, 'structuredData.code', null),
      canonicalUrl: get(genericPage, 'canonicalUrl', ''),
    },
    disableAds: genericPage.disableAds || false,
  };
});
