export const COOKIE = {
  /** If user has submitted their email */
  EMAIL_SUBMITTED: 'email-collection-prompt-submitted',
  /** If user has dismissed the email prompt */
  EMAIL_PROMPT_DISMISSED: 'email-collection-prompt-dismissed',
};

/** 45 sec default delay time before showing prompt - mobile only */
export const DEFAULT_DELAY = 45000;

/** Days to persist prompt dismissed cookie */
export const COOKIE_EXPIRATION = 30;

export const EMAIL_CONFIG_FEATURE_FLAG =
  'growth-public-site-email-collection-config';
