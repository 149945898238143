import { FC } from 'react';

import { Img } from 'styled';
import { ImageWithCaption, Caption } from './style';

import sanityImgUtil from 'utils/sanityImgUtil';
import sanityImgSrcSetUtil from 'utils/sanityImgSrcSetUtil';

import { Image } from 'types';

export interface Props {
  image: Image;
  caption: string;
  inlineImageWidth: number; // In percentage
}

const InlineImageWithCaption: FC<Props> = ({
  image,
  caption,
  inlineImageWidth,
}) => {
  return (
    <ImageWithCaption inlineWidthInPercentage={inlineImageWidth}>
      <Img
        alt={image.alt}
        sizes="(max-width: 768px) 1440px, (max-width: 1024px) 1952px, 1500px"
        src={sanityImgUtil(image, 1440)}
        srcSet={sanityImgSrcSetUtil(image, 1440, 1962, 1500)}
        preloadFullWidth={true}
        dimensions={image.metadata?.dimensions}
        crop={image.crop}
      />
      <Caption>{caption}</Caption>
    </ImageWithCaption>
  );
};

export default InlineImageWithCaption;
