import { RouteMap } from 'constants/Routes';
import { ArticleType } from 'types';

const getBrowsePageUrl = (slug: string, articleType: ArticleType | null) => {
  const basePath = (
    articleType === ArticleType.Sx
      ? RouteMap.BROWSE_SYMPTOMS.path
      : RouteMap.BROWSE_CONDITIONS.path
  ).slice(0, -1);
  return `${basePath}${slug}`;
};

export default getBrowsePageUrl;
