import memoize from 'lodash/memoize';
import get from 'utils/get';

import sanitizeImage from 'state/sanitizers/sanitizeImage';

import { CampaignPage } from 'types';

export default memoize((campaignPage: unknown): CampaignPage | null => {
  const id = get(campaignPage, '_id');

  if (!id) {
    return null;
  }

  return {
    id,
    type: get(campaignPage, '_type', 'campaignPage'),
    title: get(campaignPage, 'title', ''),
    slug: get(campaignPage, 'slug', ''),
    description: get(campaignPage, 'description', []),
    image: sanitizeImage(get(campaignPage, 'image')),
    formTitle: get(campaignPage, 'formTitle', ''),
    formSubmissionMethod: get(campaignPage, 'submissionMethod', 'chili-piper'),
    showNameAndEmailFieldsOnly: get(
      campaignPage,
      'showNameAndEmailFieldsOnly',
      false,
    ),
    formLeadSource: get(campaignPage, 'formLeadSource', ''),
    formLeadSourceOtherC: get(campaignPage, 'formLeadSourceOtherC', ''),
    formCampaignId: get(campaignPage, 'formCampaignId', ''),
    formCustomButtonLabel: get(campaignPage, 'formCustomButtonLabel', ''),
    formCustomButtonUrl: get(campaignPage, 'formCustomButtonUrl', ''),
    redirectUrl: get(campaignPage, 'redirectUrl', ''),
    demoVideo: {
      vimeoId: get(campaignPage, 'demoVideoId', ''),
      youtubeId: '',
    },
    demoVideoMessage: get(
      campaignPage,
      'demoVideoMessage',
      'watch the demo here!',
    ),
    showIntercom: get(campaignPage, 'showIntercom', false),
    enableFacebookPixel: get(
      campaignPage,
      'enableFacebookPixelTracking',
      false,
    ),
    seo: {
      title: get(campaignPage, 'metaTitle', ''),
      description: get(campaignPage, 'metaDescription', ''),
      image: sanitizeImage(get(campaignPage, 'metaImage')),
      structuredData: get(campaignPage, 'structuredData.code', null),
      canonicalUrl: get(campaignPage, 'canonicalUrl', ''),
    },
  };
});
