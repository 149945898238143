import getHost from 'utils/getHost';
import Environments from 'constants/Environments';

const isBuoyStaging = () => {
  /*
   * Server
   */
  if (typeof window === 'undefined') {
    return process.env.ENVIRONMENT === 'stage';
  }

  /*
   * Browser
   */
  const host = getHost();

  return host === Environments.STAGING.host;
};

export default isBuoyStaging;
