import memoize from 'lodash/memoize';
import sanitizeImage from '../sanitizeImage';
import type { ProductCards } from 'types';

export default memoize((module: any): ProductCards | null => {
  const id = module?._key;

  if (!id) {
    return null;
  }

  return {
    id,
    type: module._type || 'productCards',
    products: module.products?.map((product: any) => ({
      header: product.header || '',
      image: sanitizeImage(product.image),
      bulletPoints:
        product.bulletPoints?.map((bulletPoint: any) => ({
          icon: bulletPoint.icon || null,
          text: bulletPoint.text || '',
        })) || [],
      url: product.url || '',
      linkLabel: product.linkLabel || '',
    })),
  };
});
