export const BlogFrozenGroq = (filterByFrozen: boolean) =>
  filterByFrozen
    ? '&& frozen == true'
    : '&& (frozen == false || !defined(frozen))';

export const ImageGroq = `{ '_id': asset->_id, 'url': asset->url, alt, crop, hotspot, 'metadata': asset->metadata{dimensions{aspectRatio,height,width}} }`;

export const LottieGroq = `{ '_id': file.asset->_id, 'url': file.asset->url, alt }`;

export const EmbeddedInterviewQuestionGroq = `{
  _id,
  configuration,
  list,
  subText,
  text,
  options[]{ _key, next -> { ... }, exception, only, other, outcome, subText, text, valid}
}`;

export const ArticleLinkGroq = `{
  _id,
  articleType,
  conditionName,
  heroColor,
  'heroImage': heroImage${ImageGroq},
  slug,
  title,
  reviewer -> {..., 'image': image${ImageGroq}},
  author -> {..., 'image': image${ImageGroq}},
  moduleTitle,
  moduleDescription,
  'moduleImage': moduleImage${ImageGroq},
  modifiedDate,
  symptomCheckerButtonLabelMobile,
  symptomCheckerButtonLabelDesktop,
  symptomCheckerButtonUrl,
  symptomCheckerButtonGtmTrackerEventName,
  _updatedAt,
  dxTaxonomy,
  sxTaxonomy,
  subtitle,
  language
}`;

export const DrugLinkGroq = `{
  _id,
  _type,
  name,
  headline,
  slug,
  basicsDescription
}`;

export const ModuleGroq = `{
  ...,
  'moduleOptions': moduleOptions {
    ...,
    'infoSectionImage': infoSectionImage${ImageGroq},
    'supportingSectionImage': supportingSectionImage${ImageGroq},
  },
  'contents': contents[] {
    ...,
    _type == 'articleRef' => { ... }-> {...},
    _type == 'author' => { ... }-> {..., moduleImage${ImageGroq}, image${ImageGroq}},
    _type == 'teamMember' => { ... }-> {..., image${ImageGroq}},
    _type == 'image' => { ..., asset->{...} },
    'image': image${ImageGroq},
    'icon': icon${ImageGroq},
    'articleRef': articleRef->{..., heroImage${ImageGroq}, moduleImage${ImageGroq}},
    _type == 'videoItem' => {..., image${ImageGroq}},
    _type == 'contentImageWithCaption' => {..., contentImage${ImageGroq}},
    _type == 'dialog' => {..., speakerImage${ImageGroq}},
    'text': text[] {
      ...,
      _type == 'imageWithCaption' => {
        ...,
        'image': image{ ..., asset->{...} },
      }
    },
    _type == 'blogPost' => { ..., image${ImageGroq}, blogPostRef-> {...}},
  },
  'carouselItems': carouselItems[] {
    ...,
    _type == 'imageItem' => {
      ...,
      'image': image${ImageGroq}
    },
    _type == 'articleItem' => { ... }-> {..., heroImage${ImageGroq}, moduleImage${ImageGroq}},
    _type == 'imageCard' => {
      ...,
      'image': image${ImageGroq},
      'lottie': lottie${LottieGroq},
    },
  },
  'legendItems': legendItems[] {
    ...,
    'icon': icon${ImageGroq},
  },
  'items': items[] {
    ...,
    'image': image${ImageGroq},
    'lottie': lottie${LottieGroq},
  },
  'listItems': listItems[] {
    ...,
    'icon': icon${ImageGroq},
  },
  'images': images[] {
    ...,
    'image': image${ImageGroq},
  },
  'image': image${ImageGroq},
  'backgroundImage': backgroundImage${ImageGroq},
  'titleIcon': titleIcon${ImageGroq},
  'headerIcon': headerIcon${ImageGroq},
  'lottie': lottie${LottieGroq},
  'frontImage': frontImage${ImageGroq},
  'backImage': backImage${ImageGroq},
  'mobileImage': mobileImage${ImageGroq},
  'desktopImage': desktopImage${ImageGroq},
  'topImage': topImage${ImageGroq},
  'images': images[] {
    ...,
    'image': image${ImageGroq},
  },
  'cards': cards[] {
    ...,
    'image': image${ImageGroq},
  },
  'interviews': interviews[] {
    ...,
    questions[] -> ${EmbeddedInterviewQuestionGroq},
  },
  'customInterviews':  customInterviews[] -> {
    ...,
    questions[] -> ${EmbeddedInterviewQuestionGroq},
  },
  'products': products[] {
    ...,
    'image': image${ImageGroq}
  }
}`;

/*
 * The "relatedDiagnoses" array can contain two types of data:
 * (1) Manually added Dx placeholder, and
 * (2) Dx articles as references
 */
export const DxListModuleGroq = `
  _type == 'dxList' => {
    ...,
    'manualItems': relatedDiagnoses[] {
      _id,
      _type,
      title,
      'image': image${ImageGroq},
    },
    'referencedArticles': relatedDiagnoses[]->{
      _id,
      _type,
      'shortTitle': conditionName,
      diagnosisDescription,
      commonSymptoms,
      title,
      'imageBgColor': heroColor,
      slug,
      'image': heroImage${ImageGroq},
      symptomCheckerButtonUrl,
    },
  }
`;

export const ArticleBodyModuleGroq = `{
  ...,
  'moduleOptions': moduleOptions {
    ...,
    'infoSectionImage': infoSectionImage${ImageGroq},
    'supportingSectionImage': supportingSectionImage${ImageGroq},
  },
  'contents': contents[] {
    ...,
    _type == 'articleRef' => { ... }-> {...},
    _type == 'image' => { ..., asset->{...} },
    'image': image${ImageGroq},
    'articleRef': articleRef->{..., heroImage${ImageGroq}, moduleImage${ImageGroq}},
  },
  _type == 'articleText' => {
    ...,
    'text': text[] {
        ...,
        _type == 'articleImage' => {
        ...,
        'desktopImage': desktopImage{ ..., asset->{...} },
        'mobileImage': mobileImage{ ..., asset->{...} },
      },
      _type == 'articleCallout' => {
        ...,
        'supportingSectionImage': supportingSectionImage{..., asset->{...}}
      },
    },
  },
  ${DxListModuleGroq},
  'carouselItems': carouselItems[] {
    ...,
    _type == 'imageItem' => {
      ...,
      'image': image${ImageGroq}
    },
    _type == 'articleItem' => { ... }-> {..., heroImage${ImageGroq}, moduleImage${ImageGroq}},
    _type == 'imageCard' => {
      ...,
      'image': image${ImageGroq},
      'lottie': lottie${LottieGroq},
    },
  },
  'listItems': listItems[] {
    ...,
    'icon': icon${ImageGroq}
  },
  'items': items[] {
    ...,
    'image': image${ImageGroq},
    'lottie': lottie${LottieGroq},
  },
  'images': images[] {
    ...,
    'image': image${ImageGroq},
  },
  'image': image${ImageGroq},
  'backgroundImage': backgroundImage${ImageGroq},
  'titleIcon': titleIcon${ImageGroq},
  'lottie': lottie${LottieGroq},
  'topImage': topImage${ImageGroq},
  'frontImage': frontImage${ImageGroq},
  'backImage': backImage${ImageGroq},
  'headerIcon': headerIcon${ImageGroq},
  'cards': cards[] {
    ...,
    'image': image${ImageGroq},
  },
  'interviews': interviews[] {
    ...,
    questions[] -> ${EmbeddedInterviewQuestionGroq},
  },
  'customInterviews':  customInterviews[] -> {
    ...,
    questions[] -> ${EmbeddedInterviewQuestionGroq},
  },
  'products': products[] {
    ...,
    'image': image${ImageGroq}
  }
}`;

export const StoriesGroq = `*[_type == 'userStory' && article._ref == ^._id && (isDisapproved != true) && !(_id in path("drafts.**"))] | order(submissionDate desc) { 'name': firstNameInitial, 'id': _id, title, 'story': bodyText, 'date': submissionDate }`;

// GROQ projection to get blog categories with unfrozen blogs
export const BlogCategoriesWithUnfrozenBlogsGroq = `
  { 
    ...,
    'hasUnfrozenBlogs': count(*[_type == "blogPost" && references(^._id) && (frozen == false || !defined(frozen))]{slug}) > 0,
    'heroImage': heroImage${ImageGroq},
    'metaImage': metaImage${ImageGroq},
  }
  [hasUnfrozenBlogs == true]
`;
