import { Action, ShowcasePage, Video } from 'types';

export interface ShowcasePageReducer {
  pages: {
    [slug: string]: ShowcasePage;
  };
  activeVideo: Video | null;
}

const initialState: ShowcasePageReducer = {
  pages: {},
  activeVideo: null,
};

function showcasePage(
  state: ShowcasePageReducer = initialState,
  action: Action
): ShowcasePageReducer {
  switch (action.type) {
    case 'FETCH_SHOWCASE_PAGE_FULFILLED':
      if (!action.payload) {
        return state;
      }

      return {
        ...state,
        pages: {
          ...state.pages,
          [action.payload.slug]: action.payload,
        },
      };
    case 'SET_ACTIVE_VIDEO':
      return {
        ...state,
        activeVideo: action.payload,
      };
    default:
      return state;
  }
}

export default showcasePage;
