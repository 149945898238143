import memoize from 'lodash/memoize';

import { ContentTag } from 'types';
import sanitizeIcon from './sanitizeIcon';

export default memoize((contentTag: any): ContentTag => {
  return {
    text: contentTag?.text || '',
    icon: sanitizeIcon(contentTag),
  };
});
