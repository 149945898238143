import memoize from 'lodash/memoize';
import get from 'utils/get';

import sanitizeImage from 'state/sanitizers/sanitizeImage';
import sanitizeModuleOptions from 'state/sanitizers/modules/sanitizeModuleOptions';

import { ImageCard, ImageCardsModule } from 'types';

export default memoize((module: unknown): ImageCardsModule => {
  const imageCards: ImageCard[] = get(module, 'contents', []).map(
    (item: unknown) => {
      return {
        id: get(item, '_key', ''),
        title: get(item, 'title', ''),
        description: get(item, 'description', ''),
        image: sanitizeImage(get(item, 'image', '')),
      };
    },
  );

  return {
    type: get(module, '_type', 'imageCards'),
    id: get(module, '_key', ''),
    title: get(module, 'contentTitle', ''),
    imageCards: imageCards,
    moduleOptions: sanitizeModuleOptions(module),
  };
});
