import React from 'react';
import Head from 'next/head';
import ArticleStructuredDataSchema from './ArticleStructuredDataSchema';
import BlogPostStructuredDataSchema from './BlogPostStructuredDataSchema';
import ProviderPageStructuredDataSchema from './ProviderPageStructuredDataSchema';
import DefaultStructuredDataSchema from './DefaultStructuredDataSchema';
import usePage from 'hooks/usePage';

/**
 * This component is used to render JSON structured data schema.
 * The SEO team can manually enter custom schema through the Sanity CMS for specific pages.
 *
 * If a page contains custom schema, it will override the default.
 */
const StructuredDataSchema = () => {
  const pageContext = usePage();
  const page =
    pageContext.article ||
    pageContext.blogPost ||
    pageContext.drugPage ||
    pageContext.page ||
    pageContext.providerPage ||
    pageContext.quiz;

  if (!page) {
    return <DefaultStructuredDataSchema />;
  }

  if (page?.seo?.structuredData) {
    return (
      <Head>
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: page.seo.structuredData,
          }}
        />
      </Head>
    );
  }

  switch (page.type) {
    case 'article':
      return <ArticleStructuredDataSchema article={page} />;
    case 'blogPost':
      return <BlogPostStructuredDataSchema blogPost={page} />;
    case 'provider':
      return <ProviderPageStructuredDataSchema providerPage={page} />;
    default:
      return <DefaultStructuredDataSchema page={page} />;
  }
};

export default StructuredDataSchema;
