import React, { FC } from 'react';
import theme from '@buoyhealth/common.buoy-theme';
import { Svg } from 'styled/components/base';
import { Color } from 'styled/theme/colors';

type Props = {
  className?: string;
  color?: Color;
};

const NavCloseIcon: FC<Props> = ({
  className = '',
  color = theme.palette.primary.main,
}) => (
  <Svg
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    fill="transparent"
    className={className}
  >
    <desc>Nav Close Icon.</desc>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.22187 5.63604C3.83135 6.02656 3.83135 6.65973 4.22187 7.05025L9.17162 12L4.22187 16.9497C3.83135 17.3403 3.83135 17.9734 4.22187 18.364L5.63608 19.7782C6.02661 20.1687 6.65977 20.1687 7.0503 19.7782L12 14.8284L16.9498 19.7782C17.3403 20.1687 17.9735 20.1687 18.364 19.7782L19.7782 18.364C20.1687 17.9734 20.1687 17.3403 19.7782 16.9497L14.8285 12L19.7782 7.05025C20.1687 6.65973 20.1687 6.02656 19.7782 5.63604L18.364 4.22183C17.9735 3.8313 17.3403 3.8313 16.9498 4.22183L12 9.17157L7.0503 4.22183C6.65977 3.8313 6.02661 3.8313 5.63608 4.22183L4.22187 5.63604Z"
      fill={color}
    />
  </Svg>
);

export default NavCloseIcon;
