import type { LDClient } from 'launchdarkly-node-server-sdk';
import {
  LAUNCHDARKLY_LOGS_ENABLED,
  LAUNCHDARKLY_SDK_KEY,
} from 'constants/EnvVars';

let launchDarklyClient: LDClient;

async function initialize() {
  const LaunchDarkly = (await import('launchdarkly-node-server-sdk')).default;
  console.log(JSON.stringify(LaunchDarkly));
  const options = {
    ...(LAUNCHDARKLY_LOGS_ENABLED
      ? {
          logger: LaunchDarkly.basicLogger({
            level: 'debug',
          }),
        }
      : {}),
  };

  const client = LaunchDarkly.init(LAUNCHDARKLY_SDK_KEY as string, options);

  await client.waitForInitialization();

  return client;
}

export async function getClient() {
  if (launchDarklyClient) return launchDarklyClient;

  return (launchDarklyClient = await initialize());
}
