import memoize from 'lodash/memoize';
import sanitizeImage from 'state/sanitizers/sanitizeImage';
import { IFeaturedLinkModule } from 'types';

export default memoize((module: any): IFeaturedLinkModule => {
  return {
    type: module._type || 'featuredLink',
    id: module._key || '',
    description: module.description || [],
    buttonText: module.buttonText || '',
    buttonUrl: module.buttonUrl || '',
    image: sanitizeImage(module.image || ''),
    productName: module.productName || '',
    productSummary: module.productSummary || '',
    moduleHeader: module.moduleHeader || '',
    editorsChoice: module.editorsChoice || false,
    showTopDivider: module.showTopDivider || false,
  };
});
