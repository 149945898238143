import { Color } from 'styled/theme/colors';
import theme from '@buoyhealth/common.buoy-theme';

const { palette } = theme;

export const GeneralModuleColorMap: {
  [hexColor: string]: GeneralModuleColor;
} = {
  '#bed4fc': 'BLUE',
  '#e4f4f5': 'GREEN',
  '#fdefc8': 'ORANGE',
  '#f8edeb': 'ROSE',
  '#f7f7f7': 'GRAY',
  '#ffffff': 'WHITE',
};

export type GeneralModuleColor =
  | 'BLUE'
  | 'GREEN'
  | 'ORANGE'
  | 'ROSE'
  | 'GRAY'
  | 'WHITE';

export interface GeneralModuleColorValue {
  text: Color;
  background: Color;
  darkBackground: Color;
}

export const GeneralModuleColorScheme: {
  [color in GeneralModuleColor]: GeneralModuleColorValue;
} = {
  BLUE: {
    text: palette.text.primary,
    background: palette.blue[10],
    darkBackground: palette.blue[20],
  },
  GREEN: {
    text: palette.text.primary,
    background: palette.teal[0],
    darkBackground: palette.teal[10],
  },
  ORANGE: {
    text: palette.text.primary,
    background: palette.orange[0],
    darkBackground: palette.orange[10],
  },
  ROSE: {
    text: palette.text.primary,
    background: palette.rose[0],
    darkBackground: palette.rose[10],
  },
  GRAY: {
    text: palette.text.primary,
    background: palette.gray[0],
    darkBackground: palette.gray[10],
  },
  WHITE: {
    text: palette.text.primary,
    background: palette.common.white,
    darkBackground: palette.gray[0],
  },
};

export const EmphasizeModuleColorMap: {
  [hexColor: string]: EmphasizeModuleColor;
} = {
  '#8348ef': 'PURPLE',
  '#183d85': 'NAVY',
  '#162d5b': 'DARK_BLUE',
  '#196cff': 'BLUE',
  '#bed4fc': 'LIGHTEST_BLUE',
  '#ecf2fe': 'PALE_BLUE',
  '#719b9d': 'GREEN',
  '#e4f4f5': 'LIGHTEST_GREEN',
  '#ca822f': 'ORANGE',
  '#fdefc8': 'LIGHTEST_ORANGE',
  '#c6806c': 'ROSE',
  '#f8edeb': 'LIGHTEST_ROSE',
  '#ffffff': 'WHITE',
};

export type EmphasizeModuleColor =
  | 'PURPLE'
  | 'NAVY'
  | 'DARK_BLUE'
  | 'BLUE'
  | 'LIGHTEST_BLUE'
  | 'PALE_BLUE'
  | 'GREEN'
  | 'LIGHTEST_GREEN'
  | 'ORANGE'
  | 'LIGHTEST_ORANGE'
  | 'ROSE'
  | 'LIGHTEST_ROSE'
  | 'WHITE';

export interface EmphasizeModuleColorValue {
  text: Color;
  background: Color;
  highlight: Color;
}

// Header, Footer, Single-Block CTA, Article Hero, DrugPage Hero & Nav
export const EmphasizeModuleColorScheme: {
  [color in EmphasizeModuleColor]: EmphasizeModuleColorValue;
} = {
  // Confirm with designer if the highlight color should change when working on DrugPages where highlight color is used.
  PURPLE: {
    text: palette.common.white,
    background: palette.purple[80],
    highlight: palette.purple[80],
  },
  NAVY: {
    text: palette.common.white,
    background: palette.blue[90],
    highlight: palette.blue[100],
  },
  DARK_BLUE: {
    text: palette.common.white,
    background: palette.blue[100],
    highlight: palette.blue[90],
  },
  BLUE: {
    text: palette.common.white,
    background: palette.primary.main,
    highlight: palette.blue[60],
  },
  LIGHTEST_BLUE: {
    text: palette.text.primary,
    background: palette.blue[10],
    highlight: palette.blue[20],
  },
  PALE_BLUE: {
    text: palette.text.primary,
    background: palette.blue[0],
    highlight: palette.blue[10],
  },
  GREEN: {
    text: palette.common.white,
    background: palette.teal[50],
    highlight: palette.teal[60],
  },
  LIGHTEST_GREEN: {
    text: palette.text.primary,
    background: palette.teal[0],
    highlight: palette.teal[10],
  },
  ORANGE: {
    text: palette.common.white,
    background: palette.orange[50],
    highlight: palette.orange[60],
  },
  LIGHTEST_ORANGE: {
    text: palette.text.primary,
    background: palette.orange[0],
    highlight: palette.orange[10],
  },
  ROSE: {
    text: palette.common.white,
    background: palette.rose[50],
    highlight: palette.rose[60],
  },
  LIGHTEST_ROSE: {
    text: palette.text.primary,
    background: palette.rose[0],
    highlight: palette.rose[10],
  },
  WHITE: {
    text: palette.text.primary,
    background: palette.common.white,
    highlight: palette.common.white,
  },
};

export const SplitCtaColorMap: { [hexColor: string]: SplitCtaColor } = {
  '#adcafe': 'BLUE',
  '#d3ecee': 'GREEN',
  '#ca822f': 'DARK_ORANGE',
  '#ffe19f': 'ORANGE',
  '#f7ded7': 'ROSE',
  '#ffffff': 'WHITE',
};

export interface SplitCtaColorValue {
  text: Color;
  arrow: Color;
  background: Color;
  section: Color;
  sectionHover: Color;
}

export type SplitCtaColor =
  | 'BLUE'
  | 'GREEN'
  | 'DARK_ORANGE'
  | 'ORANGE'
  | 'ROSE'
  | 'WHITE';

export const SplitCtaColorScheme: {
  [color in SplitCtaColor]: SplitCtaColorValue;
} = {
  BLUE: {
    text: palette.text.primary,
    arrow: palette.blue[100],
    background: palette.blue[20],
    section: palette.blue[10],
    sectionHover: palette.blue[30],
  },
  GREEN: {
    text: palette.text.primary,
    arrow: palette.teal[100],
    background: palette.teal[10],
    section: palette.teal[0],
    sectionHover: palette.teal[30],
  },
  DARK_ORANGE: {
    text: palette.common.white,
    arrow: palette.orange[100],
    background: palette.orange[50],
    section: palette.orange[30],
    sectionHover: palette.orange[10],
  },
  ORANGE: {
    text: palette.text.primary,
    arrow: palette.orange[100],
    background: palette.orange[10],
    section: palette.orange[0],
    sectionHover: palette.orange[30],
  },
  ROSE: {
    text: palette.text.primary,
    arrow: palette.rose[100],
    background: palette.rose[10],
    section: palette.rose[0],
    sectionHover: palette.rose[30],
  },
  WHITE: {
    text: palette.text.primary,
    arrow: palette.text.primary,
    background: palette.common.white,
    section: palette.gray[10],
    sectionHover: palette.gray[30],
  },
};

export interface CalloutColorValue {
  background: Color;
  supportingSectionBackground: Color;
  supportingSectionText: Color;
  infoSectionBackground: Color;
  infoSectionText: Color;
}

export const CalloutColorMap: { [hexColor: string]: CalloutColor } = {
  '#bed4fc': 'BLUE',
  '#e4f4f5': 'GREEN',
  '#f9ae0f': 'DARK_ORANGE',
  '#fdefc8': 'ORANGE',
  '#ab7060': 'DARKER_ROSE',
  '#f3ab96': 'DARK_ROSE',
  '#f8edeb': 'ROSE',
  '#ffffff': 'WHITE',
};

export type CalloutColor =
  | 'BLUE'
  | 'GREEN'
  | 'DARK_ORANGE'
  | 'ORANGE'
  | 'DARKER_ROSE'
  | 'DARK_ROSE'
  | 'ROSE'
  | 'WHITE';

export const CalloutColorScheme: {
  [color in CalloutColor]: CalloutColorValue;
} = {
  BLUE: {
    background: palette.common.white,
    supportingSectionBackground: palette.blue[10],
    supportingSectionText: palette.text.primary,
    infoSectionBackground: palette.blue[0],
    infoSectionText: palette.text.primary,
  },
  GREEN: {
    background: palette.common.white,
    supportingSectionBackground: palette.teal[10],
    supportingSectionText: palette.text.primary,
    infoSectionBackground: palette.teal[0],
    infoSectionText: palette.text.primary,
  },
  DARK_ORANGE: {
    background: palette.common.white,
    supportingSectionBackground: palette.orange[60],
    supportingSectionText: palette.common.white,
    infoSectionBackground: palette.orange[30],
    infoSectionText: palette.text.primary,
  },
  ORANGE: {
    background: palette.common.white,
    supportingSectionBackground: palette.orange[10],
    supportingSectionText: palette.text.primary,
    infoSectionBackground: palette.orange[0],
    infoSectionText: palette.text.primary,
  },
  DARKER_ROSE: {
    background: palette.common.white,
    supportingSectionBackground: palette.rose[80],
    supportingSectionText: palette.common.white,
    infoSectionBackground: palette.rose[60],
    infoSectionText: palette.common.white,
  },
  DARK_ROSE: {
    background: palette.common.white,
    supportingSectionBackground: palette.rose[60],
    supportingSectionText: palette.common.white,
    infoSectionBackground: palette.rose[30],
    infoSectionText: palette.text.primary,
  },
  ROSE: {
    background: palette.common.white,
    supportingSectionBackground: palette.rose[10],
    supportingSectionText: palette.text.primary,
    infoSectionBackground: palette.rose[0],
    infoSectionText: palette.text.primary,
  },
  WHITE: {
    background: palette.common.white,
    supportingSectionBackground: palette.common.white,
    supportingSectionText: palette.text.primary,
    infoSectionBackground: palette.common.white,
    infoSectionText: palette.text.primary,
  },
};

// NoAdsChip used in Article Hero and Drug Page Hero
export interface NoAdsChipColorValue {
  text: Color;
  background: Color;
  border: Color;
}

export const NoAdsChipColorScheme: {
  [color in EmphasizeModuleColor]: NoAdsChipColorValue;
} = {
  PURPLE: {
    text: palette.common.white,
    background: palette.purple[70],
    border: palette.purple[30],
  },
  DARK_BLUE: {
    text: palette.common.white,
    background: palette.blue[90],
    border: palette.blue[10],
  },
  NAVY: {
    text: palette.common.white,
    background: palette.blue[100],
    border: palette.blue[30],
  },
  BLUE: {
    text: palette.common.white,
    background: palette.blue[70],
    border: palette.blue[30],
  },
  LIGHTEST_BLUE: {
    text: palette.text.primary,
    background: palette.blue[0],
    border: palette.blue[30],
  },
  /* Confirm with designer */
  PALE_BLUE: {
    text: palette.text.primary,
    background: palette.blue[0],
    border: palette.blue[30],
  },
  GREEN: {
    text: palette.common.white,
    background: palette.teal[60],
    border: palette.teal[30],
  },
  LIGHTEST_GREEN: {
    text: palette.text.primary,
    background: palette.teal[10],
    border: palette.teal[20],
  },
  ORANGE: {
    text: palette.common.white,
    background: palette.orange[60],
    border: palette.orange[40],
  },
  LIGHTEST_ORANGE: {
    text: palette.text.primary,
    background: palette.orange[10],
    border: palette.orange[30],
  },
  ROSE: {
    text: palette.common.white,
    background: palette.rose[60],
    border: palette.rose[20],
  },
  LIGHTEST_ROSE: {
    text: palette.text.primary,
    background: palette.rose[10],
    border: palette.rose[30],
  },
  WHITE: {
    text: palette.text.primary,
    background: palette.teal[10],
    border: palette.teal[30],
  },
};
