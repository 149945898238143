import { useState, useEffect, useMemo } from 'react';
import useBreakpoint from 'hooks/useBreakpoint';

const useBreakpointIsDesktop = (): boolean => {
  const currentBreakpoint = useBreakpoint();

  const [breakPointIsDesktop, setBreakPointIsDesktop] = useState(false);

  const isDesktop = useMemo(() => {
    return (
      !!currentBreakpoint &&
      ['LARGE', 'EXTRA_LARGE', 'EXTRA_EXTRA_LARGE'].includes(currentBreakpoint)
    );
  }, [currentBreakpoint]);

  useEffect(() => {
    setBreakPointIsDesktop(isDesktop);
  }, [isDesktop]);

  return breakPointIsDesktop;
};

export default useBreakpointIsDesktop;
