import React, { FC } from 'react';
import Head from 'next/head';
import {
  getAnswer,
  getQuestion,
} from 'utils/extractQuestionAndAnswerFromTextModule';
import baseSchema from './baseSchema';
import { useRouter } from 'next/router';

import type { WithContext, MedicalWebPage, FAQPage } from 'schema-dts';
import type { BlogPost, BlogPostModule, TextModule } from 'types';

interface Props {
  blogPost: BlogPost;
}

const BlogPostStructuredDataSchema: FC<Props> = ({ blogPost }) => {
  const { meta, seo, modules } = blogPost;
  const { author, publishedDate, updatedDate } = meta;
  const { asPath } = useRouter();

  const getBlogPostTextModulesWithSeoFaqEnabled = (
    modules: BlogPostModule[],
  ): TextModule[] | [] => {
    if (!modules) return [];

    const blogPostTextArray: TextModule[] = modules.reduce(
      (textModules: TextModule[], module: BlogPostModule) => {
        // only text modules with addToSeoFaqJsonSchema set to true should be returned
        if (module?.type === 'textModule' && module?.addToSeoFaqJsonSchema) {
          textModules.push(module);
        }

        return textModules;
      },
      [],
    );

    return blogPostTextArray;
  };

  const baseSchemaJson: WithContext<MedicalWebPage> = {
    ...baseSchema(asPath),
    headline: seo.title,
    description: seo.description,
    author: author
      ? {
          '@type': 'Person',
          name: author.fullName,
          sameAs: `https://www.buoyhealth.com/writers${author.slug}`,
        }
      : undefined,
    datePublished: publishedDate,
    dateModified: updatedDate,
    image: [seo.image.src],
  };

  const faqPageSchema = {
    '@context': 'https://schema.org',
    '@type': 'FAQPage',
    mainEntity: getBlogPostTextModulesWithSeoFaqEnabled(modules)?.map(
      (textModule) => ({
        '@type': 'Question',
        name: getQuestion(textModule.contents),
        acceptedAnswer: {
          '@type': 'Answer',
          text: getAnswer(textModule.contents),
        },
      }),
    ),
  } satisfies WithContext<FAQPage>;

  return (
    <Head>
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: JSON.stringify(baseSchemaJson),
        }}
      />
      {/* if addToSeoFaqJsonSchema is true, Q&A content will be added to the FAQPageSchema  */}
      {faqPageSchema.mainEntity?.length && (
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: JSON.stringify(faqPageSchema),
          }}
        />
      )}
    </Head>
  );
};

export default BlogPostStructuredDataSchema;
