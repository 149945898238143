import React, { useContext } from 'react';
import { AnchorLabel } from 'styled/components/base';
import { NavigationContext } from '../contexts';
import { NavBarLink, Text } from '../DesktopNavBar.style';
import { MenuItem } from '../DesktopNavLinks/DesktopNavLinks.style';
import { ArticleItem, ArticleItems } from './DesktopArticles.style';
import { ArticleItemImage } from '../../Navigation.style';
import {
  RandomDefaultImage,
  getOMDLinkConfig,
  getTrendingArticleLink,
  shouldShowOMDLinks,
} from '../../utils';
import {
  trackUserClicksOMDLink,
  trackUserClicksTrendingArticle,
} from '../../analytics';
import useLanguage from 'hooks/useLanguage';
import TwoPeopleDiscussingTreatment from 'styled/components/svg/TwoPeopleDiscussingTreatment';
import HealthCareWorkerTalkingToPatient from 'styled/components/svg/HealthCareWorkerTalkingToPatient';
import { useGlobalSettings } from 'state/selectors/globalSettings';

interface IDesktopArticleProps {
  renderPlaceholderColumn?: boolean;
  firstLevelSlug: string;
}

function DesktopArticles({
  renderPlaceholderColumn,
  firstLevelSlug,
}: IDesktopArticleProps) {
  const { trending } = useContext(NavigationContext);
  const language = useLanguage();
  const { trendingArticleImages } = useGlobalSettings();

  const { articles: baseArticles, label } = trending;
  const articles = baseArticles.filter(Boolean);

  if (!articles.length && !renderPlaceholderColumn) {
    return null;
  }

  return (
    <ArticleItems>
      {Boolean(articles.length) && (
        <MenuItem>
          <Text>Trending in {label.toLowerCase()}</Text>
        </MenuItem>
      )}
      {shouldShowOMDLinks(firstLevelSlug) && (
        <>
          <MenuItem>
            <Text>{language.t('Nav.getSeenNow')}</Text>
          </MenuItem>
          {getOMDLinkConfig(language).map(({ url, label }, i) => (
            <ArticleItem key={url}>
              <NavBarLink
                ariaLabel={label}
                to={url}
                onClick={() => trackUserClicksOMDLink(url)}
                openInCurrentTab={false}
              >
                <ArticleItemImage
                  color={i === 0 ? '#8CB5FE' : '#d4d0e7'}
                  title={label}
                >
                  {i === 0 ? (
                    <TwoPeopleDiscussingTreatment />
                  ) : (
                    <HealthCareWorkerTalkingToPatient />
                  )}
                </ArticleItemImage>
                <AnchorLabel>{label}</AnchorLabel>
              </NavBarLink>
            </ArticleItem>
          ))}
        </>
      )}
      {articles.map((article, i) => {
        const url = getTrendingArticleLink({
          article,
        });
        const trendingArticleImage = trendingArticleImages?.find(
          (imageConfig) => imageConfig?.articleId === article.id,
        );
        const imageILoading = !trendingArticleImages?.length;
        return (
          <ArticleItem key={article.slug}>
            <NavBarLink
              ariaLabel={article.title}
              to={url}
              onClick={() => trackUserClicksTrendingArticle(url)}
            >
              <ArticleItemImage
                color={trendingArticleImage?.heroColor}
                title={trendingArticleImage?.heroImage?.alt}
              >
                {!imageILoading &&
                  (trendingArticleImage?.heroImage?.src ? (
                    <img
                      src={`${trendingArticleImage?.heroImage.src}?h=200&auto=format`}
                    />
                  ) : (
                    <RandomDefaultImage index={i} />
                  ))}
              </ArticleItemImage>
              <AnchorLabel>{article.title}</AnchorLabel>
            </NavBarLink>
          </ArticleItem>
        );
      })}
    </ArticleItems>
  );
}

export default DesktopArticles;
