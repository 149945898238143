import memoize from 'lodash/memoize';
import { BlogPostModule } from 'types';
import sanitizeCtaModule from 'state/sanitizers/articleBodyModules/sanitizeCta';
import sanitizeBuyingGuide from './sanitizeBuyingGuide';
import sanitizeDataSetVisualsModule from './sanitizeDataSetVisualsModule';
import sanitizeEmbeddedInterview from './sanitizeEmbeddedInterview';
import sanitizeFeaturedLink from './sanitizeFeaturedLink';
import sanitizeGoogleFormModule from './sanitizeGoogleFormModule';
import sanitizeHighlightsModule from './sanitizeHighlightsModule';
import sanitizeImageWithCaptionModule from './sanitizeImageWithCaptionModule';
import sanitizeInsuranceFormModule from './sanitizeInsuranceFormModule';
import sanitizeInterviewModule from './sanitizeInterviewModule';
import sanitizeKatalysModule from '../sanitizeKatalysModule';
import sanitizeNumberedListWithImageModule from './sanitizeNumberedListWithImageModule';
import sanitizeQuoteModule from './sanitizeQuoteModule';
import sanitizeSupplierCtaModule from './sanitizeSupplierCtaModule';
import sanitizeTextModule from './sanitizeTextModule';
import sanitizeThreeColumnInfoModule from './sanitizeThreeColumnInfoModule';
import sanitizeVideoModule from './sanitizeVideoModule';
import sanitizeProsAndConsListModule from './sanitizeProsAndConsListModule';
import sanitizeProductCardsModule from './sanitizeProductCardsModule';
import sanitizeSupplierCardsModule from './sanitizeSupplierCardsModule';

export default memoize(function (modules?: unknown[]): BlogPostModule[] {
  return (modules || []).map((module: any) => {
    const type = module._type;

    if (!type) {
      return null;
    }

    switch (type) {
      case 'cta':
        return sanitizeCtaModule(module);
      case 'dataSetVisuals':
        return sanitizeDataSetVisualsModule(module);
      case 'embeddedInterview':
        return sanitizeEmbeddedInterview(module);
      case 'featuredLink':
        return sanitizeFeaturedLink(module);
      case 'googleFormModule':
        return sanitizeGoogleFormModule(module);
      case 'insuranceFormModule':
        return sanitizeInsuranceFormModule(module);
      case 'highlights':
        return sanitizeHighlightsModule(module);
      case 'imageWithCaption':
        return sanitizeImageWithCaptionModule(module);
      case 'interview':
        return sanitizeInterviewModule(module);
      case 'katalysModule':
        return sanitizeKatalysModule(module);
      case 'numberedListWithImage':
        return sanitizeNumberedListWithImageModule(module);
      case 'quote':
        return sanitizeQuoteModule(module);
      case 'supplierCtaModule':
        return sanitizeSupplierCtaModule(module);
      case 'threeColumnInfo':
        return sanitizeThreeColumnInfoModule(module);
      case 'textModule':
        // TODO: Remove this once we've introduced 'buyingGuide' schema
        return {
          ...sanitizeTextModule(module),
          ...((module?.title || '').toLowerCase().includes('buying guide')
            ? sanitizeBuyingGuide(module)
            : {}),
        };
      case 'videoModule':
        return sanitizeVideoModule(module);
      case 'prosAndConsList':
        return sanitizeProsAndConsListModule(module);
      case 'productCards':
        return sanitizeProductCardsModule(module);
      case 'supplierCards':
        return sanitizeSupplierCardsModule(module);
      default:
        return null;
    }
  });
});
