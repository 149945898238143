import styled from 'styled-components';
import { Theme, Base, ArticleCalloutPortableText } from 'styled';
import { CalloutColorValue } from 'constants/ColorScheme';

type SectionContainerProps = {
  colorScheme: CalloutColorValue;
  border?: boolean;
};

export const Container = styled(Base)`
  display: flex;
  flex-direction: column;
  margin: 2.5rem 0;

  ${Theme.mediaQueries.sm} {
    flex-direction: row;
  }

  *:last-child {
    margin-bottom: 0;
  }
`;

export const ImageContainer = styled(Base)`
  display: flex;
  align-items: center;

  img {
    width: 100%;
    max-height: 21.875rem;
    object-fit: cover;
  }

  ${Theme.mediaQueries.sm} {
    img {
      width: auto;
      max-width: 21.875rem;
    }
  }
`;

export const TextContainer = styled(Base)`
  display: flex;
  flex-direction: column;

  ${Theme.mediaQueries.sm} {
    flex-grow: 1;
  }
`;

export const InfoSectionContainer = styled(ArticleCalloutPortableText).attrs(
  (props: SectionContainerProps) => ({
    textColor: props.colorScheme.infoSectionText,
  }),
)<SectionContainerProps>`
  background-color: ${({ colorScheme }) => colorScheme.infoSectionBackground};
  display: flex;
  align-items: center;
  flex-grow: 1;
  padding: 1rem;
  border-bottom: ${({ border, theme }) =>
    border ? `1px solid ${theme.palette.gray[40]}` : undefined};
`;

export const SupportingSectionContainer = styled(
  ArticleCalloutPortableText,
).attrs((props: SectionContainerProps) => ({
  textColor: props.colorScheme.supportingSectionText,
}))<SectionContainerProps>`
  background-color: ${({ colorScheme }) =>
    colorScheme.supportingSectionBackground};
  padding: 1rem;
`;
