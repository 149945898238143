import { useSelector } from 'react-redux';
import { GlobalState } from 'types';

function useFeatureFlags() {
  const featureFlagsFromState = useSelector(
    (state: GlobalState) => state.featureFlags
  );

  return featureFlagsFromState;
}

export default useFeatureFlags;
