import Environments from 'constants/Environments';
import getHost from 'utils/getHost';

const isLocalhost = () => {
  /*
   * Server
   */
  if (typeof window === 'undefined') {
    return process.env.ENVIRONMENT === 'local';
  }

  /*
   * Browser
   */
  const host = getHost();
  return host === Environments.LOCAL.host;
};

export default isLocalhost;
