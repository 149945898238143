import { css, Keyframes, FlattenSimpleInterpolation } from 'styled-components';
import transitions from './transitions';
import {
  fadeOut,
  fadeOutDown,
  fadeIn,
  fadeInUp,
  fadeInUpShortDistance,
  open,
  close,
  growInWidth,
  slideUpWithJump,
} from './keyframes';

const EASE_IN_OUT_QUAD = 'cubic-bezier(0.455, 0.03, 0.515, 0.955)';

export const compose = (
  name: Keyframes,
  duration?: string
): FlattenSimpleInterpolation => {
  return css`
    animation: ${name} ${duration || '0s'} ${EASE_IN_OUT_QUAD} forwards;
  `;
};

export default {
  fadeOut: compose(fadeOut, transitions['transition-duration']),
  fadeOutShort: compose(fadeOut, transitions['transition-half']),
  fadeOutDown: compose(fadeOutDown, transitions['transition-duration']),
  fadeIn: compose(fadeIn, transitions['transition-duration']),
  fadeInUp: compose(fadeInUp, transitions['transition-duration']),
  fadeInUpShortDistance: compose(
    fadeInUpShortDistance,
    transitions['transition-long']
  ),
  open: compose(open, transitions['transition-duration']),
  openShort: compose(open),
  close: compose(close, transitions['transition-duration']),
  closeShort: compose(close),
  growInWidth: compose(growInWidth, transitions['transition-long']),
  slideUpWithJump: compose(slideUpWithJump, transitions['transition-duration']),
};
