import memoize from 'lodash/memoize';
import get from 'utils/get';

import sanitizeImage from 'state/sanitizers/sanitizeImage';

import { LogosModule } from 'types';

export default memoize((module: unknown): LogosModule => {
  return {
    type: get(module, '_type', 'logos'),
    id: get(module, '_key', ''),
    moduleTitle: get(module, 'moduleTitle', ''),
    moduleDescription: get(module, 'moduleDescription', []),
    images: get(module, 'images', []).map((item: unknown) => {
      return sanitizeImage(get(item, 'image', ''));
    }),
    renderLogosGrayscale: get(module, 'renderLogosGrayscale', false),
  };
});
