import { Action, IBrowsePage } from 'types';

export interface BrowseReducer {
  browsePagesBySlug: {
    [slug: string]: IBrowsePage;
  };
}

const initialState: BrowseReducer = {
  browsePagesBySlug: {},
};

function topic(
  state: BrowseReducer = initialState,
  action: Action
): BrowseReducer {
  switch (action.type) {
    case 'FETCH_BROWSE_PAGE_FULFILLED':
      if (action.payload) {
        return {
          ...state,
          browsePagesBySlug: {
            ...state.browsePagesBySlug,
            [action.payload.slug]: action.payload as IBrowsePage,
          },
        };
      }

      return state;
    default:
      return state;
  }
}

export default topic;
