import isProd from 'utils/isProd';
import Environments from 'constants/Environments';
import { RouteMap } from 'constants/Routes';

const buoyDomainRegex = /http(?:s)?:\/\/www\.buoy(?:health)?.com(.*)/i;
const productionDomain = `https://${Environments.PRODUCTION.host}`;

function sanitizeInternalLink(url: string): string {
  /*
   * When not in production, symptom checker and account routes
   * should not render as relative paths.
   */
  if (
    !isProd() &&
    [
      RouteMap.SYMPTOM_CHECKER.path,
      RouteMap.SIGN_UP.path,
      RouteMap.SIGN_IN.path,
    ].includes(url)
  ) {
    return `${productionDomain}${url}`;
  }

  if (
    !isProd() &&
    (url.includes('buoy.com/symptom-checker') ||
      url.includes('buoy.com/signup') ||
      url.includes('buoy.com/login'))
  ) {
    return url;
  }

  const results = url.match(buoyDomainRegex);

  if (!results) return url;

  return results[1];
}

export default sanitizeInternalLink;
