import memoize from 'lodash/memoize';
import get from 'utils/get';
import { RoundedBgLinkCardsModule } from 'types';
import sanitizeImage from 'state/sanitizers/sanitizeImage';

export default memoize((module: unknown): RoundedBgLinkCardsModule => {
  return {
    type: get(module, '_type', 'roundedBgLinkCardsModule'),
    id: get(module, '_key', ''),
    renderTwoCardsInARowForTablet: false, // When used in CMS, module cards always render one card per row
    cards: get(module, 'cards', []).map((card: unknown) => {
      return {
        id: get(card, '_key', ''),
        type: get(card, '_type', ''),
        title: get(card, 'title', ''),
        description: get(card, 'description', ''),
        image: sanitizeImage(get(card, 'image')),
        backgroundColor: get(card, 'backgroundColor', ''),
        url: get(card, 'url', ''),
      };
    }),
  };
});
