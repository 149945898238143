import memoize from 'lodash/memoize';
import get from 'utils/get';

import sanitizeImage from 'state/sanitizers/sanitizeImage';

import { SliderModule } from 'types';

export default memoize((module: unknown): SliderModule => {
  return {
    type: get(module, '_type', 'slider'),
    id: get(module, '_key', ''),
    title: get(module, 'title', ''),
    titleIcon: sanitizeImage(get(module, 'titleIcon')),
    image: sanitizeImage(get(module, 'image')),
    backgroundImage: sanitizeImage(get(module, 'backgroundImage')),
    slides: get(module, 'slides', []).map((slide: unknown) => {
      return {
        id: get(slide, '_key', ''),
        text: get(slide, 'text', ''),
        label: get(slide, 'label', ''),
      };
    }),
  };
});
