import { GoogleTagManagerTrackingEventData } from 'types';

function sendEventToGoogleTagManager(
  trackerData: GoogleTagManagerTrackingEventData
) {
  if (window['google_tag_manager'] && window.ga && window.ga.create) {
    window.dataLayer.push(trackerData);
  }
}

export default sendEventToGoogleTagManager;
