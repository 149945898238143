import get from 'utils/get';
import { PortableText } from 'types';

/**
 * This util does not add spacing in between blocks of text.
 * It's best to preserve content in its original form due to unpredictability of spacing in the content.
 */
const getPortableTextAsString = (
  portableText: PortableText | string,
): string => {
  if (typeof portableText === 'string') {
    return portableText;
  }

  return portableText.reduce(
    (text: string, portableTextBlock: unknown): string => {
      if (get(portableTextBlock, '_type') === 'block') {
        return (
          text +
          get(portableTextBlock, 'children', [])
            .map((child: unknown) => get(child, 'text', ''))
            .join('')
        );
      }

      return text;
    },
    '',
  );
};

export default getPortableTextAsString;
