import styled from 'styled-components';
import { Base } from 'styled';

import { Props, State } from './index';

type ContainerProps = Pick<Props, 'notificationBarIsActive'> & State;

export const Container = styled(Base)<ContainerProps>`
  background-color: ${({ theme }) => theme.palette.accent.main};
  color: ${({ theme }) => theme.palette.common.white};
  ${({ theme }) => theme.zIndex('notification-bar')};
  ${({ theme }) => theme.typography.variants.primary.xs};

  transform: ${({ notificationBarIsActive }) =>
    notificationBarIsActive ? 'none' : 'translate3d(0, -100%, 0)'};
  pointer-events: ${({ notificationBarIsActive }) =>
    notificationBarIsActive ? 'auto' : 'none'};

  ${({ removeWithAnimation, notificationBarIsActive, theme }) =>
    removeWithAnimation &&
    !notificationBarIsActive &&
    theme.animations.fadeOut};

  display: ${({ removeWithAnimation, notificationBarIsActive }) =>
    !removeWithAnimation && !notificationBarIsActive ? 'hidden' : 'flex'};
  justify-content: center;

  opacity: ${({ removeWithAnimation, notificationBarIsActive }) =>
    !removeWithAnimation && !notificationBarIsActive ? 0 : 'initial'};

  > p > a {
    color: ${({ theme }) => theme.palette.common.white};
    ${({ theme }) =>
      theme.hoverStyles.linkBorderBottom(theme.palette.common.white)};

    :hover,
    :focus {
      ${({ theme }) =>
        theme.hoverStyles.linkBorderBottomHidden(theme.palette.common.white)};
    }
  }
`;

export const Svg = styled(Base)`
  ${({ theme }) => theme.zIndex('1')};
  width: ${({ theme }) => theme.sizes.notificationBarCloseIcon};
  height: ${({ theme }) => theme.sizes.notificationBarCloseIcon};

  path {
    ${({ theme }) => theme.transitions['transition']};
  }
`;
