import React, { FC } from 'react';
import { Svg } from '../base';

type Props = {
  className?: string;
};

const PersonThinkingWithCrossBandaids: FC<Props> = ({ className = '' }) => (
  <Svg
    width="100%"
    height="100%"
    viewBox="0 0 2000 1400"
    fill="transparent"
    className={className}
  >
    <desc>Illustration of a person thinking with cross bandaids.</desc>
    <path
      d="M799.67 781.46C768.08 790.411 737.416 802.369 708.097 817.17C681.584 830.54 636.79 856.04 619.707 874.37C570.094 927.63 610.836 1074.54 711.51 1228.65C705.622 1237.24 700.51 1246.35 696.232 1255.85C687.571 1275.19 682.402 1295.91 680.965 1317.06L1091.56 1311.96V1238.85C1103.47 1240.13 1115.49 1239.76 1127.3 1237.75C1127.3 1237.75 1151.63 1233.6 1171.05 1221.84C1226.68 1188.15 1131.44 837.08 1044.91 798.84C998.243 778.21 948.899 775.06 948.899 775.06C945.317 774.83 942.383 774.72 940.557 774.66L799.67 781.46Z"
      fill="#F6AB0F"
    />
    <path
      d="M699.564 1172.54C693.457 1175.29 678.49 1183.09 670.726 1199.74C660.129 1222.47 667.294 1252.34 689.386 1273.4C713.219 1253.58 742.44 1241.39 773.266 1238.4L699.564 1172.54Z"
      fill="#8E6255"
    />
    <path
      opacity="0.16"
      d="M777.619 1048.41L740.289 965.1L773.557 999.36C795.031 955.86 816.508 912.363 837.989 868.87C817.872 928.717 797.749 988.563 777.619 1048.41Z"
      fill="black"
    />
    <path
      d="M935.41 1135.13H908.258C904.093 1168.28 890.555 1199.55 869.242 1225.25C891.552 1217.45 915.275 1214.54 938.803 1216.74C937.678 1189.54 936.547 1162.34 935.41 1135.13Z"
      fill="#8E6255"
    />
    <path
      opacity="0.16"
      d="M938.801 1216.74L1091.47 1277.96V1238.85L938.801 1216.74Z"
      fill="black"
    />
    <path
      d="M872.633 432.89C863.323 433.89 809.07 440.97 774.225 489C744.449 530 740.108 585.5 762.35 633.53C759.027 633.86 746.953 635.46 738.591 645.43C728.044 658.02 729.93 676.16 736.895 687.94C745.397 702.33 763.069 709.94 781.01 706.64C788.649 720.839 793.27 736.471 794.581 752.55C795.398 762.876 794.824 773.266 792.875 783.44C799.502 796.445 808.751 807.931 820.036 817.17C860.948 850.31 913.226 839.49 921.817 837.57C924.348 820.75 930.109 804.582 938.781 789.96C954.477 763.81 970.832 758.56 984.592 737.25C998.353 715.94 998.971 694.97 999.86 665.84C1002.12 591.36 971.76 533.99 957.441 507.71C944.155 483.357 928.222 460.552 909.933 439.71L872.633 432.89Z"
      fill="#8E6255"
    />
    <path
      opacity="0.21"
      d="M921.836 556.59C951.901 573.67 962.688 599.3 955.763 611.43C948.838 623.56 924.261 621.83 921.836 621.63C926.296 620.75 937.881 617.82 943.3 608.31C951.093 594.63 944.018 571.17 921.836 556.59Z"
      fill="black"
    />
    <path
      d="M766.592 536.19C790.425 530.798 812.941 520.687 832.819 506.45C852.697 492.214 869.535 474.14 882.344 453.29C904.454 457.789 925.046 467.89 942.155 482.63C945.039 474.01 951.056 452.12 943.432 426.51C941.636 420.51 934.98 398.16 918.636 387.01C893.54 369.89 847.439 380.45 809.869 417.59C810.169 415.92 816.216 380.9 799.681 370.4C782.578 359.54 752.583 382.12 738.603 398.46C707.29 435.08 717.049 486.46 719.514 497.93C712.608 504.12 701.053 516.31 695.336 534.93C687.423 560.72 695.575 583.13 701.702 599.93C708.857 619.387 719.988 637.133 734.382 652.03C737.889 644.61 744.164 638.867 751.856 636.039C759.547 633.211 768.04 633.523 775.503 636.91C780.071 603.085 777.03 568.664 766.602 536.17L766.592 536.19Z"
      fill="#162D5B"
    />
    <path
      d="M837.989 868.87C842.193 831.944 846.388 795.013 850.572 758.08C840.52 745.443 831.192 732.246 822.632 718.55C810.977 699.95 798.624 680.24 803.543 675.19C807.784 670.83 822.772 679.61 823.899 680.29C827.987 682.73 831.656 685.812 834.766 689.42C845.064 701.456 858.326 710.581 873.233 715.89V715.89H873.582C885.287 713.73 889.249 693.48 895.166 679.06C902.151 662.06 912.549 651.51 921.889 642.06C943.512 620.22 975.433 607.06 980.582 613.23C982.838 615.96 982.219 625.23 948.651 663.74C964.028 662.89 974.396 667.52 979.185 670.12C989.483 675.69 994.792 683.12 1004.64 696.9C1013.99 709.98 1018.61 716.52 1018.61 723.68C1018.61 735.09 1009.97 743.28 995.66 756.83C982.688 769.13 978.956 769.05 961.304 784.89C950.717 794.4 945.338 799.3 940.948 806.57C937.834 811.72 933.643 820.13 930.76 839.73C928.05 858.517 927.862 877.583 930.201 896.42L837.989 868.87Z"
      fill="#A36E62"
    />
    <path
      d="M1060.74 433.77C1057.5 435.77 1048.76 442.22 1047.41 453.06C1045.63 466.95 1056.91 480.06 1069.62 484.24C1083.37 488.75 1102.1 483.89 1108.14 469.39C1113.05 457.61 1107.57 444.24 1099.25 436.73C1088.28 426.83 1071.9 426.7 1060.74 433.77Z"
      fill="white"
    />
    <path
      d="M1021.23 514.37C1017.24 517.37 1015.68 522.81 1016.29 527.29C1017.37 535.08 1025.28 542.66 1034.07 540.65C1042.57 538.65 1047.04 528.8 1044.44 521.35C1041.22 512.27 1028.21 509.17 1021.23 514.37Z"
      fill="white"
    />
    <path
      d="M1007.45 220C995.179 268 1013.62 309.14 1017.83 318C1062.01 411 1189.4 417.62 1214.85 419C1249.44 420.81 1367.71 427 1417.79 343.29C1456.3 278.94 1427.9 205.92 1425.19 199.29C1386.6 104.47 1275.1 87.4398 1256.33 84.9798C1154.69 71.5998 1031.25 126.82 1007.45 220Z"
      fill="white"
    />
    <path
      opacity="0.09"
      d="M908.664 1133.54C930.284 1056.48 937.577 976.093 930.178 896.39C926.772 868.903 929.082 841.008 936.963 814.46C936.903 821.69 936.903 832.29 937.273 845.07C939.707 929.94 953.089 941.31 961.401 1024.91C965.502 1066.14 965.831 1099.35 982.106 1104.75C985.02 1105.75 990.448 1104.75 1001.31 1102.91C1023.76 1099.03 1037.06 1096.97 1043.09 1087.47C1047.95 1079.81 1046.8 1071.08 1045.75 1063.94C1042.65 1042.71 1038.38 1016.3 1032.53 985.61C1054.17 1021.17 1068.19 1060.86 1073.68 1102.15C1069.18 1104.06 1061.86 1107.09 1052.72 1110.39C1015.16 1124.02 975.747 1131.83 935.836 1133.54C929.839 1133.77 920.389 1134 908.664 1133.54Z"
      fill="black"
    />
    <path
      opacity="0.18"
      d="M882.381 453.29C884.944 447.582 886.849 441.6 888.059 435.46C890.434 423.39 888.977 416.46 891.562 415.58C894.944 414.47 901.74 424.93 905.532 433.12C909.889 442.575 912.208 452.846 912.337 463.26C902.773 458.746 892.707 455.389 882.352 453.26L882.381 453.29Z"
      fill="black"
    />
    <path
      opacity="0.21"
      d="M772.958 648.14C767.799 643.19 759.527 645.81 756.932 646.64C756.184 646.87 746.455 650.13 742.414 659.64C737.494 671.25 743.411 686.31 754.388 692.77C765.364 699.23 782.817 697.58 786.379 689.06C788.784 683.3 784.184 676.17 782.388 673.7C777.398 674.57 772.818 671.34 772.08 667.17C771.863 665.48 772.158 663.764 772.924 662.244C773.691 660.723 774.895 659.467 776.381 658.64C776.51 657.31 776.71 651.78 772.958 648.14Z"
      fill="black"
    />
    <path
      opacity="0.16"
      d="M699.564 1172.54C684.826 1152.32 669.748 1130.15 654.76 1106C640.66 1083.29 628.177 1061.29 617.121 1040.31C628.011 1071.65 640.859 1102.28 655.588 1132C664.649 1150.27 673.959 1167.28 683.269 1183L699.564 1172.54Z"
      fill="black"
    />
    <path
      d="M1250.16 177.134L1135.61 291.925C1120.64 306.929 1120.64 331.255 1135.61 346.259C1150.58 361.263 1174.86 361.263 1189.83 346.259L1304.38 231.468C1319.35 216.464 1319.35 192.137 1304.38 177.134C1289.4 162.13 1265.13 162.13 1250.16 177.134Z"
      fill="#F0A893"
    />
    <path
      d="M1264.13 187.07C1265.71 187.07 1266.99 185.785 1266.99 184.2C1266.99 182.615 1265.71 181.33 1264.13 181.33C1262.54 181.33 1261.26 182.615 1261.26 184.2C1261.26 185.785 1262.54 187.07 1264.13 187.07Z"
      fill="#F5C3B6"
    />
    <path
      d="M1251.83 199.19C1253.41 199.19 1254.69 197.905 1254.69 196.32C1254.69 194.735 1253.41 193.45 1251.83 193.45C1250.25 193.45 1248.96 194.735 1248.96 196.32C1248.96 197.905 1250.25 199.19 1251.83 199.19Z"
      fill="#F5C3B6"
    />
    <path
      d="M1275.19 197.74C1276.77 197.74 1278.06 196.455 1278.06 194.87C1278.06 193.285 1276.77 192 1275.19 192C1273.61 192 1272.33 193.285 1272.33 194.87C1272.33 196.455 1273.61 197.74 1275.19 197.74Z"
      fill="#F5C3B6"
    />
    <path
      d="M1262.91 209.87C1264.49 209.87 1265.77 208.585 1265.77 207C1265.77 205.415 1264.49 204.13 1262.91 204.13C1261.33 204.13 1260.04 205.415 1260.04 207C1260.04 208.585 1261.33 209.87 1262.91 209.87Z"
      fill="#F5C3B6"
    />
    <path
      d="M1286.03 208.84C1287.61 208.84 1288.89 207.555 1288.89 205.97C1288.89 204.385 1287.61 203.1 1286.03 203.1C1284.45 203.1 1283.16 204.385 1283.16 205.97C1283.16 207.555 1284.45 208.84 1286.03 208.84Z"
      fill="#F5C3B6"
    />
    <path
      d="M1273.74 220.96C1275.32 220.96 1276.61 219.675 1276.61 218.09C1276.61 216.505 1275.32 215.22 1273.74 215.22C1272.16 215.22 1270.88 216.505 1270.88 218.09C1270.88 219.675 1272.16 220.96 1273.74 220.96Z"
      fill="#F5C3B6"
    />
    <path
      d="M1296.15 219.23C1297.73 219.23 1299.02 217.945 1299.02 216.36C1299.02 214.775 1297.73 213.49 1296.15 213.49C1294.57 213.49 1293.29 214.775 1293.29 216.36C1293.29 217.945 1294.57 219.23 1296.15 219.23Z"
      fill="#F5C3B6"
    />
    <path
      d="M1283.87 231.35C1285.45 231.35 1286.74 230.065 1286.74 228.48C1286.74 226.895 1285.45 225.61 1283.87 225.61C1282.29 225.61 1281.01 226.895 1281.01 228.48C1281.01 230.065 1282.29 231.35 1283.87 231.35Z"
      fill="#F5C3B6"
    />
    <path
      d="M1138.8 225.552L1250.73 342.899C1265.37 358.237 1289.63 358.785 1304.94 344.122C1320.24 329.46 1320.79 305.14 1306.16 289.802L1194.22 172.455C1179.59 157.117 1155.32 156.569 1140.02 171.232C1124.71 185.894 1124.17 210.214 1138.8 225.552Z"
      fill="#F5C3B6"
    />
    <path
      d="M1222.1 313L1278.57 261L1217.99 197.5L1162.92 251L1222.1 313Z"
      fill="#FFD4CC"
    />
    <path
      d="M1146 214.56C1147.58 214.56 1148.86 213.275 1148.86 211.69C1148.86 210.105 1147.58 208.82 1146 208.82C1144.41 208.82 1143.13 210.105 1143.13 211.69C1143.13 213.275 1144.41 214.56 1146 214.56Z"
      fill="#F0A893"
    />
    <path
      d="M1157.81 227.15C1159.39 227.15 1160.68 225.865 1160.68 224.28C1160.68 222.695 1159.39 221.41 1157.81 221.41C1156.23 221.41 1154.95 222.695 1154.95 224.28C1154.95 225.865 1156.23 227.15 1157.81 227.15Z"
      fill="#F0A893"
    />
    <path
      d="M1156.9 203.71C1158.48 203.71 1159.76 202.425 1159.76 200.84C1159.76 199.255 1158.48 197.97 1156.9 197.97C1155.31 197.97 1154.03 199.255 1154.03 200.84C1154.03 202.425 1155.31 203.71 1156.9 203.71Z"
      fill="#F0A893"
    />
    <path
      d="M1168.71 216.3C1170.29 216.3 1171.57 215.015 1171.57 213.43C1171.57 211.845 1170.29 210.56 1168.71 210.56C1167.13 210.56 1165.84 211.845 1165.84 213.43C1165.84 215.015 1167.13 216.3 1168.71 216.3Z"
      fill="#F0A893"
    />
    <path
      d="M1168.2 193.1C1169.78 193.1 1171.06 191.815 1171.06 190.23C1171.06 188.645 1169.78 187.36 1168.2 187.36C1166.62 187.36 1165.34 188.645 1165.34 190.23C1165.34 191.815 1166.62 193.1 1168.2 193.1Z"
      fill="#F0A893"
    />
    <path
      d="M1180.02 205.69C1181.61 205.69 1182.89 204.405 1182.89 202.82C1182.89 201.235 1181.61 199.95 1180.02 199.95C1178.44 199.95 1177.16 201.235 1177.16 202.82C1177.16 204.405 1178.44 205.69 1180.02 205.69Z"
      fill="#F0A893"
    />
    <path
      d="M1178.8 183.19C1180.38 183.19 1181.66 181.905 1181.66 180.32C1181.66 178.735 1180.38 177.45 1178.8 177.45C1177.22 177.45 1175.93 178.735 1175.93 180.32C1175.93 181.905 1177.22 183.19 1178.8 183.19Z"
      fill="#F0A893"
    />
    <path
      d="M1190.61 195.77C1192.2 195.77 1193.48 194.485 1193.48 192.9C1193.48 191.315 1192.2 190.03 1190.61 190.03C1189.03 190.03 1187.75 191.315 1187.75 192.9C1187.75 194.485 1189.03 195.77 1190.61 195.77Z"
      fill="#F0A893"
    />
    <path
      d="M1255.65 328.23C1257.23 328.23 1258.52 326.945 1258.52 325.36C1258.52 323.775 1257.23 322.49 1255.65 322.49C1254.07 322.49 1252.79 323.775 1252.79 325.36C1252.79 326.945 1254.07 328.23 1255.65 328.23Z"
      fill="#F0A893"
    />
    <path
      d="M1267.48 340.82C1269.06 340.82 1270.34 339.535 1270.34 337.95C1270.34 336.365 1269.06 335.08 1267.48 335.08C1265.9 335.08 1264.61 336.365 1264.61 337.95C1264.61 339.535 1265.9 340.82 1267.48 340.82Z"
      fill="#F0A893"
    />
    <path
      d="M1266.55 317.38C1268.13 317.38 1269.41 316.095 1269.41 314.51C1269.41 312.925 1268.13 311.64 1266.55 311.64C1264.97 311.64 1263.68 312.925 1263.68 314.51C1263.68 316.095 1264.97 317.38 1266.55 317.38Z"
      fill="#F0A893"
    />
    <path
      d="M1278.37 329.97C1279.95 329.97 1281.24 328.686 1281.24 327.1C1281.24 325.515 1279.95 324.23 1278.37 324.23C1276.79 324.23 1275.51 325.515 1275.51 327.1C1275.51 328.686 1276.79 329.97 1278.37 329.97Z"
      fill="#F0A893"
    />
    <path
      d="M1277.86 306.77C1279.45 306.77 1280.73 305.485 1280.73 303.9C1280.73 302.315 1279.45 301.03 1277.86 301.03C1276.28 301.03 1275 302.315 1275 303.9C1275 305.485 1276.28 306.77 1277.86 306.77Z"
      fill="#F0A893"
    />
    <path
      d="M1289.68 319.36C1291.26 319.36 1292.54 318.075 1292.54 316.49C1292.54 314.905 1291.26 313.62 1289.68 313.62C1288.1 313.62 1286.82 314.905 1286.82 316.49C1286.82 318.075 1288.1 319.36 1289.68 319.36Z"
      fill="#F0A893"
    />
    <path
      d="M1288.45 296.86C1290.03 296.86 1291.31 295.575 1291.31 293.99C1291.31 292.405 1290.03 291.12 1288.45 291.12C1286.87 291.12 1285.59 292.405 1285.59 293.99C1285.59 295.575 1286.87 296.86 1288.45 296.86Z"
      fill="#F0A893"
    />
    <path
      d="M1300.28 309.44C1301.86 309.44 1303.14 308.155 1303.14 306.57C1303.14 304.985 1301.86 303.7 1300.28 303.7C1298.7 303.7 1297.41 304.985 1297.41 306.57C1297.41 308.155 1298.7 309.44 1300.28 309.44Z"
      fill="#F0A893"
    />
    <path
      d="M1154.61 295.64C1156.19 295.64 1157.47 294.355 1157.47 292.77C1157.47 291.185 1156.19 289.9 1154.61 289.9C1153.03 289.9 1151.75 291.185 1151.75 292.77C1151.75 294.355 1153.03 295.64 1154.61 295.64Z"
      fill="#F5C3B6"
    />
    <path
      d="M1142.81 308.24C1144.39 308.24 1145.67 306.955 1145.67 305.37C1145.67 303.785 1144.39 302.5 1142.81 302.5C1141.22 302.5 1139.94 303.785 1139.94 305.37C1139.94 306.955 1141.22 308.24 1142.81 308.24Z"
      fill="#F5C3B6"
    />
    <path
      d="M1166.08 305.88C1167.66 305.88 1168.95 304.595 1168.95 303.01C1168.95 301.425 1167.66 300.14 1166.08 300.14C1164.5 300.14 1163.22 301.425 1163.22 303.01C1163.22 304.595 1164.5 305.88 1166.08 305.88Z"
      fill="#F5C3B6"
    />
    <path
      d="M1154.28 318.47C1155.86 318.47 1157.14 317.186 1157.14 315.6C1157.14 314.015 1155.86 312.73 1154.28 312.73C1152.7 312.73 1151.41 314.015 1151.41 315.6C1151.41 317.186 1152.7 318.47 1154.28 318.47Z"
      fill="#F5C3B6"
    />
    <path
      d="M1177.35 316.54C1178.93 316.54 1180.22 315.255 1180.22 313.67C1180.22 312.085 1178.93 310.8 1177.35 310.8C1175.77 310.8 1174.49 312.085 1174.49 313.67C1174.49 315.255 1175.77 316.54 1177.35 316.54Z"
      fill="#F5C3B6"
    />
    <path
      d="M1165.54 329.14C1167.12 329.14 1168.4 327.855 1168.4 326.27C1168.4 324.685 1167.12 323.4 1165.54 323.4C1163.95 323.4 1162.67 324.685 1162.67 326.27C1162.67 327.855 1163.95 329.14 1165.54 329.14Z"
      fill="#F5C3B6"
    />
    <path
      d="M1187.87 326.53C1189.45 326.53 1190.73 325.245 1190.73 323.66C1190.73 322.075 1189.45 320.79 1187.87 320.79C1186.29 320.79 1185 322.075 1185 323.66C1185 325.245 1186.29 326.53 1187.87 326.53Z"
      fill="#F5C3B6"
    />
    <path
      d="M1176.06 339.12C1177.64 339.12 1178.93 337.835 1178.93 336.25C1178.93 334.665 1177.64 333.38 1176.06 333.38C1174.48 333.38 1173.2 334.665 1173.2 336.25C1173.2 337.835 1174.48 339.12 1176.06 339.12Z"
      fill="#F5C3B6"
    />
  </Svg>
);

export default PersonThinkingWithCrossBandaids;
