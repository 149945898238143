const GT_EESTI_DISPLAY = '"GT Eesti Display", sans-serif';
const SPECTRAL = '"Spectral", serif';
const weight = { regular: 400, bold: 600 };

export enum FontSizes {
  xxs = 'xxs', // 0.75rem (12px)
  xs = 'xs', // 0.875rem (14px)
  'xs-sm' = 'xs-sm', // 1rem (16px)
  sm = 'sm', // 1.125rem (18px)
  md = 'md', // 1.5rem (24px)
  'md-lg' = 'md-lg', // 2rem (32px)
  lg = 'lg', // 2.25rem (36px)
  xl = 'xl', // 3rem (48px)
  'xl-xxl' = 'xl-xxl', // 3.75rem (60px)
  xxl = 'xxl', // 4.5rem (72px)
}

export enum Fonts {
  GT_EESTI_DISPLAY = 'gt-eesti-display',
  SPECTRAL = 'spectral',
}

const size = {
  xxl: '4.5rem', // 72px
  'xl-xxl': '3.75rem', // 60px
  xl: '3rem', // 48px
  lg: '2.25rem', // 36px
  'md-lg': '2rem', // 32px
  md: '1.5rem', // 24px
  sm: '1.125rem', // 18px
  'xs-sm': '1rem', // 16px
  xs: '0.875rem', // 14px
  xxs: '0.75rem', // 12px
};

const lineHeight = {
  xxl: '4.75rem',
  'xl-xxl': '4.3125rem',
  xl: '3.4375rem',
  lg: '2.5625rem',
  'md-lg': '2.3rem',
  md: '1.75rem',
  sm: '1.375rem',
  'xs-sm': '1.2rem',
  xs: '1.05rem',
  xxs: '0.9rem',
};

const primary = {
  [FontSizes.xxl]: {
    fontFamily: GT_EESTI_DISPLAY,
    fontWeight: weight.regular,
    fontSize: size.xxl,
    lineHeight: lineHeight.xxl,
    letterSpacing: '0rem',
  },
  [FontSizes['xl-xxl']]: {
    fontFamily: GT_EESTI_DISPLAY,
    fontWeight: weight.regular,
    fontSize: size['xl-xxl'],
    lineHeight: lineHeight['xl-xxl'],
    letterSpacing: '0rem',
  },
  [FontSizes.xl]: {
    fontFamily: GT_EESTI_DISPLAY,
    fontWeight: weight.regular,
    fontSize: size.xl,
    lineHeight: lineHeight.xl,
    letterSpacing: '0rem',
  },
  [FontSizes.lg]: {
    fontFamily: GT_EESTI_DISPLAY,
    fontWeight: weight.regular,
    fontSize: size.lg,
    lineHeight: lineHeight.lg,
    letterSpacing: '0rem',
  },
  [FontSizes['md-lg']]: {
    fontFamily: GT_EESTI_DISPLAY,
    fontWeight: weight.regular,
    fontSize: size['md-lg'],
    lineHeight: lineHeight['md-lg'],
    letterSpacing: '0rem',
  },
  [FontSizes.md]: {
    fontFamily: GT_EESTI_DISPLAY,
    fontWeight: weight.regular,
    fontSize: size.md,
    lineHeight: lineHeight.md,
    letterSpacing: '0rem',
  },
  [FontSizes.sm]: {
    fontFamily: GT_EESTI_DISPLAY,
    fontWeight: weight.regular,
    fontSize: size.sm,
    lineHeight: lineHeight.sm,
    letterSpacing: '0rem',
  },
  [FontSizes['xs-sm']]: {
    fontFamily: GT_EESTI_DISPLAY,
    fontWeight: weight.regular,
    fontSize: size['xs-sm'],
    lineHeight: lineHeight['xs-sm'],
    letterSpacing: '0rem',
  },
  [FontSizes.xs]: {
    fontFamily: GT_EESTI_DISPLAY,
    fontWeight: weight.regular,
    fontSize: size.xs,
    lineHeight: lineHeight.xs,
    letterSpacing: '0rem',
  },
  [FontSizes.xxs]: {
    fontFamily: GT_EESTI_DISPLAY,
    fontWeight: weight.regular,
    fontSize: size.xxs,
    lineHeight: lineHeight.xxs,
    letterSpacing: '0rem',
  },
};

const secondary = {
  [FontSizes.lg]: {
    fontFamily: SPECTRAL,
    fontWeight: weight.regular,
    fontSize: size['md-lg'],
    lineHeight: '2.8rem',
    letterSpacing: '-0.08rem',
  },
  [FontSizes.md]: {
    fontFamily: SPECTRAL,
    fontWeight: weight.regular,
    fontSize: size.md,
    lineHeight: '2.1rem',
    letterSpacing: '-0.06rem',
  },
  [FontSizes.sm]: {
    fontFamily: SPECTRAL,
    fontWeight: weight.regular,
    fontSize: size.sm,
    lineHeight: '1.5625rem',
    letterSpacing: '-0.04rem',
  },
  [FontSizes['xs-sm']]: {
    fontFamily: SPECTRAL,
    fontWeight: weight.regular,
    fontSize: size['xs-sm'],
    lineHeight: '1.4rem',
    letterSpacing: '-0.04rem',
  },
  [FontSizes.xs]: {
    fontFamily: SPECTRAL,
    fontWeight: weight.regular,
    fontSize: size.xs,
    lineHeight: '1.25rem',
    letterSpacing: '-0.025rem',
  },
  'xs-italic': {
    fontFamily: SPECTRAL,
    fontWeight: weight.regular,
    fontSize: size.xs,
    fontStyle: 'italic',
    lineHeight: '1.25rem',
    letterSpacing: '-0.025rem',
  },
  [FontSizes.xxs]: {
    fontFamily: SPECTRAL,
    fontWeight: weight.regular,
    fontSize: size.xxs,
    lineHeight: lineHeight.xs,
    letterSpacing: '-0.04rem',
  },
  'xxs-italic': {
    fontFamily: SPECTRAL,
    fontWeight: weight.regular,
    fontSize: size.xxs,
    lineHeight: lineHeight.xs,
    fontStyle: 'italic',
    letterSpacing: '-0.04rem',
  },
};

const link = {
  fontFamily: GT_EESTI_DISPLAY,
  fontWeight: weight.regular,
  fontSize: size.sm,
  lineHeight: '1.3125rem',
  letterSpacing: '0rem',
};

export default {
  spectral: SPECTRAL,
  'gt-eesti-display': GT_EESTI_DISPLAY,
  weight,
  size,
  lineHeight,
  FontSizes,
  variants: {
    primary,
    secondary,
    link,
  },
};
