import memoize from 'lodash/memoize';
import get from 'utils/get';

import {
  SurveyResponse,
  SurveyResponsePagesById,
  SurveyResponsePagesQuestionsById,
  SurveyResponseAnswer,
} from 'types';

export default memoize((data: unknown): SurveyResponse => {
  if (!get(data, 'id')) {
    return {
      id: null,
      pages: {},
    };
  }

  return {
    id: get(data, 'id'),
    pages: get(data, 'pages', []).reduce(
      (sanitizedPages: SurveyResponsePagesById, page: unknown) => {
        const pageId = get(page, 'id');
        const questions = get(page, 'questions', []);

        if (!questions.length) return sanitizedPages;

        sanitizedPages[pageId] = {
          id: get(page, 'id'),
          questions: questions.reduce(
            (
              sanitizedQuestions: SurveyResponsePagesQuestionsById,
              question: unknown,
            ) => {
              const questionId = get(question, 'id');
              const answers: SurveyResponseAnswer[] = [];

              get(question, 'answers', []).map((answer: unknown) => {
                const rowId = get(answer, 'row_id');
                const choiceId = get(answer, 'choice_id');

                const sanitizedAnswer: SurveyResponseAnswer = {
                  choice_id: choiceId,
                };

                if (rowId) {
                  sanitizedAnswer['row_id'] = rowId;
                }

                questions.concat(sanitizedAnswer);
              });

              sanitizedQuestions[questionId] = {
                id: questionId,
                answers,
              };

              return sanitizedQuestions;
            },
            {},
          ),
        };

        return sanitizedPages;
      },
      {},
    ),
  };
});
