import { z } from 'zod';
import { ImageSchema } from './image';
import { AuthorSchema, ReviewerSchema } from './author';

export const QuizSlug = z.object({
  current: z.string(),
  _type: z.string(),
});

export const QuizResponseSchema = z.object({
  _id: z.string(),
  title: z.string(),
  slug: QuizSlug,
  _type: z.string(),
  quizType: z.string(),
  quizBodyModuleRepeater: z.array(z.any()),
  canonicalUrl: z.string().optional(),
  metaDescription: z.string().optional(),
  metaImage: ImageSchema.optional(),
  keywords: z.array(z.string()).optional(),
  noindex: z.boolean().optional(),
  structuredData: z.object({ code: z.string().optional() }).optional(),
  metaTitle: z.string().optional(),
  author: AuthorSchema,
  reviewer: ReviewerSchema,
  publishedDate: z.string().optional(),
  modifiedDate: z.string().optional(),
  _createdAt: z.string().optional(),
  _updatedAt: z.string().optional(),
});

export type QuizResponse = z.infer<typeof QuizResponseSchema>;
