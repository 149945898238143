import memoize from 'lodash/memoize';
import get from 'utils/get';
import sanitizeImage from 'state/sanitizers/sanitizeImage';
import { Article } from 'types';

export default memoize((article: unknown): Article['pauseUxCta'] => {
  const variant = get(article, 'pauseUxCtaVariant', 'symptom-checker');

  if (variant === 'symptom-checker') {
    return {
      displayPopUp: get(article, 'displayBuoyAssistantCtaPopUp', false),
      variant: 'symptom-checker',
    };
  }

  return {
    displayPopUp: get(article, 'displayBuoyAssistantCtaPopUp', false),
    variant: 'buoy-services',
    header: get(article, 'pauseUxCtaHeader', ''),
    description: get(article, 'pauseUxCtaDescription', ''),
    backgroundColor: get(article, 'pauseUxCtaBackgroundColor', '#fff'),
    image: sanitizeImage(get(article, 'pauseUxCtaImage')),
    items: get(article, 'pauseUxCtaItems', []).map((listItem: unknown) => ({
      id: get(listItem, '_key'),
      type: get(listItem, '_type', 'listItem'),
      title: get(listItem, 'title', ''),
      subtitle: get(listItem, 'subtitle', ''),
      url: get(listItem, 'url', ''),
      image: sanitizeImage(get(listItem, 'image')),
    })),
    disclaimer: get(article, 'pauseUxCtaDisclaimer', ''),
  };
});
