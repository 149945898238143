import get from 'lodash/get';
import keys from 'lodash/keys';
import { Action, Article, ArticleUserStories } from 'types';
export interface ArticleReducer {
  [slug: string]: Article;
}

const initialState: ArticleReducer = {};

function article(
  state: ArticleReducer = initialState,
  action: Action
): ArticleReducer {
  switch (action.type) {
    case 'FETCH_ARTICLE_FULFILLED':
      if (get(action, 'payload.type') === 'article') {
        return {
          ...state,
          [get(action, 'payload.slug', '')]: action.payload as Article,
        };
      }
      return { ...state };
    case 'FETCH_ARTICLE_USER_STORIES_FULFILLED':
      const stories =
        action.payload.paginatedStories[action.payload.lastFetchedPageNumber];

      if (!action.payload || !stories.length) return state;

      const articleId = stories[0].articleId;
      const articleSlug: string =
        keys(state).find((k) => state[k].id === articleId) || '';

      return {
        ...state,
        [articleSlug]: {
          ...state[articleSlug],
          content: {
            ...state[articleSlug].content,
            articleUserStories: {
              ...state[articleSlug].content.articleUserStories,
              paginatedStories: {
                ...state[articleSlug].content.articleUserStories
                  .paginatedStories,
                ...action.payload.paginatedStories,
              },
              storiesById: {
                ...state[articleSlug].content.articleUserStories.storiesById,
                ...action.payload.storiesById,
              },
            } as ArticleUserStories,
          },
        },
      };
    case 'UPVOTE_ARTICLE_FULFILLED':
      if (!action.payload) return state;

      return {
        ...state,
        [action.payload.id]: {
          ...state[action.payload.id],
          content: {
            ...state[action.payload.id].content,
            articleRating: {
              ...state[action.payload.id].content.articleRating,
              likes: action.payload.likes,
            },
          },
        },
      };
    default:
      return state;
  }
}

export default article;
