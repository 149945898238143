import React, { FC } from 'react';
import theme from '@buoyhealth/common.buoy-theme';
import { Svg } from 'styled';
import { Color } from 'styled/theme/colors';

type Props = {
  className?: string;
  color?: Color;
};

const NotificationBarCloseIcon: FC<Props> = ({
  className = '',
  color = theme.palette.common.white,
}) => (
  <Svg
    width="11px"
    height="11px"
    viewBox="0 0 11 11"
    className={className}
    fill="transparent"
  >
    <desc>Close Icon.</desc>
    <path d="M0.999999 0.999999L10 10" stroke={color} strokeWidth="2" />
    <path d="M10 1L1 10" stroke={color} strokeWidth="2" />
  </Svg>
);

export default NotificationBarCloseIcon;
