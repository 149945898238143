import type { IEventEmitter } from '@buoy-components/polaris/types';

/**
 * EventEmitter class for handling events and listeners.
 */
class EventEmitter implements IEventEmitter {
  private listeners: {
    [key: string]: Array<(data: any) => void>;
  } = {};

  /**
   * Emit an event, calling all registered callbacks for that event.
   */
  emit(name: string, data: any) {
    if (this.listeners[name]) {
      this.listeners[name].forEach((callback) => callback(data));
    }
  }

  /**
   * De-register a callback from a specific event.
   */
  off(event: string, callback: (data: any) => void) {
    if (this.listeners[event]) {
      this.listeners[event] = this.listeners[event].filter(
        (listener) => listener !== callback,
      );
    }
  }

  /**
   * Register a callback for a specific event.
   */
  on(event: string, callback: (data: any) => void) {
    if (!this.listeners[event]) {
      this.listeners[event] = [];
    }

    this.listeners[event].push(callback);
  }
}

export default EventEmitter;
