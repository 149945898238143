import sha1 from 'sha1';
import { Buffer } from 'buffer';
import {
  StructuredEvent,
  enableActivityTracking,
  newTracker,
  trackPageView as spTrackPageView,
  trackSelfDescribingEvent,
  trackStructEvent,
} from '@snowplow/browser-tracker';
import { SNOWPLOW_COLLECTOR_URL } from '../constants/EnvVars';
import { SnowplowActivityTrackingConfig } from '../constants/Global';
import {
  LinkClickTrackingPlugin,
  enableLinkClickTracking,
  refreshLinkClickTracking,
} from '@snowplow/browser-plugin-link-click-tracking';
import { getRequestBase } from 'lib/constants';
import Environments from 'constants/Environments';

const APP_ID = 'buoy-public-site';
const TRACKER_ID = 'sp-public-site';

enum ContextSchema {
  CareResource = 'iglu:com.buoyhealth/care-resource/jsonschema/1-0-0',
}

export enum EventSchema {
  CareResourceImpression = 'iglu:com.buoyhealth.care-resources/care-resource-impression/jsonschema/1-0-0',
  ExperimentationExposure = 'iglu:com.buoyhealth/ab-test-assignment/jsonschema/1-0-0',
}

export function careResourceContext(name: string, token: string) {
  return {
    schema: ContextSchema.CareResource,
    data: {
      careCollectionResourceToken: token,
      name,
    },
  };
}

export function getSnowplowDomainHash(hostname: string) {
  if (!hostname) {
    return null;
  }

  return sha1(`${hostname}/`).slice(0, 4);
}
/*
 * Extract the Snowplow Domain User Information from the first-party cookie
 * set by the Snowplow JavaScript Tracker.
 *
 * Ref: https://docs.snowplowanalytics.com/docs/collecting-data/collecting-from-own-applications/javascript-trackers/javascript-tracker/cookies-local-storage/getting-the-userid-from-the-snowplow-cookie/
 */
export function getSnowplowUserInfo() {
  if (typeof window !== 'undefined') {
    const domainHash = getSnowplowDomainHash(window.location.hostname);

    const cookieName = '_sp_';
    const matcher = new RegExp(`${cookieName}id.${domainHash}=([^;]+);?`);
    const match = document.cookie.match(matcher);

    if (match?.[1]) {
      const split = match[1].split('.');

      return {
        sessionId: split?.[5],
        sessionIdx: split?.[2],
        userId: split?.[0],
      };
    } else {
      return {};
    }
  }
}

export function initializeSnowplowTracker() {
  if (typeof window !== 'undefined') {
    window.SNOWPLOW_TRACKERS = window.SNOWPLOW_TRACKERS || {};

    if (!window.SNOWPLOW_TRACKERS[TRACKER_ID]) {
      window.SNOWPLOW_TRACKERS[TRACKER_ID] = newTracker(
        TRACKER_ID,
        SNOWPLOW_COLLECTOR_URL,
        {
          appId: APP_ID,
          plugins: [LinkClickTrackingPlugin()],
          respectDoNotTrack: false,
        },
      );

      enableLinkClickTracking();
    }
  }
}

export function trackCustomEvent(
  schema: EventSchema,
  data: Record<string, unknown>,
  eventContext?: {
    schema: ContextSchema;
    data: Record<string, unknown>;
  },
) {
  if (typeof window !== 'undefined') {
    trackSelfDescribingEvent(
      {
        event: {
          schema,
          data,
        },
        context: eventContext ? [eventContext] : undefined,
      },
      [TRACKER_ID],
    );
  }
}

export function trackPageView() {
  if (typeof window !== 'undefined') {
    // FIXME: Test if we can move this to #initializeSnowplowTracker. Seems
    // weird that this is invoked each time we track page view.
    enableActivityTracking(SnowplowActivityTrackingConfig);

    refreshLinkClickTracking();
    spTrackPageView({}, [TRACKER_ID]);
  }
}

export function trackStructuredEvent(event: StructuredEvent) {
  if (typeof window !== 'undefined') {
    trackStructEvent(event, [TRACKER_ID]);
  }
}

export function getBazaarRedirectUrl(
  careResourceUrl: string,
  careResourceImpressionUuid: string,
  careCollectionResourceToken: string,
) {
  if (!careResourceUrl) return '';
  return (
    `${getRequestBase(Environments.LOCAL.bazaarPort)}/bazaar/redirect/` +
    `?careResourceURL=${Buffer.from(careResourceUrl, 'binary').toString(
      'base64',
    )}` +
    `&careResourceImpressionUuid=${Buffer.from(
      careResourceImpressionUuid,
    ).toString('base64')}` +
    `&careCollectionResourceToken=${Buffer.from(
      careCollectionResourceToken,
    ).toString('base64')}`
  );
}

/**
 * Ensure tracking event is fired only once.
 *
 * @example
 * const event = {
 *   action: "interview started",
 *   category: "interview"
 * }
 *
 * const key = `${event.category}:${event.action}`
 *
 * fireOnce(() => snowplowStructEvent(event), key)
 *
 * */
export function fireOnce(callback: () => void, key: string) {
  window.SNOWPLOW_EVENTS = window.SNOWPLOW_EVENTS || {};

  if (!window.SNOWPLOW_EVENTS[key]) {
    callback();

    window.SNOWPLOW_EVENTS[key] = Date.now();
  }
}
