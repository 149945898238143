// https://gist.github.com/reecelucas/cd110ece696cca8468db895281fa28cb#file-usescrolldirection-js

import { useState, useEffect } from 'react';

enum ScrollDirection {
  SCROLL_UP = 'up',
  SCROLL_DOWN = 'down',
}

type Config = {
  initialDirection: ScrollDirection.SCROLL_UP | ScrollDirection.SCROLL_DOWN;
  thresholdPixels?: number;
  off?: boolean;
};

const useScrollDirection = (config: Config) => {
  const { initialDirection, thresholdPixels = 0, off = false } = config;

  const [scrollDir, setScrollDir] = useState(initialDirection);

  useEffect(() => {
    let lastScrollY = window.pageYOffset;
    let ticking = false;

    const updateScrollDir = () => {
      const scrollY = window.pageYOffset;

      if (Math.abs(scrollY - lastScrollY) <= thresholdPixels) {
        // We haven't exceeded the threshold
        ticking = false;
        return;
      }

      setScrollDir(
        scrollY > lastScrollY
          ? ScrollDirection.SCROLL_DOWN
          : ScrollDirection.SCROLL_UP
      );
      lastScrollY = scrollY > 0 ? scrollY : 0;
      ticking = false;
    };

    const onScroll = () => {
      if (!ticking) {
        window.requestAnimationFrame(updateScrollDir);
        ticking = true;
      }
    };

    /**
     * Bind the scroll handler if `off` is set to false.
     * If `off` is set to true reset the scroll direction.
     */
    !off
      ? window.addEventListener('scroll', onScroll)
      : setScrollDir(initialDirection);

    return () => window.removeEventListener('scroll', onScroll);
  }, [initialDirection, thresholdPixels, off]);

  if (typeof window === 'undefined') return ScrollDirection.SCROLL_UP;

  return scrollDir;
};

export { ScrollDirection };
export default useScrollDirection;
