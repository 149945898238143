import { Action, Status } from 'types';

export interface StatusReducer {
  globalSettingsStatus: Status;
  postArticleUserStoryStatus: Status;
  fetchArticleUserStoriesStatus: Status;
  submitOnboardingFormStatus: Status;
  createSurveyResponseStatus: Status;
  updateSurveyResponseStatus: Status;
  submitDrugReviewStatus: Status;
  fetchDrugReviewsStatus: Status;
  upvoteDrugReviewStatus: Status;
}

const initialState: StatusReducer = {
  globalSettingsStatus: Status.IDLE,
  postArticleUserStoryStatus: Status.IDLE,
  fetchArticleUserStoriesStatus: Status.IDLE,
  submitOnboardingFormStatus: Status.IDLE,
  createSurveyResponseStatus: Status.IDLE,
  updateSurveyResponseStatus: Status.IDLE,
  submitDrugReviewStatus: Status.IDLE,
  fetchDrugReviewsStatus: Status.IDLE,
  upvoteDrugReviewStatus: Status.IDLE,
};

function status(
  state: StatusReducer = initialState,
  action: Action
): StatusReducer {
  switch (action.type) {
    case 'FETCH_GLOBAL_SETTINGS_PENDING':
      return { ...state, globalSettingsStatus: Status.PENDING };
    case 'FETCH_GLOBAL_SETTINGS_FULFILLED':
      return { ...state, globalSettingsStatus: Status.FULFILLED };
    case 'FETCH_GLOBAL_SETTINGS_REJECTED':
      return { ...state, globalSettingsStatus: Status.REJECTED };
    case 'POST_ARTICLE_USER_STORY_PENDING':
      return { ...state, postArticleUserStoryStatus: Status.PENDING };
    case 'POST_ARTICLE_USER_STORY_FULFILLED':
      return { ...state, postArticleUserStoryStatus: Status.FULFILLED };
    case 'POST_ARTICLE_USER_STORY_REJECTED':
      return { ...state, postArticleUserStoryStatus: Status.REJECTED };

    case 'FETCH_ARTICLE_USER_STORIES_PENDING':
      return { ...state, fetchArticleUserStoriesStatus: Status.PENDING };
    case 'FETCH_ARTICLE_USER_STORIES_FULFILLED':
      return { ...state, fetchArticleUserStoriesStatus: Status.FULFILLED };
    case 'FETCH_ARTICLE_USER_STORIES_REJECTED':
      return { ...state, fetchArticleUserStoriesStatus: Status.REJECTED };
    case 'SUBMIT_ONBOARDING_FORM_PENDING':
      return {
        ...state,
        submitOnboardingFormStatus: Status.PENDING,
      };
    case 'SUBMIT_ONBOARDING_FORM_FULFILLED':
      return {
        ...state,
        submitOnboardingFormStatus: Status.FULFILLED,
      };
    case 'SUBMIT_ONBOARDING_FORM_REJECTED':
      return {
        ...state,
        submitOnboardingFormStatus: Status.REJECTED,
      };

    case 'CREATE_SURVEY_RESPONSE_PENDING':
      return {
        ...state,
        createSurveyResponseStatus: Status.PENDING,
      };
    case 'CREATE_SURVEY_RESPONSE_FULFILLED':
      return {
        ...state,
        createSurveyResponseStatus: Status.FULFILLED,
      };
    case 'CREATE_SURVEY_RESPONSE_REJECTED':
      return {
        ...state,
        createSurveyResponseStatus: Status.REJECTED,
      };

    case 'UPDATE_SURVEY_RESPONSE_PENDING':
      return {
        ...state,
        updateSurveyResponseStatus: Status.PENDING,
      };
    case 'UPDATE_SURVEY_RESPONSE_FULFILLED':
      return {
        ...state,
        updateSurveyResponseStatus: Status.FULFILLED,
      };
    case 'UPDATE_SURVEY_RESPONSE_REJECTED':
      return {
        ...state,
        updateSurveyResponseStatus: Status.REJECTED,
      };

    case 'SUBMIT_DRUG_REVIEW_PENDING':
      return {
        ...state,
        submitDrugReviewStatus: Status.PENDING,
      };
    case 'SUBMIT_DRUG_REVIEW_FULFILLED':
      return {
        ...state,
        submitDrugReviewStatus: Status.FULFILLED,
      };
    case 'SUBMIT_DRUG_REVIEW_REJECTED':
      return {
        ...state,
        submitDrugReviewStatus: Status.REJECTED,
      };

    case 'FETCH_DRUG_REVIEWS_PENDING':
      return {
        ...state,
        fetchDrugReviewsStatus: Status.PENDING,
      };
    case 'FETCH_DRUG_REVIEWS_FULFILLED':
      return {
        ...state,
        fetchDrugReviewsStatus: Status.FULFILLED,
      };
    case 'FETCH_DRUG_REVIEWS_REJECTED':
      return {
        ...state,
        fetchDrugReviewsStatus: Status.REJECTED,
      };

    case 'UPVOTE_DRUG_REVIEW_PENDING':
      return {
        ...state,
        upvoteDrugReviewStatus: Status.PENDING,
      };
    case 'UPVOTE_DRUG_REVIEW_FULFILLED':
      return {
        ...state,
        upvoteDrugReviewStatus: Status.FULFILLED,
      };
    case 'UPVOTE_DRUG_REVIEW_REJECTED':
      return {
        ...state,
        upvoteDrugReviewStatus: Status.REJECTED,
      };

    default:
      return state;
  }
}

export default status;
