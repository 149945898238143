import React, { useCallback, useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import Router from 'next/router';
import theme from '@buoyhealth/common.buoy-theme';

import { differenceInDays } from 'date-fns';
import cx from 'classnames';
import BlockContent from '@sanity/block-content-to-react';
import localForage from 'localforage';

import { openPopUp, closePopUp } from 'state/actions/applicationUIActions';

import usePrevious from 'hooks/usePrevious';
import { BasePortableText, Button, LinkOrButton } from 'styled';

import useLanguage from 'hooks/useLanguage';
import {
  ListItem,
  ExternalLink,
  InternalLink,
} from 'constants/PortableTextSerializer';

import { GlobalState, PopUp as IPopUp } from 'types';

interface PassedProps {
  popUp: IPopUp;
}

interface StoreProps {
  popUpIsActive: boolean;
  notificationBarIsActive: boolean | null;
  notificationBarHeight: number;
}

interface DispatchProps {
  actions: {
    openPopUp(): void;
    closePopUp(): void;
  };
}

type Props = PassedProps & StoreProps & DispatchProps;

interface State {
  removeWithAnimation: boolean;
  displayNone: boolean;
}

function PopUp(props: Props) {
  const {
    actions,
    notificationBarHeight,
    notificationBarIsActive,
    popUp,
    popUpIsActive,
  } = props;

  const Language = useLanguage();
  const prevProps = usePrevious(props);
  const timeout = useRef<ReturnType<typeof setTimeout> | null>(null);
  const [state, setState] = useState<State>({
    displayNone: true,
    removeWithAnimation: false,
  });

  const setDisplayNoneTimer = useCallback(() => {
    timeout.current = setTimeout(
      () =>
        setState((s) => ({
          ...s,
          displayNone: true,
        })),
      1000
    );

    return timeout.current;
  }, []);

  const handleClosePopUp = useCallback(() => {
    actions.closePopUp();
    setDisplayNoneTimer();
  }, [actions, setDisplayNoneTimer]);

  const handlePopUpOpen = useCallback(() => {
    if (popUp.isActive) {
      localForage.getItem('popUpDismissDate', (_err, value) => {
        const difference = differenceInDays(
          new Date(),
          new Date(value as string)
        );
        if (difference >= 30) {
          actions.openPopUp();
          setState((s) => ({
            ...s,
            displayNone: false,
          }));
        } else {
          setState((s) => ({
            ...s,
            displayNone: true,
          }));
        }
      });
    } else {
      setState((s) => ({
        ...s,
        displayNone: true,
      }));
    }
  }, [actions, popUp]);

  useEffect(() => {
    if (prevProps?.popUpIsActive && !props.popUpIsActive) {
      setState((s) => ({
        ...s,
        removeWithAnimation: true,
      }));
      setDisplayNoneTimer();
    }

    if (!prevProps?.popUpIsActive && props.popUpIsActive) {
      setState((s) => ({
        ...s,
        displayNone: false,
      }));
    }
  }, [prevProps, props, setDisplayNoneTimer]);

  useEffect(() => {
    Router.events.on('routeChangeComplete', handlePopUpOpen);

    return () => {
      Router.events.off('routeChangeComplete', handlePopUpOpen);
    };
  }, [handlePopUpOpen]);

  useEffect(() => {
    return () => {
      if (timeout.current) {
        clearTimeout(timeout.current);
      }
    };
  }, [timeout]);

  const { displayNone, removeWithAnimation } = state;

  const continueLabel =
    popUp.continueButtonLabel || Language.t('PopUp.continueLabel');
  const secondaryButtonLabel = popUp.secondaryButtonLabel;
  const secondaryButtonLink = popUp.secondaryButtonLink;

  return (
    <div
      style={{
        paddingTop: notificationBarIsActive
          ? `${notificationBarHeight}px`
          : '0',
      }}
      className={cx(
        'PopUp fixed t0 l0 r0 b0 z-pop-up flex items-center justify-center',
        {
          'transition-fade-out events-none':
            !popUpIsActive && removeWithAnimation,
          'hidden opacity-0 events-none':
            !popUpIsActive && !removeWithAnimation,
          transition: popUpIsActive,
          'PopUp--display': !displayNone,
        }
      )}
      aria-hidden={!popUpIsActive}
    >
      <div className="PopUp__bg absolute t0 b0 r0 l0 bg-color-blue-80 transition-short w100 h100" />
      <div className="PopUp__container z-1 w100 h100 bg-color-white p1_5 md:p3 overflow-y-scroll flex flex-col justify-between">
        <BasePortableText
          textColor={theme.palette.blue[80]}
          className="PopUp__text-container text-xs"
        >
          <BlockContent
            blocks={popUp.text}
            serializers={{
              listItem: ListItem,
              marks: { link: ExternalLink, internalLink: InternalLink },
            }}
          />
        </BasePortableText>
        <div className="PopUp__button-container flex flex-col items-center">
          <Button
            ariaLabel={Language.t('PopUp.continueAriaLabel', {
              label: continueLabel,
            })}
            className="inline-flex"
            label={continueLabel}
            onClick={handleClosePopUp}
            variant="primary"
            tabIndex={!popUpIsActive ? -1 : undefined}
          />
          {secondaryButtonLabel && secondaryButtonLink && (
            <LinkOrButton
              ariaLabel={Language.t('Global.pageButtonAriaLabel', {
                title: secondaryButtonLabel,
              })}
              containerClassName="PopUp__decline-button-container"
              onClick={handleClosePopUp}
              to={secondaryButtonLink}
              tabIndex={!popUpIsActive ? -1 : undefined}
            >
              <span className="text-xs md-up_text-sm color-blue-80 link-border-bottom-blue">
                {secondaryButtonLabel}
              </span>
            </LinkOrButton>
          )}
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state: GlobalState): StoreProps => ({
  popUpIsActive: state.applicationUI.popUpIsActive,
  notificationBarIsActive: state.applicationUI.notificationBarIsActive,
  notificationBarHeight: state.applicationUI.notificationBarHeight,
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  actions: bindActionCreators(
    {
      closePopUp,
      openPopUp,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(PopUp);
