import React, { FC } from 'react';
import theme from '@buoyhealth/common.buoy-theme';
import styled from 'styled-components';
import { Svg } from 'styled/components/base';
import { Color } from 'styled/theme/colors';

type Props = {
  className?: string;
  primaryColor?: Color;
  secondaryColor?: Color;
  hoverColor?: Color;
};

const BuoyChatIcon: FC<Props> = ({
  className = '',
  primaryColor = theme.palette.primary.main,
  secondaryColor = theme.palette.common.white,
}) => (
  <Svg
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    className={className}
    fill="transparent"
  >
    <desc>Buoy Chat Icon.</desc>
    <path
      id="chat-icon-primary"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 24H24V12C24 5.372 18.628 0 12 0C5.373 0 0 5.372 0 12C0 18.627 5.373 24 12 24Z"
      fill={primaryColor}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.9796 13.6018C13.9796 14.6049 13.1677 15.4168 12.1646 15.4168C11.1623 15.4168 10.3496 14.6049 10.3496 13.6018C10.3496 12.5996 11.1623 11.7868 12.1646 11.7868C13.1677 11.7868 13.9796 12.5996 13.9796 13.6018ZM15.7629 13.6044C15.7629 11.6265 14.1451 10.0087 12.1654 10.0087C11.507 10.0087 10.8914 10.1861 10.3607 10.4948L10.3633 7.0714C10.3633 6.51327 9.96034 6.05716 9.46737 6.05716H8.57144V13.6018C8.57144 15.5866 10.1798 17.195 12.1654 17.195C14.1168 17.195 15.7629 15.6029 15.7629 13.6044Z"
      fill={secondaryColor}
    />
  </Svg>
);

export default styled(BuoyChatIcon)`
  path {
    ${({ theme }) => theme.transitions.transition};
  }

  :hover {
    path#chat-icon-primary {
      fill: ${({ hoverColor }) => hoverColor};
    }
  }
`;
