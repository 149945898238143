import { ITypeformModule } from 'components/modules/TypeformModule';
import memoize from 'lodash/memoize';

export default memoize((typeformModule: any): ITypeformModule | null => {
  const id = typeformModule._key;
  const typeformId = typeformModule.typeformId || '';

  if (!id || !typeformId) {
    return null;
  }

  return {
    id,
    type: typeformModule._type || 'typeformModule',
    typeformId,
    header: typeformModule.header || '',
  };
});
