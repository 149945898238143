import { useEffect, useRef } from 'react';

/**
 * Returns the previous value of a prop or state.
 */
function usePrevious<T>(value: T) {
  const ref = useRef<T>();

  useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref.current;
}

export default usePrevious;
