import memoize from 'lodash/memoize';
import get from 'utils/get';

import sanitizeImage from 'state/sanitizers/sanitizeImage';

import { HighlightedImagesModule } from 'types';

export default memoize((module: unknown): HighlightedImagesModule => {
  return {
    type: get(module, '_type', 'highlightedImages'),
    id: get(module, '_key', ''),
    title: get(module, 'title', ''),
    text: get(module, 'text', []),
    frontImage: sanitizeImage(get(module, 'frontImage')),
    backImage: sanitizeImage(get(module, 'backImage')),
  };
});
