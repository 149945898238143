import { css, FlattenSimpleInterpolation } from 'styled-components';
import { mediaQueries, sizes } from './config';

export const moduleContentPaddingX: FlattenSimpleInterpolation = css`
  padding-left: 1.5rem;
  padding-right: 1.5rem;

  ${mediaQueries.md} {
    padding-left: 5.25rem;
    padding-right: 5.25rem;
  }
`;

export const moduleWithCalloutContentPaddingX: FlattenSimpleInterpolation = css`
  ${mediaQueries.md} {
    padding-left: 6rem;
    padding-right: 6rem;
  }
`;

export const articleContentPadding: FlattenSimpleInterpolation = css`
  padding-left: 1.5rem;
  padding-right: 1.5rem;

  ${mediaQueries.sm} {
    padding-left: 2.25rem;
  }
`;

export const articleInnerTextFullWidth: FlattenSimpleInterpolation = css`
  margin-right: -1.5rem;
  margin-left: -1.5rem;
  min-width: calc(100% + 1.5rem * 2);

  ${mediaQueries.sm} {
    margin-left: -2.25rem;
    min-width: calc(100% + 1.5rem + 2.25rem);
  }
`;

export const articleInnerTextFullWidthMdUpExtraWide: FlattenSimpleInterpolation = css`
  ${articleInnerTextFullWidth};

  ${mediaQueries.md} {
    min-width: calc(120% + 1.5rem + 2.25rem);
  }
`;

export const articleMainPanelStyling: FlattenSimpleInterpolation = css`
  margin: 0 auto;
  padding-left: 1rem;
  padding-right: 1rem;

  ${mediaQueries.md} {
    padding-left: unset;
    padding-right: unset;
    width: ${sizes.articleContentWidthTablet};
  }

  ${mediaQueries.lg} {
    width: ${sizes.articleContentWidthXlDesktop};
  }
`;

export const blogPostContentPaddingX: FlattenSimpleInterpolation = css`
  padding-left: 1.5rem;
  padding-right: 1.5rem;

  ${mediaQueries.md} {
    padding-left: 0rem;
    padding-right: 0rem;
  }
`;

export const drugPageInnerMaxWidth: FlattenSimpleInterpolation = css`
  max-width: 46.875rem;
`;

export const managedMarketplacePageContentMaxWidth: FlattenSimpleInterpolation = css`
  max-width: 80rem;
`;

// Applies to the following CarouselModule types: VideoItem, ImageItem, TextItem, QuoteItem. The ArticleItem Carousel type has different width and margins.
export const carouselItemDescriptionContainerStyling: FlattenSimpleInterpolation = css`
  width: calc(100% + ${sizes.carouselMobilePadding} * 2);
  margin: calc(${sizes.carouselMobilePadding} * -1);
  margin-top: 1rem;
  padding: ${sizes.carouselMobilePadding};

  ${mediaQueries.md} {
    width: calc(100% + ${sizes.carouselDesktopPadding} * 2);
    margin: calc(${sizes.carouselDesktopPadding} * -1);
    margin-top: 1rem;
    padding: ${sizes.carouselDesktopPadding};
  }
`;

export default {
  moduleContentPaddingX,
  moduleWithCalloutContentPaddingX,
  articleContentPadding,
  articleInnerTextFullWidth,
  articleInnerTextFullWidthMdUpExtraWide,
  articleMainPanelStyling,
  blogPostContentPaddingX,
  drugPageInnerMaxWidth,
  carouselItemDescriptionContainerStyling,
  managedMarketplacePageContentMaxWidth,
};
