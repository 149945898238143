import { hasMany, Model } from 'miragejs';
import { PlanModel } from 'dev/mock-api/types';
import { mockPredictorsRoutes } from 'dev/mock-api/bazaar/predictors';
import { Predictor } from 'dev/mock-api/types';
import { ModelDefinition } from 'miragejs/-types';

export const PredictorModel: ModelDefinition<Predictor> = Model.extend({});

export const bazaarModels = {
  careCollection: Model.extend({ careResources: hasMany() }),
  careResource: Model.extend({ plans: hasMany() }),
  plan: PlanModel,
  predictor: PredictorModel,
};

export const mockApiBazaarRoutes = {
  ...mockPredictorsRoutes,
};
