import memoize from 'lodash/memoize';

import get from 'utils/get';

import { ArticleUserStories, ArticleUserStory } from 'types';

export default memoize(
  (response: unknown, pageNumber: number): ArticleUserStories => {
    const sanitizedStories = get(response, 'results', []).map(
      (userStory: unknown): ArticleUserStory =>
        sanitizeArticleUserStory(userStory, pageNumber),
    );

    return {
      totalCount: get(response, 'count', 0),
      lastFetchedPageNumber: pageNumber,
      paginatedStories: {
        [pageNumber]: sanitizedStories,
      },
      storiesById: sanitizedStories.reduce(
        (
          storiesById: ArticleUserStories['storiesById'],
          story: ArticleUserStory,
        ) => {
          storiesById[story.id] = story;
          return storiesById;
        },
        {},
      ),
    };
  },
);

function sanitizeArticleUserStory(
  userStory: unknown,
  pageNumber: number,
): ArticleUserStory {
  return {
    articleId: get(userStory, 'article_id', ''),
    id: get(userStory, 'id', ''),
    story: get(userStory, 'body', ''),
    name: get(userStory, 'first_initial', ''),
    title: get(userStory, 'title', ''),
    date: get(userStory, 'submitted', '').split(' ')[0],
    likes: get(userStory, 'likes', 0),
    pageNumber,
  };
}
