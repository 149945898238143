import memoize from 'lodash/memoize';
import type { SupplierCards } from 'types';

export default memoize((supplierCardsModule: any): SupplierCards | null => {
  const id = supplierCardsModule?._key;

  if (!id) {
    return null;
  }

  return {
    id,
    type: 'supplierCards',
    suppliers: supplierCardsModule.suppliers?.map((supplier: any) => ({
      header: supplier.header || '',
      headerIntroText: supplier.headerIntroText || '',
      bulletPoints: supplier.bulletPoints || [],
      buttonText: supplier.buttonText || '',
      buttonUrl: supplier.buttonUrl || '',
    })),
  };
});
