export const RouteMap = {
  HOME: {
    path: '/',
  },
  CONTENT_HUB_LANDING: {
    path: '/learn',
  },
  CONTENT_HUB_HEALTH_A_TO_Z: {
    path: '/learn/health-a-to-z',
  },
  GENERIC: {
    path: '/[slug]',
  },
  SHOWCASE: {
    path: '/showcase/[slug]',
    base: '/showcase',
  },
  AUTHOR: {
    path: '/writers/[slug]',
    base: '/writers',
  },
  BLOG: {
    path: '/blog/[slug]',
    base: '/blog',
  },
  CAMPAIGN: {
    path: '/campaigns/[slug]',
    base: '/campaigns',
  },
  ARTICLE: {
    path: '/learn/[slugOrLanguage]/[[...slugOrTopics]]',
    base: '/learn',
  },
  COST: {
    path: '/cost/[slugOrLanguage]/[[...slug]]',
    base: '/cost',
  },
  DRUG: {
    path: '/drugs/[slug]',
    base: '/drugs',
  },
  SYMPTOM_CHECKER: {
    path: '/symptom-checker',
  },
  SIGN_UP: {
    path: '/signup',
  },
  SIGN_IN: {
    path: '/login',
  },
  PROVIDERS: {
    path: '/providers/[kind]/[state]/[city]',
    base: '/providers',
  },
  QUIZ: {
    path: '/ai/[slug]/',
    base: '/ai',
  },
  // CMS Generic Pages
  ALMOST_READY: {
    path: '/almost-ready',
  },
  ACCESSIBILITY: {
    path: '/accessibility',
  },
  EDITORIAL: {
    path: '/editorial-quality',
  },
  BROWSE_CONDITIONS: {
    path: '/learn/conditions/',
    base: '/learn',
  },
  BROWSE_SYMPTOMS: {
    path: '/learn/symptoms/',
    base: '/learn',
  },
  BROWSE_OTHER_ARTICLES: {
    path: '/learn/other/',
    base: '/learn',
  },
  FIND_CARE: {
    path: '/find-care',
  },
};

export const RoutesWithoutPageHeroButton = [
  `${RouteMap.SHOWCASE.base}/`,
  `${RouteMap.ARTICLE.base}/`,
];

export const RoutesWithoutFooter = [
  `${RouteMap.CAMPAIGN.base}/`,
  RouteMap.ALMOST_READY.path,
];

export const RoutesWithMinimalNav = [`${RouteMap.SHOWCASE.base}/`];

export const ContentHubBaseRoutes = [`${RouteMap.ARTICLE.base}/`];

export const RoutesWithTabNavigation = [
  `${RouteMap.DRUG.base}/`,
  `${RouteMap.ARTICLE.base}/`,
];
