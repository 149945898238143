import { getLaunchDarklyUserId } from 'analytics';
import { v4 as uuid } from 'uuid';
import { COOKIE_NAME } from './constants';
import { getClient } from './LaunchDarklyClient';
import { FeatureFlagsContext } from 'types';

type FeatureFlagCookies = { [key: string]: string };

const USER_TYPE = {
  INTERNAL: 'buoy_internal',
  PAID: 'buoy_paid',
  PUBLIC: 'buoy_public',
  SEM: 'buoy_sem',
};

export function getCustomAttributes(ctx: FeatureFlagsContext): {
  page_type: string;
  user_type: string;
  pathname: string;
} {
  const { query, req } = ctx;

  const slug = query?.slug && typeof query.slug === 'string' ? query.slug : '';
  const pathname =
    (query?.pathname && typeof query.pathname === 'string'
      ? query.pathname
      : req.url) || '';

  return {
    page_type: slug,
    user_type: getUserType(query),
    pathname,
  };
}

function getFeatureFlagsFromCookies(cookies?: FeatureFlagCookies) {
  if (!cookies) {
    return {};
  }

  try {
    return JSON.parse(cookies[COOKIE_NAME]);
  } catch (err) {
    return {};
  }
}

async function getFeatureFlagsFromLaunchDarkly(ctx?: FeatureFlagsContext) {
  const { req } = ctx || {};

  const launchDarklyClient = await getClient();
  const userId = getLaunchDarklyUserId(req?.headers?.cookie) || uuid();

  if (!launchDarklyClient) {
    throw new Error('LD client is unavailable');
  }

  // ref: https://launchdarkly.github.io/node-server-sdk/interfaces/_launchdarkly_node_server_sdk_.LDUser.html
  const flagsState = await launchDarklyClient.allFlagsState({
    anonymous: true,
    key: userId,
    ...(ctx ? { custom: getCustomAttributes(ctx) } : {}),
  });

  if (!flagsState) {
    throw new Error('Failed to fetch LD flags state');
  }

  const flags = flagsState.allValues();

  if (!flags) {
    throw new Error('Failed to fetch LD flags');
  }

  return Object.fromEntries(
    Object.entries(flags).filter(([key]) => key.includes('-public-site'))
  );
}

export async function getFeatureFlags(ctx?: FeatureFlagsContext) {
  const { req } = ctx || {};

  const flagsFromCookies = getFeatureFlagsFromCookies(
    req?.cookies as FeatureFlagCookies
  );

  if (Object.keys(flagsFromCookies).length) {
    return flagsFromCookies;
  }

  if (typeof window !== 'undefined') {
    return {};
  }

  return getFeatureFlagsFromLaunchDarkly(ctx);
}

function getUserType(query: Record<string, unknown>) {
  if (query['data_quality_flag']) {
    return USER_TYPE.INTERNAL;
  }

  if (query['utm_medium']) {
    return USER_TYPE.SEM;
  }

  if (query.organization) {
    return USER_TYPE.PAID;
  }

  return USER_TYPE.PUBLIC;
}
