import styled from 'styled-components';

export const ArticleItems = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 1.5rem;
  width: 100%;
`;

export const ArticleItem = styled.li`
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 100%;

  .Link {
    font-size: 1.125rem;

    &:hover {
      > span {
        background-image: none;
      }
    }

    > span {
      padding-bottom: 0;
      padding-right: 1.5rem;
      padding-top: 0;

      &:first-child {
        padding-left: 0;
      }

      &:not(:first-child) {
        padding-left: 1.5rem;
      }
    }

    span {
      transition: none;
      white-space: normal;
    }
  }
`;
