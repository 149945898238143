import memoize from 'lodash/memoize';
import { IKatalysModule } from 'types';

export default memoize((module: any): IKatalysModule => {
  return {
    type: module._type || 'katalysModule',
    id: module._key || '',
    url: module.url || '',
    utmParams: module.utmParams || { medium: '', source: '' },
  };
});
