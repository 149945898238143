const docTypes = `
  _type in [
    'article',
    'articleGenericPage',
    'author',
    'blogCategory',
    'blogPost',
    'browsePage',
    'drug',
    'quiz',
    'genericPage',
  ]
  && (!defined(excludeFromSitemap) || !excludeFromSitemap)
`;

export const sitemapDocs = `
  *[${docTypes}] | order(
    lower(_type) asc,
    lower(title) asc,
    lower(lastName) asc,
    lower(name) asc,
  ) {
    ...,
    'categories': categories[]->{
      slug,
    },
  }
`;

export const sitemapDocsCount = `
  count(*[${docTypes}])
`;

export const sitemapDocsByTypeCount = `
  {
    'articles': count(*[_type == 'article']),
    'authors': count(*[_type == 'author' ]),
    'blogCategories': count(*[_type == 'blogCategory' ]),
    'blogs': count(*[_type == 'blogPost' ]),
    'browsePages': count(*[_type == 'browsePage' ]),
    'drugs': count(*[_type == 'drug' ]),
    'quizzes': count(*[_type == 'quiz' ]),

    'genericPages': count(*[
      (_type == 'genericPage' && (!defined(excludeFromSitemap)
      || !excludeFromSitemap))
      || _type == 'articleGenericPage'
    ]),
  }
`;
