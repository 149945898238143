import styled from 'styled-components';
import { Base, Theme } from 'styled';

type FooterProps = {
  pageHasMinimalNav: boolean;
};

export const ImageContainer = styled(Base).withConfig({
  shouldForwardProp: (prop, defaultValidatorFn) =>
    !['display', 'overflow'].includes(prop) && defaultValidatorFn(prop),
})`
  background-color: ${({ theme }) => theme.palette.blue[60]};
  max-height: 12.5rem;

  * {
    max-height: 12.5rem;
  }
`;

export const ImageText = styled(Base)`
  color: ${({ theme }) => theme.palette.common.white};
  ${({ theme }) => theme.typography.variants.primary.md};

  ${Theme.mediaQueries.md} {
    font-size: ${Theme.typography.size.lg};
    line-height: ${Theme.typography.lineHeight.lg};
  }
`;

export const Container = styled.footer<FooterProps>`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.palette.common.white};
`;

export const Inner = styled(Base)`
  border-top: 1px solid ${({ theme }) => theme.palette.blue[80]};

  ${Theme.mediaQueries.md} {
    border-top: none;
  }
`;

export const MenuContainer = styled(Base)`
  display: flex;
  flex-wrap: wrap;
`;

export const Menu = styled(Base)`
  width: 50%;
  margin-top: 2.25rem;
  padding-right: 1.5rem;

  ${Theme.mediaQueries.sm} {
    width: calc(100% / 3);
  }

  ${Theme.mediaQueries.md} {
    margin-top: 0;
    width: ${({ theme }) => theme.sizes.footerMenuWidthDesktop};
    padding-right: 2.25rem;
  }

  :nth-of-type(2) {
    ${Theme.mediaQueries.sm} {
      width: calc(100% / 3 * 2);
    }

    ${Theme.mediaQueries.md} {
      width: ${({ theme }) => theme.sizes.footerMenuWidthDesktop};
    }
  }

  :nth-of-type(3) {
    width: 100%;

    ${Theme.mediaQueries.sm} {
      width: calc(100% / 3);
    }

    ${Theme.mediaQueries.md} {
      width: ${({ theme }) => theme.sizes.footerMenuWidthDesktop};
    }
  }

  li {
    margin-top: 0.375rem;

    :first-of-type {
      margin-top: 1.5rem;

      ${Theme.mediaQueries.md} {
        margin-top: 2.25rem;
      }
    }
  }
`;

export const MenuTitle = styled.h4`
  display: inline-flex;
  color: ${({ theme }) => theme.palette.text.primary};
  ${({ theme }) => theme.typography.variants.primary.sm};
`;

export const MenuLink = styled(Base)`
  line-height: 1.6875rem;
  display: inline-flex;
`;

export const InfoContainer = styled(Base)`
  border-top: 1px solid ${({ theme }) => theme.palette.gray[10]};
`;

export const InfoLinks = styled(Base)``;

export const InfoLink = styled(Base)`
  display: inline-flex;
`;

export const CopyrightContainer = styled(Base)`
  ${({ theme }) => theme.typography.variants.primary.xs};
  color: ${({ theme }) => theme.palette.gray[90]};

  p {
    max-width: 23rem;
  }
`;
