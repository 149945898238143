import memoize from 'lodash/memoize';

import get from 'utils/get';

import { VideoModule } from 'types';

export default memoize((videoModule: any): VideoModule | null => {
  const id = get(videoModule, '_key');
  const vimeoId = get(videoModule, 'vimeoId', '');

  const youtubeId = videoModule.youtubeId || '';

  const videoId = vimeoId || youtubeId;

  if (!id || !videoId) {
    return null;
  }

  return {
    id,
    type: get(videoModule, '_type', 'videoModule'),
    vimeoId,
    youtubeId,
  };
});
