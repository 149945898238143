import applicationUI from 'state/reducers/applicationUI';
import article from 'state/reducers/article';
import authorPage from 'state/reducers/authorPage';
import blog from 'state/reducers/blog';
import browsePage from 'state/reducers/browsePage';
import campaignPage from 'state/reducers/campaignPage';
import drugPage from 'state/reducers/drugPage';
import featureFlags from 'state/reducers/featureFlags';
import genericPage from 'state/reducers/genericPage';
import geolocation from 'state/reducers/geolocation';
import globalSettings from 'state/reducers/globalSettings';
import i18n from 'state/reducers/i18n';
import showcasePage from 'state/reducers/showcasePage';
import status from 'state/reducers/status';
import userResearch from 'state/reducers/userResearch';

export default {
  applicationUI,
  article,
  authorPage,
  blog,
  browsePage,
  campaignPage,
  drugPage,
  featureFlags,
  genericPage,
  geolocation,
  globalSettings,
  i18n,
  showcasePage,
  status,
  userResearch,
};
