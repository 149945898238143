import ClientCookies from 'js-cookie';
import { FEATURE_FLAG } from 'constants/Experimentation';
import { Action, FeatureFlags } from 'types';

export type FeatureFlagsReducer = FeatureFlags;

function initialState() {
  if (typeof window !== 'undefined') {
    return JSON.parse(ClientCookies.get(FEATURE_FLAG.cookieName) || '{}') || {};
  }

  return {};
}

const featureFlagReducer = (
  state = initialState(),
  action: Action
): FeatureFlagsReducer => {
  switch (action.type) {
    case 'FETCH_FEATURE_FLAGS_FULFILLED':
      return action.payload;
    default:
      return state;
  }
};

export default featureFlagReducer;
