import memoize from 'lodash/memoize';

import type { BuyingGuideModule, PortableText } from 'types';

/**
  Translate a `textModule` schema to a `buyingGuide` schema. While we're testing this new module,
  the `buyingGuide` schema is not defined in Sanity. This is to make rolling back easier, because
  we won't have to perform a schema migration.

  TODO: Remove this translation when the `buyingGuide` schema is defined in Sanity and migrations
  have been run.

  The `textModule` schema has this shape:
  {
    ...
    contents: [
      PortableText, // H2 title (e.g. "Buying Guide")
      PortableText, // introduction text (e.g. "When it comes to choosing the...")
      PortableText, // H3 buying factor (e.g. "Dosage")
      PortableText, // supporting text for buying factor (e.g. "The dosage of the supplement is...")
      // 0+ buying factors
      PortableText, // conclusion text (e.g. "In summary, when choosing the best adaptogen...")
    ],
    ...
  }
 */
function translateTextModuleContents(module: any) {
  // Assume the first item in content is the H2 title.
  const [title, ...contents] = module?.contents || [];

  const buyingFactors: BuyingGuideModule['buyingFactors'] = [];
  const conclusion: BuyingGuideModule['conclusion'] = [];
  const introduction: BuyingGuideModule['introduction'] = [];

  let currentGrouping: PortableText[] = [];

  contents.forEach((content: any) => {
    // When there are no buying factors, any non-H3 content is assumed to be part of
    // the introduction text.
    if (
      !buyingFactors.length &&
      !currentGrouping.length &&
      content?.style !== 'h3'
    ) {
      introduction.push(content);

      // If the current item is an H3, assume it's the start of the next buying factor.
    } else if (content?.style == 'h3') {
      if (currentGrouping.length) {
        buyingFactors.push(currentGrouping);
      }

      currentGrouping = [content];

      // Assume that each buying factor will only have 1 paragraph of supporting text. Thus,
      // if the current item is a non-H3 and there has not been another supporting text block,
      // add the current item to the current grouping.
    } else if (content?.style !== 'h3' && currentGrouping.length < 2) {
      currentGrouping.push(content);

      // If there is already 1 paragraph of supporting text, assume that we've reached
      // the conclusion text.
    } else if (content?.style !== 'h3' && currentGrouping.length >= 2) {
      conclusion.push(content);

      if (currentGrouping.length) {
        buyingFactors.push(currentGrouping);
      }
    }
  });

  return {
    buyingFactors,
    conclusion,
    introduction,
    title,
  };
}

export default memoize((module: any): BuyingGuideModule => {
  const { buyingFactors, conclusion, introduction, title } =
    translateTextModuleContents(module);

  return {
    anchor: module?.anchor || '',
    buyingFactors,
    id: module?._key || '',
    conclusion,
    introduction,
    title,
    type: 'buyingGuide',
  };
});
