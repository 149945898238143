import { createContext } from 'react';

const NotificationBarContext = createContext<{
  isActive: boolean;
  height: number;
}>({
  isActive: false,
  height: 0,
});

export default NotificationBarContext;
