import memoize from 'lodash/memoize';
import get from 'utils/get';

import sanitizeImage from 'state/sanitizers/sanitizeImage';
import sanitizeAuthor from 'state/sanitizers/sanitizeAuthor';
import sanitizeBlogPostModules from './modules/sanitizeBlogPostModules';

import { BlogPost } from 'types';

export default memoize((blogPost: any): BlogPost | null => {
  const id = get(blogPost, '_id');

  if (!id) {
    return null;
  }

  return {
    id,
    title: get(blogPost, 'title', ''),
    slug: get(blogPost, 'slug', ''),
    type: get(blogPost, '_type', 'blogPost'),
    meta: {
      author: sanitizeAuthor(get(blogPost, 'author')),
      publishedDate:
        get(blogPost, 'publishedDate', '') ||
        get(blogPost, '_createdAt', '') ||
        '',
      updatedDate: get(blogPost, '_updatedAt', '') || '',
    },
    heroImage: sanitizeImage(get(blogPost, 'heroImage')),
    modules: sanitizeBlogPostModules(blogPost.moduleRepeater),
    seo: {
      title: get(blogPost, 'metaTitle', ''),
      description: get(blogPost, 'metaDescription', ''),
      image: sanitizeImage(get(blogPost, 'metaImage')),
      structuredData: get(blogPost, 'structuredData.code', null),
      canonicalUrl: get(blogPost, 'canonicalUrl', ''),
    },
    references: get(blogPost, 'references', []),
    categories:
      blogPost.categories?.map((c: any) => c.slug)?.filter(Boolean) || [],
    disableAds: blogPost.disableAds || false,
    frozen: blogPost.frozen || false,
    language: blogPost.language || 'en',
  };
});
