import memoize from 'lodash/memoize';
import get from 'utils/get';

import sanitizeModuleOptions from 'state/sanitizers/modules/sanitizeModuleOptions';
import sanitizeInternalLink from 'state/sanitizers/sanitizeInternalLink';

import getDefaultArticleUrl from 'utils/getDefaultArticleUrl';

import { LinkCardWithType, LinkCardsModule } from 'types';

export default memoize((module: unknown): LinkCardsModule => {
  const linkCards: LinkCardWithType[] = get(module, 'contents', []).map(
    (item: any) => {
      const type = get(item, '_type', '');

      if (type === 'article') {
        const title = get(item, 'moduleTitle', '') || get(item, 'title', '');

        return {
          type,
          id: get(item, '_key', ''),
          title,
          description: get(item, 'moduleDescription', ''),
          button: {
            ariaLabel: `Read "${title}".`,
            label: get(item, 'buttonLabel', 'Read article'),
            url: getDefaultArticleUrl({
              slug: item.slug,
              language: item.language,
              articleType: item.articleType,
            }),
            variant: get(item, 'buttonVariant', 'underline-black'),
          },
        };
      }

      return {
        type: 'manualContent',
        id: get(item, '_key', ''),
        title: get(item, 'title', ''),
        description: get(item, 'description', ''),
        button: {
          ariaLabel: get(item, 'buttonAriaLabel', ''),
          label: get(item, 'buttonLabel', ''),
          url: sanitizeInternalLink(get(item, 'buttonUrl', '')),
          variant: get(item, 'buttonVariant', 'underline-black'),
        },
      };
    },
  );

  return {
    type: get(module, '_type', 'linkCards'),
    id: get(module, '_key', ''),
    linkCards: linkCards,
    moduleOptions: sanitizeModuleOptions(module),
  };
});
