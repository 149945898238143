import memoize from 'lodash/memoize';

import { IInsuranceFormModule } from 'types';

export default memoize(
  (insuranceFormModule: any): IInsuranceFormModule | null => {
    const id = insuranceFormModule._key || '';

    if (!id) {
      return null;
    }

    return {
      id,
      trustedFormEnabled: insuranceFormModule.trustedFormEnabled || false,
      type: insuranceFormModule._type,
    };
  }
);
