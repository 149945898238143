import { createServer, Server, Serializer } from 'miragejs';
import { bazaarModels, mockApiBazaarRoutes } from './bazaar';
import { PublicSiteRegistry, IServerRoute } from './types';
import { SNOWPLOW_COLLECTOR_URL } from 'constants/EnvVars';
import { SITE_API_URL, BUOY_GPT_URL } from 'lib/constants';
import { BAZAAR_URL } from 'lib/BazaarClient/constants';
import predictorMappings from 'dev/data/pred_slug_map.json';

const translateServerRoutes = (serverRoutes: IServerRoute) => {
  return Object.entries(serverRoutes).map(([url, handler]) => ({
    url,
    handler,
  }));
};

const setPassthroughs = (server: Server<PublicSiteRegistry>) => {
  // Allow unhandled requests on the current domain to pass through
  server.passthrough();

  // Add URLs here that should have the ability to be toggled on/off in our dev tools panel
  server.passthrough(`${BAZAAR_URL}/**`);
  server.passthrough(`${SITE_API_URL}/**`);
  server.passthrough(`${BUOY_GPT_URL}/**`);

  // Add any urls here that should never be intercepted by mirage
  server.passthrough(`http://${SNOWPLOW_COLLECTOR_URL}/**`);
  server.passthrough(`http://localhost:9090/**`);
  server.passthrough('https://ws.zoominfo.com/**');
  server.passthrough('https://vimeo.com/**');
  server.passthrough('https://events.launchdarkly.com/**');
  server.passthrough('https://app.launchdarkly.com/**');
  server.passthrough('https://monitor.clickcease.com/**');
  server.passthrough('https://consentcdn.cookiebot.com/**');
  server.passthrough('https://aax.amazon-adsystem.com/**');
};

/**
 * Object describing routes and responses of mocked endpoints.
 * Add any routes and handlers here to be mocked.
 * */
export const MOCK_SERVER_ROUTES = {
  bazaar: translateServerRoutes(mockApiBazaarRoutes),
};

export const DEFAULT_ENABLED_ENDPOINTS: string[] = (() => {
  const result: string[] = [];

  Object.values(MOCK_SERVER_ROUTES).forEach((serverEndpoints) => {
    serverEndpoints.forEach(({ url }) => result.push(url));
  });

  return result;
})();

/**
 * Mirage insists opn using the ID property as the serializer,
 * whereas we use tokens. So let's just remove IDs from the responses.
 */
const NoIdSerializer = Serializer.extend({
  serialize<T>(record: { attrs: { id: T } }): unknown {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { id, ...rest } = record.attrs;
    return { ...rest };
  },
});

export function makeServer({
  environment = 'test',
  logging,
  endpoints = MOCK_SERVER_ROUTES,
  enabledEndpoints = DEFAULT_ENABLED_ENDPOINTS,
}: {
  environment?: string;
  logging?: boolean;
  endpoints?: typeof MOCK_SERVER_ROUTES;
  enabledEndpoints?: string[];
} = {}) {
  const enabledUrls = Object.values(endpoints)
    .flat()
    .filter((route) => enabledEndpoints.includes(route.url));

  return createServer({
    environment,
    logging,

    models: {
      ...bazaarModels,
    },

    fixtures: {
      predictors: predictorMappings,
    },

    serializers: {
      application: NoIdSerializer,
    },

    seeds(server: Server<PublicSiteRegistry>) {
      server.loadFixtures();
    },

    routes() {
      enabledUrls.forEach(({ handler }) => handler(this));

      setPassthroughs(this);
    },
  });
}
