import memoize from 'lodash/memoize';
import get from 'utils/get';
import { BlogPostLink } from 'types';

export default memoize(
  (blogPostLink: unknown): BlogPostLink => ({
    type: get(blogPostLink, '_type', 'blogPost'),
    id: get(blogPostLink, '_id'),
    title: get(blogPostLink, 'title', ''),
    slug: get(blogPostLink, 'slug', ''),
  }),
);
