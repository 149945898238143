import React, { FC } from 'react';
import theme from '@buoyhealth/common.buoy-theme';
import styled from 'styled-components';
import { Svg } from 'styled/components/base';
import { Color } from 'styled/theme/colors';

type Props = {
  className?: string;
  color?: Color;
  isReverse?: boolean;
};

const NavChevron: FC<Props> = ({
  className = '',
  color = theme.palette.primary.main,
}) => (
  <Svg
    width="18px"
    height="10px"
    viewBox="0 0 18 10"
    fill="transparent"
    className={className}
  >
    <desc>Chevron Icon.</desc>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.353553 1.76777C0.158291 1.5725 0.158291 1.25592 0.353553 1.06066L1.06066 0.353553C1.25592 0.158291 1.5725 0.158291 1.76777 0.353553L8.70711 7.29289L15.6464 0.353553C15.8417 0.158291 16.1583 0.158291 16.3536 0.353553L17.0607 1.06066C17.2559 1.25592 17.2559 1.5725 17.0607 1.76777L9.06066 9.76777C8.8654 9.96303 8.54882 9.96303 8.35355 9.76777L0.353553 1.76777Z"
      fill={color}
    />
  </Svg>
);

export default styled(NavChevron)`
  transform: ${({ isReverse }) => (isReverse ? 'rotate(180deg)' : 'initial')};
  transition: transform 150ms;
`;
