import { Action, ArticleGenericPage, GenericPage } from 'types';

export interface GenericPageReducer {
  [slug: string]: GenericPage | ArticleGenericPage;
}

const initialState: GenericPageReducer = {};

function genericPage(
  state: GenericPageReducer = initialState,
  action: Action
): GenericPageReducer {
  switch (action.type) {
    case 'FETCH_GENERIC_PAGE_FULFILLED':
      if (!action.payload) {
        return { ...state };
      }

      return { ...state, [action.payload.slug]: action.payload };
    default:
      return state;
  }
}

export default genericPage;
