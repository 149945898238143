import memoize from 'lodash/memoize';
import get from 'utils/get';

import sanitizeAccordionListModule from 'state/sanitizers/modules/sanitizeAccordionListModule';
import sanitizeBannerModule from 'state/sanitizers/modules/sanitizeBannerModule';
import sanitizeCarouselModule from 'state/sanitizers/modules/sanitizeCarouselModule';
import sanitizeEmphasisLinkCardModule from 'state/sanitizers/modules/sanitizeEmphasisLinkCardModule';
import sanitizeFourColumnVideoModule from 'state/sanitizers/modules/sanitizeFourColumnVideoModule';
import sanitizeImageCardsModule from 'state/sanitizers/modules/sanitizeImageCardsModule';
import sanitizeInstagramFeedModule from 'state/sanitizers/modules/sanitizeInstagramFeedModule';
import sanitizeJobPostingsModule from 'state/sanitizers/modules/sanitizeJobPostingsModule';
import sanitizeLargeTextModule from 'state/sanitizers/modules/sanitizeLargeTextModule';
import sanitizeLinkCardsModule from 'state/sanitizers/modules/sanitizeLinkCardsModule';
import sanitizeLinkCardWithImageModule from 'state/sanitizers/modules/sanitizeLinkCardWithImageModule';
import sanitizeLinksListModule from 'state/sanitizers/modules/sanitizeLinksListModule';
import sanitizeNumberedListWithImageModule from 'state/sanitizers/modules/sanitizeNumberedListWithImageModule';
import sanitizePageHero from 'state/sanitizers/sanitizePageHero';
import sanitizeQuoteModule from 'state/sanitizers/modules/sanitizeQuoteModule';
import sanitizeSingleBlockCtaModule from 'state/sanitizers/modules/sanitizeSingleBlockCtaModule';
import sanitizeSplitCtaModule from 'state/sanitizers/modules/sanitizeSplitCtaModule';
import sanitizeTeamListModule from 'state/sanitizers/modules/sanitizeTeamListModule';
import sanitizeTextModule from 'state/sanitizers/modules/sanitizeTextModule';
import sanitizeThreeColumnInfoModule from 'state/sanitizers/modules/sanitizeThreeColumnInfoModule';
import sanitizeVideoModule from 'state/sanitizers/modules/sanitizeVideoModule';
import sanitizeTypeformModule from './modules/sanitizeTypeformModule';

import { ShowcasePage } from 'types';

export default memoize((showcasePage: unknown): ShowcasePage | null => {
  const id = get(showcasePage, '_id');

  if (!id) {
    return null;
  }

  return {
    id,
    type: get(showcasePage, '_type', 'showcasePage'),
    slug: get(showcasePage, 'slug', ''),
    title: get(showcasePage, 'title', ''),
    hero: sanitizePageHero(showcasePage),
    modules: get(showcasePage, 'moduleRepeater', []).map((module: any) => {
      const type = get(module, '_type');

      if (!type) {
        return null;
      }

      switch (type) {
        case 'accordionList':
          return sanitizeAccordionListModule(module);
        case 'banner':
          return sanitizeBannerModule(module);
        case 'carousel':
          return sanitizeCarouselModule(module);
        case 'emphasisLinkCard':
          return sanitizeEmphasisLinkCardModule(module);
        case 'fourColumnVideo':
          return sanitizeFourColumnVideoModule(module);
        case 'imageCards':
          return sanitizeImageCardsModule(module);
        case 'instagramFeed':
          return sanitizeInstagramFeedModule(module);
        case 'jobPostings':
          return sanitizeJobPostingsModule(module);
        case 'largeText':
          return sanitizeLargeTextModule(module);
        case 'linkCards':
          return sanitizeLinkCardsModule(module);
        case 'linkCardWithImage':
          return sanitizeLinkCardWithImageModule(module);
        case 'linksList':
          return sanitizeLinksListModule(module);
        case 'numberedListWithImage':
          return sanitizeNumberedListWithImageModule(module);
        case 'quote':
          return sanitizeQuoteModule(module);
        case 'singleBlockCta':
          return sanitizeSingleBlockCtaModule(module);
        case 'splitCta':
          return sanitizeSplitCtaModule(module);
        case 'teamList':
          return sanitizeTeamListModule(module);
        case 'textModule':
          return sanitizeTextModule(module);
        case 'threeColumnInfo':
          return sanitizeThreeColumnInfoModule(module);
        case 'typeformModule':
          return sanitizeTypeformModule(module);
        case 'videoModule':
          return sanitizeVideoModule(module);
        default:
          return null;
      }
    }),
  };
});
