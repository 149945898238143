import styled from 'styled-components';
import theme from 'styled/theme';

export const MenuContainer = styled.div`
  width: 100%;
`;

export const MenuCard = styled.div`
  opacity: 0;
  position: absolute;
  width: 100%;
  left: 0;
  top: 100%;

  &.open {
    animation: open 600ms cubic-bezier(0.19, 1, 0.22, 1) forwards;
  }

  &.close {
    animation: close 600ms cubic-bezier(0.19, 1, 0.22, 1) forwards;
    pointer-events: none;
  }

  > div {
    background-color: ${theme.palette.common.white};
    box-shadow: 0px 4px 2px -2px rgba(0, 0, 0, 0.1);
    border-top: 1px solid ${theme.palette.gray[10]};
    display: flex;
    justify-content: center;
    flex-direction: row;
    padding: 1rem;
    grid-column-gap: 24px;
  }

  @keyframes open {
    100% {
      opacity: 1;
    }
  }

  @keyframes close {
    100% {
      opacity: 0;
    }
  }
`;
