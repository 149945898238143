import memoize from 'lodash/memoize';
import get from 'utils/get';

import sanitizeInternalLink from 'state/sanitizers/sanitizeInternalLink';
import sanitizeModuleOptions from 'state/sanitizers/modules/sanitizeModuleOptions';

import { SingleBlockCtaModule } from 'types';

export default memoize((module: unknown): SingleBlockCtaModule => {
  return {
    type: get(module, '_type', 'singleBlockCta'),
    id: get(module, '_key', ''),
    text: get(module, 'text', ''),
    link: sanitizeInternalLink(get(module, 'link', '')),
    slug: get(module, 'slug', ''),
    variant: get(module, 'variant', 'with-arrow'),
    size: get(module, 'size', 'large'),
    moduleOptions: sanitizeModuleOptions(module),
    gtmTrackerEventName: get(module, 'gtmTrackerEventName', ''),
  };
});
