import { Action, SurveyResponse } from 'types';

export interface UserResearchReducer {
  currentResponse: SurveyResponse;
}

const initialState: UserResearchReducer = {
  currentResponse: {
    id: null,
    pages: {},
  },
};

function userResearch(
  state: UserResearchReducer = initialState,
  action: Action
): UserResearchReducer {
  switch (action.type) {
    case 'CREATE_SURVEY_RESPONSE_FULFILLED':
    case 'UPDATE_SURVEY_RESPONSE_FULFILLED':
      return { ...state, currentResponse: action.payload };
    default:
      return state;
  }
}

export default userResearch;
