import Environments from 'constants/Environments';
import { getRequestBase } from 'lib/constants';

export const BAZAAR_URL = `${getRequestBase(
  Environments.LOCAL.bazaarPort,
)}/bazaar/api`;

export const PREDICTOR_MAPPING_URL = `${BAZAAR_URL}/v2/medical-mappings/predictors/`;

export const AFFILIATE_PRODUCTS_URL = `${BAZAAR_URL}/v2/affiliates/products/`;

export const QUESTIONS_URL = `${BAZAAR_URL}/v2/questions/`;
