import React, { useCallback, useContext } from 'react';
import NavChevron from 'styled/components/svg/NavChevron';
import { MenuContext, NavigationContext } from '../contexts';
import { MenuContainer, MenuCard } from './DesktopMenu.style';
import DesktopNavLink from '../DesktopNavLink';

import type { FC, KeyboardEvent, MouseEvent, PropsWithChildren } from 'react';
import type { TrendingArticle } from '../../types';
import {
  trackUserClicksTopLevelItem,
  trackUserExpandsTopLevelSection,
} from '../../analytics';

type Props = PropsWithChildren<{
  articles: TrendingArticle[];
  icon?: FC<{
    className?: string;
    isReverse?: boolean;
  }> | null;
  id: string;
  label: string;
  slug: string;
}>;

function DesktopMenu(props: Props) {
  const { articles = [], children, icon = NavChevron, id, label, slug } = props;

  const { activeMenu, setActiveMenu } = useContext(NavigationContext);

  const open = activeMenu.includes(id);

  const clearActiveMenu = useCallback(
    (event: KeyboardEvent | MouseEvent) => {
      event.stopPropagation();

      setActiveMenu('');
    },
    [setActiveMenu],
  );

  const className = `${open ? 'open' : 'close'}`;

  return (
    <MenuContainer>
      <DesktopNavLink
        articles={articles}
        icon={icon}
        iconIsReverse={open}
        id={id}
        key={id}
        label={label}
        slug={slug}
        onClick={() => trackUserClicksTopLevelItem(label, slug)}
        onHover={() => trackUserExpandsTopLevelSection(label)}
      />
      <MenuContext.Provider value={{ open }}>
        <MenuCard className={className} onMouseLeave={clearActiveMenu}>
          {open && <div>{children}</div>}
        </MenuCard>
      </MenuContext.Provider>
    </MenuContainer>
  );
}

export default DesktopMenu;
