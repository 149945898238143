import get from 'utils/get';
import { ArticleBodyModule } from 'types';

const extractMainSymptomsFromDxArticleBody = (
  articleBodyModules: ArticleBodyModule[],
) => {
  return articleBodyModules.reduce((mainSymptoms: string[], module) => {
    if (!module) return mainSymptoms;

    /*
     * Find the article body module where symptoms are listed
     */
    if (
      module.type === 'articleText' &&
      module.sideNav.anchor.includes('symptoms')
    ) {
      /*
       * Find index of "Main symptoms" h2 or h3
       * i.e. "Main symptoms", "Main symptoms of a heart attack", etc.
       */
      const mainSymptomsIndex = module.text.findIndex(
        (portableTextBlock) =>
          ['h2', 'h3'].includes(get(portableTextBlock, 'style')) &&
          get(portableTextBlock, 'children[0].text').includes('Main symptoms'),
      );

      /*
       * If index is greater than -1, it means that a section with a "Main symptoms" heading exists
       * We loop through the section's portable text blocks.
       */
      if (mainSymptomsIndex > -1) {
        let bulletListEndIndex = module.text.length;

        module.text.forEach((portableTextBlock, index) => {
          /*
           * We ignore blocks prior to the "Main symptoms" heading
           */
          if (index < mainSymptomsIndex) return;

          /*
           * If we encounter an h3 heading, that means we've reached the end of the symptoms
           * bullet list, and we can ignore the remaining blocks.
           */
          if (
            index > mainSymptomsIndex &&
            get(portableTextBlock, 'style') === 'h3'
          ) {
            bulletListEndIndex = index - 1;
          }

          if (index > bulletListEndIndex) return;

          /*
           * Symptoms are formatted as bullet points under the heading:
           * - Level 1 bullet points are added to the symptoms array.
           * - For level 2 bullet points, we find its parent and concat them.
           */
          if (get(portableTextBlock, 'listItem') === 'bullet') {
            if (get(portableTextBlock, 'level') > 1) {
              const childOfPreviousSymptom = get(
                portableTextBlock,
                'children',
                [],
              )
                .map((child: unknown) => get(child, 'text', ''))
                .join('');

              const previousSymptom = mainSymptoms.pop();

              if (previousSymptom) {
                mainSymptoms.push(
                  previousSymptom?.concat(' ' + childOfPreviousSymptom),
                );
              }
            } else {
              const symptom = get(portableTextBlock, 'children', [])
                .map((child: unknown) => get(child, 'text', ''))
                .join('');

              mainSymptoms.push(symptom);
            }
          }
        });
      }
    }

    return mainSymptoms;
  }, []);
};

export default extractMainSymptomsFromDxArticleBody;
