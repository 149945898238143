import { Action, CampaignPage } from 'types';

export interface CampaignPageReducer {
  pages: {
    [slug: string]: CampaignPage;
  };
}

const initialState: CampaignPageReducer = {
  pages: {},
};

function campaignPage(
  state: CampaignPageReducer = initialState,
  action: Action
): CampaignPageReducer {
  switch (action.type) {
    case 'FETCH_CAMPAIGN_PAGE_FULFILLED':
      if (!action.payload) {
        return state;
      }

      return {
        ...state,
        pages: {
          ...state.pages,
          [action.payload.slug]: action.payload,
        },
      };
    default:
      return state;
  }
}

export default campaignPage;
