import memoize from 'lodash/memoize';
import get from 'utils/get';
import { ArticleType, IBrowsePageLink } from 'types';
import getBrowsePageUrl from 'utils/getBrowsePageUrl';

export default memoize(
  (
    browseLink: { _id: string; title: string; slug: string },
    articleType: ArticleType | null,
  ): IBrowsePageLink => ({
    id: get(browseLink, '_id'),
    title: get(browseLink, 'title', ''),
    slug: get(browseLink, 'slug', ''),
    url: getBrowsePageUrl(browseLink.slug, articleType),
  }),
);
