import React, { MouseEvent, FocusEvent, RefObject } from 'react';
import NextLink from 'next/link';

import linkIsExternal from 'utils/linkIsExternal';

import Button from 'styled/components/Button';
import Link from 'styled/components/Link';

import { RouteMap } from 'constants/Routes';

interface Props {
  ariaLabel: string;
  children?: React.ReactNode;
  className: string;
  containerClassName: string;
  disabled: boolean;
  elemRef?: RefObject<HTMLAnchorElement | typeof NextLink | HTMLButtonElement>;
  variant?:
    | 'primary'
    | 'primary-lg'
    | 'primary-md'
    | 'primary-md-white'
    | 'primary-lg-with-logo'
    | 'tag'
    | 'nav-menu'
    | 'symptom-checker-white'
    | 'symptom-checker-blue'
    | 'underline-blue'
    | 'underline-white'
    | 'underline-black'
    | 'hover-underline-blue'
    | 'hover-underline-white'
    | 'hover-underline-black'
    | 'hero';
  label?: string;
  onClick(e: MouseEvent<HTMLElement>): void;
  onMouseEnter(e: MouseEvent<HTMLElement>): void;
  onMouseLeave(e: MouseEvent<HTMLElement>): void;
  onFocus(e: FocusEvent<HTMLElement>): void;
  openInCurrentTab: boolean;
  style: React.CSSProperties;
  to: string;
  wrap?: boolean;
  tabIndex?: number;
  gtmTrackerEventName?: string;
}

const defaultProps = {
  className: '',
  containerClassName: '',
  disabled: false,
  onClick: () => {},
  onMouseEnter: () => {},
  onMouseLeave: () => {},
  onFocus: () => {},
  openInCurrentTab: false,
  style: {},
  to: '',
  wrap: false,
};

/*
 * This component was created to correctly render Link or Button component
 * based on the href supplied through the CMS
 */

const LinkOrButton = (props: Props) => {
  const {
    ariaLabel,
    children,
    className,
    containerClassName,
    disabled,
    elemRef,
    variant,
    label,
    onMouseLeave,
    onMouseEnter,
    openInCurrentTab,
    style,
    wrap,
    tabIndex,
    onClick,
    onFocus,
    to,
    gtmTrackerEventName,
  } = props;
  const linkIsFakeInternalLink =
    to === RouteMap.SYMPTOM_CHECKER.path ||
    to === RouteMap.SIGN_UP.path ||
    to === RouteMap.SIGN_IN.path;
  const linkIsAnchor = to.startsWith('#');

  const commonProps = {
    ariaLabel,
    className,
    containerClassName,
    disabled,
    variant,
    label,
    onClick,
    onMouseEnter,
    onMouseLeave,
    onFocus,
    style,
    to,
    wrap,
    tabIndex,
    gtmTrackerEventName,
  };

  if (linkIsExternal(to) || linkIsFakeInternalLink || linkIsAnchor) {
    return children ? (
      <Button
        {...commonProps}
        elemRef={elemRef as RefObject<HTMLAnchorElement | HTMLButtonElement>}
        openInCurrentTab={linkIsFakeInternalLink ? true : openInCurrentTab}
      >
        {children}
      </Button>
    ) : (
      <Button
        {...commonProps}
        elemRef={elemRef as RefObject<HTMLAnchorElement | HTMLButtonElement>}
        openInCurrentTab={linkIsFakeInternalLink ? true : openInCurrentTab}
      />
    );
  }

  return children ? (
    <Link {...commonProps} elemRef={elemRef}>
      {children}
    </Link>
  ) : (
    <Link {...commonProps} elemRef={elemRef} />
  );
};

LinkOrButton.defaultProps = defaultProps;

export default LinkOrButton;
