import memoize from 'lodash/memoize';

import get from 'utils/get';

import sanitizeImage from 'state/sanitizers/sanitizeImage';

import { ArticleLink } from 'types';
import sanitizeAuthor from './sanitizeAuthor';

export default memoize(
  (articleLink: any): ArticleLink => ({
    type: get(articleLink, '_type', 'article'),
    articleType: get(articleLink, 'articleType', null),
    conditionName: get(articleLink, 'conditionName', ''),
    id: get(articleLink, '_id', null),
    // Temporary using _updatedAt for articles whose modifiedDate did not get set with _updatedAt when "Publish" button is clicked
    modifiedDate:
      get(articleLink, 'modifiedDate', '') ||
      get(articleLink, '_updatedAt', ''),
    title: get(articleLink, 'title', ''),
    slug: get(articleLink, 'slug', ''),
    heroImage: sanitizeImage(get(articleLink, 'heroImage')),
    heroColor: get(articleLink, 'heroColor', '#8cb5fe'),
    subtitle: get(articleLink, 'subtitle', ''),
    moduleTitle: get(articleLink, 'moduleTitle', ''),
    moduleDescription: get(articleLink, 'moduleDescription', ''),
    moduleImage: sanitizeImage(get(articleLink, 'moduleImage')),
    seo: {
      canonicalUrl: get(articleLink, 'canonicalUrl', ''),
      description: get(articleLink, 'metaDescription', ''),
      image: sanitizeImage(get(articleLink, 'metaImage')),
      keywords: get(articleLink, 'keywords', []),
      noindex: get(articleLink, 'noindex', false),
      structuredData: get(articleLink, 'structuredData.code', null),
      title: get(articleLink, 'metaTitle', ''),
    },
    meta: {
      author: sanitizeAuthor(get(articleLink, 'author')),
      reviewer: sanitizeAuthor(get(articleLink, 'reviewer')),
    },
    symptomChecker: {
      buttonLabelMobile: get(
        articleLink,
        'symptomCheckerButtonLabelMobile',
        'Take symptom quiz',
      ),
      buttonLabelDesktop: get(
        articleLink,
        'symptomCheckerButtonLabelDesktop',
        '',
      ),
      buttonUrl: get(articleLink, 'symptomCheckerButtonUrl', ''),
      buttonGtmTrackerEventName: get(
        articleLink,
        'symptomCheckerButtonGtmTrackerEventName',
        '',
      ),
    },
    linkedArticle: get(articleLink, 'linkedArticle', null),
    customTitle: get(articleLink, 'customTitle', ''),
    taxonomy: articleLink.dxTaxonomy || articleLink.sxTaxonomy || [],
    browsePageLinks: articleLink.browsePageLinks || [],
    language: articleLink.language || null,
  }),
);
