import memoize from 'lodash/memoize';
import { ISupplierCtaModule } from 'styled/components/modules/SupplierCtaModule/types';
import sanitizeImage from '../sanitizeImage';

export default memoize((supplierCtaModule: any): ISupplierCtaModule | null => {
  const id = supplierCtaModule?._key;

  if (!id) {
    return null;
  }

  return {
    id,
    type: supplierCtaModule._type || 'supplierCtaModule',
    image: sanitizeImage(supplierCtaModule.image),
    header: supplierCtaModule.header || '',
    subheader: supplierCtaModule.subheader || '',
    bulletPoints:
      supplierCtaModule.bulletPoints?.map((bulletPoint: any) => ({
        icon: bulletPoint.icon,
        text: bulletPoint.text || '',
      })) || [],
    ctaButton: {
      label: supplierCtaModule.ctaButtonLabel,
      url: supplierCtaModule.ctaAction?.ctaButtonURL || '',
      isIFrame: supplierCtaModule.ctaAction?.isIFrame || false,
    },
    modalConfig: {
      image: sanitizeImage(supplierCtaModule.modalImage),
      headerIntro: supplierCtaModule.modalHeaderIntro || '',
      pages:
        supplierCtaModule.modalPages?.map((page: any, i: number) => {
          const isLastPage = i === supplierCtaModule.modalPages?.length - 1;
          return {
            header: page.header,
            bulletPoints:
              page.bulletPoints?.map((bulletPoint: any) => ({
                icon: bulletPoint.icon,
                text: bulletPoint.text || '',
              })) || [],
            ctaButton: {
              label: page.ctaButton,
              url: isLastPage
                ? supplierCtaModule.ctaActionModal?.ctaButtonURL
                : '',
              isIFrame: isLastPage
                ? supplierCtaModule.ctaActionModal?.isIFrame
                : false,
            },
          };
        }) || [],
    },
  };
});
