import React, { useCallback, useState } from 'react';
import { AnchorLabel } from 'styled/components/base';
import { NavLink } from '../MobileNavBar.style';
import MobileNavDrawer from '../MobileNavDrawer';
import { MenuIconBackward, MenuIconForward } from './MobileMenuSlider.style';

import type { PropsWithChildren } from 'react';

type Props = PropsWithChildren<{
  label: string;
  trackSliderOpen: () => void;
}>;

function MobileMenuSlider(props: Props) {
  const { children, label, trackSliderOpen } = props;

  const [open, setOpen] = useState(false);

  const handleClick = useCallback(() => {
    if (!open) {
      trackSliderOpen();
    }
    setOpen(!open);
  }, [open, trackSliderOpen]);

  return (
    <>
      <NavLink ariaLabel={label} onClick={handleClick}>
        <AnchorLabel>{label}</AnchorLabel>
        <MenuIconForward />
      </NavLink>
      <MobileNavDrawer open={open} anchor="right">
        <NavLink ariaLabel={label} onClick={handleClick}>
          <MenuIconBackward />
          <AnchorLabel>{label}</AnchorLabel>
        </NavLink>

        {open && children}
      </MobileNavDrawer>
    </>
  );
}

export default MobileMenuSlider;
