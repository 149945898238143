import React, { MouseEvent, FocusEvent, RefObject } from 'react';
import { useTheme } from 'styled-components';

import cx from 'classnames';
import { AnchorLabel, Base } from 'styled/components/base';
import BuoyChatIcon from 'styled/components/svg/BuoyChatIcon';
import sendEventToGoogleTagManager from 'utils/sendEventToGoogleTagManager';
import withPolarisLinkInterceptor from '../Polaris/withPolarisLinkInterceptor';

import {
  StyledButton,
  StyledBaseButton,
  StyledAnchor as Anchor,
  StyledAnchorInner as AnchorInner,
} from './style';

export interface Props {
  ariaLabel: string;
  ariaExpanded?: boolean;
  ariaControls?: string;
  children?: React.ReactNode;
  id?: string;
  className?: string;
  containerClassName?: string;
  'data-testid'?: string;
  disabled?: boolean;
  elemRef?: React.Ref<HTMLAnchorElement | HTMLButtonElement>;
  label?: React.ReactNode;
  onClick?(e: MouseEvent<HTMLElement>): void;
  onMouseEnter?(e: MouseEvent<HTMLElement>): void;
  onMouseLeave?(e: MouseEvent<HTMLElement>): void;
  onFocus?(e: FocusEvent<HTMLElement>): void;
  openInCurrentTab?: boolean;
  style?: React.CSSProperties;
  to?: string;
  type?: 'button' | 'submit' | 'reset';
  rel?: string;
  variant?:
    | 'primary'
    | 'primary-md'
    | 'primary-md-white'
    | 'primary-lg'
    | 'primary-lg-with-logo'
    | 'tag'
    | 'nav-menu'
    | 'symptom-checker-white'
    | 'symptom-checker-blue'
    | 'notification-bar-close'
    | 'underline-white'
    | 'underline-white-xs'
    | 'underline-black'
    | 'underline-blue'
    | 'underline-blue-xs'
    | 'underline-black-xs'
    | 'hover-underline-blue'
    | 'hover-underline-white'
    | 'hover-underline-black'
    | 'hero'
    | 'nav-tab'
    | 'nav-tab-with-border'
    | 'nav-tab-with-border-active'
    | 'nav-tab-sections-menu-anchor'
    | 'rectangle-blue'
    | 'rectangle-white'
    | 'skip-link';
  wrap?: boolean;
  tabIndex?: number;
  property?: string;
  typeOf?: string;
  inline?: boolean;
  useBaseStyle?: boolean;
  gtmTrackerEventName?: string;
  polarisTrackingLocation?: string;
}

const defaultProps = {
  className: '',
  containerClassName: '',
  disabled: false,
  onClick: () => {},
  onMouseEnter: () => {},
  onMouseLeave: () => {},
  onFocus: () => {},
  openInCurrentTab: false,
  style: {},
  to: '',
  wrap: false,
  inline: false,
};

const Button = (props: Props) => {
  const { palette } = useTheme();

  const {
    id,
    className,
    containerClassName,
    style,
    variant,
    type,
    label,
    ariaLabel,
    ariaExpanded,
    ariaControls,
    children,
    onClick: onClickProp,
    onMouseLeave,
    onMouseEnter,
    onFocus,
    elemRef,
    disabled,
    to,
    openInCurrentTab,
    wrap,
    tabIndex,
    rel,
    inline,
    useBaseStyle,
    gtmTrackerEventName,
  } = props;

  const variantHasChatIcon =
    variant &&
    [
      'symptom-checker-white',
      'symptom-checker-blue',
      'primary-lg-with-logo',
    ].includes(variant);

  const onClick = (event: MouseEvent<HTMLElement>) => {
    if (gtmTrackerEventName) {
      sendEventToGoogleTagManager({
        event: gtmTrackerEventName,
      });
    }
    onClickProp?.(event);
  };

  const inner = (
    <AnchorInner
      data-testid={props['data-testid'] || 'button'}
      as="span"
      className={cx('Button', className)}
      wrap={wrap}
      variant={variant}
    >
      {!!children && !label ? (
        children
      ) : (
        <>
          {variantHasChatIcon && (
            <Base
              as={BuoyChatIcon}
              primaryColor={
                variant === 'symptom-checker-white'
                  ? palette.primary.main
                  : palette.common.white
              }
              secondaryColor={
                variant === 'symptom-checker-white'
                  ? palette.common.white
                  : palette.primary.main
              }
              width={['1.25rem', '1.25rem', '1.5rem']}
              mr={variant === 'primary-lg-with-logo' ? 1.5 : [0.5, 0.5, 0.75]}
              alignSelf="center"
            />
          )}
          <div
            className={cx({
              inline: inline,
            })}
          >
            <AnchorLabel className="Button__label">{label}</AnchorLabel>
          </div>
        </>
      )}
    </AnchorInner>
  );

  if (to) {
    return (
      <Anchor
        as="a"
        onClick={onClick}
        onFocus={onFocus}
        target={openInCurrentTab ? '_self' : '_blank'}
        href={to}
        rel={rel ? rel : openInCurrentTab ? undefined : 'noreferrer noopener'}
        id={id}
        className={cx('Button__container', containerClassName)}
        variant={variant}
        style={style}
        disabled={disabled}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        aria-label={ariaLabel}
        aria-expanded={ariaExpanded}
        aria-controls={ariaControls}
        ref={elemRef as RefObject<HTMLAnchorElement>}
        tabIndex={tabIndex}
      >
        {inner}
      </Anchor>
    );
  }

  if (useBaseStyle) {
    return (
      <StyledBaseButton
        as="button"
        type={type}
        id={id}
        className={cx('Button__container', containerClassName)}
        style={style}
        onClick={onClick}
        onFocus={onFocus}
        disabled={disabled}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        aria-label={ariaLabel}
        aria-expanded={ariaExpanded}
        aria-controls={ariaControls}
        ref={elemRef as RefObject<HTMLButtonElement>}
        tabIndex={tabIndex}
      >
        {inner}
      </StyledBaseButton>
    );
  }

  return (
    <StyledButton
      as="button"
      type={type}
      id={id}
      className={cx('Button__container', containerClassName)}
      variant={variant}
      style={style}
      onClick={onClick}
      onFocus={onFocus}
      disabled={disabled}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      aria-label={ariaLabel}
      aria-expanded={ariaExpanded}
      aria-controls={ariaControls}
      ref={elemRef as RefObject<HTMLButtonElement>}
      tabIndex={tabIndex}
    >
      {inner}
    </StyledButton>
  );
};

Button.defaultProps = defaultProps;

export default withPolarisLinkInterceptor(Button);
