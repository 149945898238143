import { ContentAnchor, SeoSettings } from 'types';
import { UserStories as UserStoriesAnchor } from 'constants/Global';

export const DefaultImage = {
  id: '',
  src: '',
  alt: '',
  crop: null,
  hotspot: null,
  metadata: null,
};

export const DefaultReferencesSideNav: ContentAnchor = {
  id: 'defaultReferences',
  label: 'References',
  anchor: 'references',
};

export const UserStoriesContentAnchor: ContentAnchor = {
  id: 'userStories',
  label: 'User Stories',
  anchor: UserStoriesAnchor,
};

export const DefaultSeoSettings: SeoSettings = {
  title: 'Buoy Health: Check Symptoms & Find the Right Care',
  description:
    'Check your symptoms and clarify your options for care. The Buoy A.I. health assistant guides you on your way to well, the moment you feel sick.',
  image: {
    id: 'image-e56d3d48f9be2ea73150ed52331a61bca6d6c8e3-2501x1668-png',
    src: 'https://cdn.sanity.io/images/0b678gck/buoy-public-site/e56d3d48f9be2ea73150ed52331a61bca6d6c8e3-2501x1668.png',
    alt: "Buoy Health - A person in bed checking Buoy's AI Assistant on their phone. The caption says 'Feeling off? Buoy it.'",
    crop: null,
    hotspot: null,
    metadata: {
      dimensions: {
        aspectRatio: 1.499400479616307,
        height: 1668,
        width: 2501,
      },
    },
  },
  structuredData: null,
  canonicalUrl: '',
};

export const DEFAULT_SALESFORCE_LEAD_SOURCE = 'Website';
