import theme from 'styled/theme';
import styled from 'styled-components';

export const NavBar = styled.nav`
  align-items: flex-start;
  background-color: ${theme.palette.common.white};
  display: flex;
  flex-direction: column;
  justify-content: center;
  left: 0;
  height: 3.6875rem;
  padding: 0;
  position: fixed;
  top: 0;
  width: 100%;
  ${theme.zIndex('nav')}

  ${theme.mediaQueries.sm} {
    min-height: 5.25rem;
  }
  ${theme.mediaQueries.md} {
    align-items: center;
    border-bottom: 1px solid ${theme.palette.gray[10]};
    flex-direction: row;
    justify-content: space-between;
    padding: 0 1rem;
  }
`;

export const NavBarLeft = styled.div`
  border-bottom: 1px solid ${theme.palette.gray[10]};
  height: 100%;
  padding: 1rem 1.5rem;
  width: 100%;

  svg {
    height: 100%;
  }

  ${theme.mediaQueries.md} {
    border-bottom: none;
    height: 2.25rem;
    padding: 0;
    width: auto;
  }
`;

export const NavBarRight = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-end;
  width: 100%;

  > ul {
    align-items: center;
    display: flex;
  }

  ${theme.mediaQueries.md} {
    height: 100%;
    justify-content: center;
  }
`;
