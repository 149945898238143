import { Response, Server } from 'miragejs';
import { PublicSiteRegistry } from 'dev/mock-api/types';
import { PREDICTOR_MAPPING_URL } from 'lib/BazaarClient/constants';

export const mockErrorPredictorsResponse = (
  server: Server<PublicSiteRegistry>,
) => {
  server.get(PREDICTOR_MAPPING_URL, () => {
    return new Response(500, {}, { error: 'The database is out to lunch.' });
  });
};

export const mockPredictorsRoutes = {
  [PREDICTOR_MAPPING_URL]: (server: Server<PublicSiteRegistry>) => {
    server.get(PREDICTOR_MAPPING_URL, (schema, request) => {
      const { slug } = request.queryParams || {};
      const predictors = schema.where('predictor', { slug: `/${slug}` }).models;
      if (!predictors) {
        return new Response(404, {}, { error: 'Not Found' });
      }
      return new Response(200, {}, predictors);
    });
  },
};
