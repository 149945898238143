import get from 'lodash/get';

/** This is a wrapper around lodash/get which will return `defaultValue`
 *  if the target value is `null` _or_ `undefined`.
 *  `lodash/get` will only return `defaultValue` if the target value is `undefined`.
 * */
export default function (
  object: any | unknown,
  path: string,
  defaultValue?: any,
) {
  return get(object, path, defaultValue) || defaultValue;
}
