export enum TIMING_EVENTS {
  NEXTJS_HYDRATION = 'nextjs_hydration',
  NEXTJS_ROUTE_CHANGE_TO_RENDER = 'nextjs_route_change_to_render',
  NEXTJS_RENDER = 'nextjs_render',

  FTV = 'first_treatment_viewable',
}

export function addTiming(key: TIMING_EVENTS) {
  if (typeof window !== 'undefined') {
    window.TIMING_EVENTS = window.TIMING_EVENTS || {};

    // Ensure timing events are fired only once.
    if (!window.TIMING_EVENTS[key]) {
      window.TIMING_EVENTS[key] = performance.now();
    }
  }
}

export const allowedTracingOrigins = [
  /https:\/\/((?!snowplow).*)(\.*)buoyhealth\.com/,
];
