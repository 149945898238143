import memoize from 'lodash/memoize';
import get from 'utils/get';

import sanitizeInternalLink from 'state/sanitizers/sanitizeInternalLink';

import { CarouselItemOption } from 'types';

export default memoize((item: unknown): CarouselItemOption => {
  return {
    id: get(item, '_key', ''),
    subtitle: get(item, 'subtitle', ''),
    title: get(item, 'title', ''),
    description: get(item, 'description', ''),
    button: {
      label: get(item, 'buttonLabel', ''),
      link: sanitizeInternalLink(get(item, 'buttonLink', '')),
    },
  };
});
