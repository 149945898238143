import styled from 'styled-components';
import Button from 'styled/components/Button';
import Link from 'styled/components/Link';
import theme from 'styled/theme';

export const NavButton = styled(Button)`
  align-items: center;
  border-radius: 0.25rem;
  display: flex;
  height: 2rem;
  justify-content: center;
  width: 2rem;
  position: fixed;
  right: 1rem;
  top: 1rem;

  :active {
    ${({ theme }) => theme.hoverStyles.boxShadowBlue};
    border: ${({ theme }) => theme.borders['blue-darker']};
  }

  :hover {
    background-color: ${({ theme }) => theme.palette.gray[10]};
  }

  ${theme.mediaQueries.sm} {
    right: 1.5rem;
    top: 1.5rem;
  }

  svg {
    height: auto;
    width: 1.5rem;
  }

  ${theme.mediaQueries.sm} {
    height: 2.5rem;
    width: 2.5rem;

    svg {
      width: 1.875rem;
    }
  }
`;

export const NavLink = styled(Link).attrs({
  variant: 'nav-menu',
})`
  align-items: center;
  cursor: pointer !important;
  display: flex;
  font-size: 1.5rem;
  font-weight: ${theme.typography.weight.regular};
  justify-content: flex-start;
  line-height: 115%;
  padding: 0.75rem 1.5rem;
  width: 100%;

  > span {
    max-width: 90%;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  ${theme.mediaQueries.md} {
    ${theme.typography.variants.primary.sm}
    padding: 1.25rem 1rem;
  }
`;
