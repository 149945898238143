import memoize from 'lodash/memoize';
import get from 'utils/get';

import sanitizeModuleOptions from 'state/sanitizers/modules/sanitizeModuleOptions';
import sanitizeImage from 'state/sanitizers/sanitizeImage';

import { ImageWithCaptionModule } from 'types';

export default memoize((module: unknown): ImageWithCaptionModule => {
  return {
    type: get(module, '_type', 'imageWithCaption'),
    id: get(module, '_key', ''),
    image: sanitizeImage(get(module, 'image')),
    caption: get(module, 'caption', ''),
    moduleOptions: sanitizeModuleOptions(module),
  };
});
