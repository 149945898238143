import { useState, useEffect, useMemo } from 'react';
import useBreakpoint from 'hooks/useBreakpoint';

const useBreakpointIsMobile = (): boolean => {
  const currentBreakpoint = useBreakpoint();

  const [breakPointIsMobile, setbreakPointIsMobile] = useState(false);

  const isMobile = useMemo(() => {
    return (
      !!currentBreakpoint &&
      ['EXTRA_SMALL', 'SMALL'].includes(currentBreakpoint)
    );
  }, [currentBreakpoint]);

  useEffect(() => {
    setbreakPointIsMobile(isMobile);
  }, [isMobile]);

  return breakPointIsMobile;
};

export default useBreakpointIsMobile;
