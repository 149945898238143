import memoize from 'lodash/memoize';

import { GlobalSettingsReducer } from 'state/reducers/globalSettings';

import sanitizeInternalLink from 'state/sanitizers/sanitizeInternalLink';
import sanitizeImage from 'state/sanitizers/sanitizeImage';

import { DefaultSeoSettings } from 'constants/Default';

import {
  ExternalLink,
  FooterMenu,
  FooterImage,
  ErrorPage,
  NavList,
  NavListItemChild,
} from 'types';
import toCamelCase from 'utils/toCamelCase';
import { TrendingArticle } from 'styled/components/Navigation/types';

export default memoize(
  (
    globalSettings: any,
  ): Omit<GlobalSettingsReducer, 'trendingArticleImages'> => {
    const navigation: NavList = (globalSettings.sideNavigation || []).reduce(
      (nav: NavList, listItem: any, i: number): NavList => {
        const _id: string = listItem._key || '';
        const label: string = listItem.label || '';
        const labelFormat: string = listItem.labelFormat || '';
        const parent: string = listItem.parent || '';
        const slug: string = listItem.slug || '';
        const trendingArticles: TrendingArticle[] =
          listItem.trendingArticles || [];

        const id: string = toCamelCase(label);
        const parentId: string = toCamelCase(parent);

        const sanitizedListItem: NavListItemChild = {
          _id,
          label,
          labelFormat,
          parent,
          slug,
          trendingArticles,
        };

        if (parent) {
          if (!nav[parentId]?.children) {
            nav[parentId] = {
              ...nav[parentId],
              _id,
              children: [sanitizedListItem],
              label: parent,
              labelFormat,
            };
          } else {
            nav[parentId].children = nav[parentId].children.concat([
              sanitizedListItem,
            ]);
          }
        }

        nav[id] = nav[id]
          ? {
              ...sanitizedListItem,
              children: nav[id].children,
              order: i,
            }
          : {
              ...sanitizedListItem,
              children: [],
              order: i,
            };

        return nav;
      },
      {},
    );

    const footerMenu: FooterMenu[] = (globalSettings.footerMenu || []).map(
      (menu: any) => {
        const menuList = (menu.menuList || []).map((list: any) => {
          return {
            id: list._key,
            label: list.label,
            slug: list.slug,
          };
        });

        return {
          title: menu.menuTitle || '',
          list: menuList,
        };
      },
    );
    const footerSocial: ExternalLink[] = (
      globalSettings.footerSocial || []
    ).map((social: any) => {
      return {
        id: social._key,
        label: social.label,
        url: social.link,
      };
    });

    const errorPage: ErrorPage = {
      title: globalSettings.errorPageTitle || 'Page not found',
      description: globalSettings.errorPageDescription || '',
      link: {
        label: globalSettings.errorPageLinkLabel || '',
        url: globalSettings.errorPageLinkUrl || '',
      },
      image: sanitizeImage(globalSettings.errorPageImage),
    };

    const footerImage: FooterImage = {
      image: sanitizeImage(globalSettings.footer.footerImage.image),
      alternateImage: sanitizeImage(
        globalSettings.footer.footerImage.alternateImage,
      ),
      text: globalSettings.footerImageText || '',
    };

    return {
      navigation,
      notificationBar: {
        isActive: globalSettings.displayNotificationBar || false,
        text: globalSettings.notificationBarText || [],
      },
      footer: {
        menu: footerMenu,
        social: footerSocial,
        termsSlug: globalSettings.termsSlug || '',
        privacySlug: globalSettings.privacySlug || '',
        cookiesSlug: globalSettings.cookiesSlug || '',
        footerImage,
      },
      popUp: {
        isActive: globalSettings.displayPopUp || false,
        text: globalSettings.popUpText || [],
        continueButtonLabel: globalSettings.popUpContinueButtonLabel || '',
        secondaryButtonLabel: globalSettings.popUpSecondaryButtonLabel || '',
        secondaryButtonLink: sanitizeInternalLink(
          globalSettings.popUpSecondaryButtonLink || '',
        ),
      },
      errorPage,
      seo: {
        title: globalSettings.metaTitle || DefaultSeoSettings.title,
        description:
          globalSettings.metaDescription || DefaultSeoSettings.description,
        image: sanitizeImage(globalSettings.metaImage),
        structuredData: null,
        canonicalUrl: globalSettings.canonicalUrl || '',
      },
      instagramToken: globalSettings.instagramToken || '',
    };
  },
);
