import { createClient, type ClientConfig } from '@sanity/client';
import Environments from 'constants/Environments';
import type { PreviewData } from 'types';

const dataset = process.env.SANITY_DATASET || process.env.sanityDataset;
const PROJECT_ID = process.env.sanityProjectId
  ? process.env.sanityProjectId
  : '0';

/*
 * Tokenized Frontend Sanity Client
 * With the token, this client has read permissions for
 * draft/unpublished content, in addition to published content
 */
const sanityPreviewClientConfig: ClientConfig = {
  projectId: PROJECT_ID,
  dataset,
  token: process.env.sanityPreviewToken as string,
  useCdn: false,
  apiVersion: 'v2024-09-24',
  perspective: 'previewDrafts',
};

/*
 * An extension of `sanityPreviewClientConfig`.
 * This config includes Content Source Maps, which are encoded into
 * query results using steganography. Note that this encoding
 * can cause some unexpected style changes, therefore this client is
 * best used only when previewing/editing text content.
 */
const sanityPresentationClientConfig: ClientConfig = {
  ...sanityPreviewClientConfig,
  stega: {
    enabled: true,
    studioUrl: Environments.PRODUCTION.sanity,
  },
};

/*
 * Untokenized Frontend Sanity Client
 * This client can only read published content
 */
const sanityClientConfig: ClientConfig = {
  projectId: PROJECT_ID,
  dataset,
  useCdn: true,
  apiVersion: 'v2024-09-24',
};

export function getSanityClient(previewData?: PreviewData) {
  if (previewData?.id) {
    return createClient(sanityPreviewClientConfig);
  } else if (previewData?.enabled) {
    return createClient(sanityPresentationClientConfig);
  }
  return createClient(sanityClientConfig);
}

export const PreviewClient = createClient(sanityPreviewClientConfig);
export const PresentationClient = createClient(sanityPresentationClientConfig);
export default createClient(sanityClientConfig);
