import * as Sentry from '@sentry/node';
import { ENVIRONMENT, SENTRY_DSN } from 'constants/EnvVars';
import { Result, err } from 'utils/results';

/**
 * Initializes sentry client
 */
export const initialize = () => {
  Sentry.init({
    dsn: SENTRY_DSN as string,
    environment: ENVIRONMENT as string,
    sampleRate: 0.1,
  });
};

export interface Tag {
  tag: string;
  value: string;
}

const withTag =
  <T>(tag?: Tag) =>
  (result: Result<T>) => {
    if (result.isErr()) {
      Sentry.withScope(function (s) {
        if (tag) {
          s.setTag(tag.tag, tag.value);
        }
        Sentry.captureException(result.error);
      });
    }
  };

/**
 * Basic Sentry captureException functionality for raw errors
 */
export const captureException = (error: Error, tag?: Tag) =>
  withTag(tag)(err(error));

/**
 * Basic Sentry captureException functionality for Result types
 */
export const captureResult = <T>(result: Result<T>, tag?: Tag) =>
  withTag(tag)(result);
