import { Action, AuthorPage } from 'types';

export interface AuthorPageReducer {
  [slug: string]: AuthorPage;
}

const initialState: AuthorPageReducer = {};

function authorPage(
  state: AuthorPageReducer = initialState,
  action: Action
): AuthorPageReducer {
  switch (action.type) {
    case 'FETCH_AUTHOR_PAGE_FULFILLED':
      if (!action.payload) {
        return { ...state };
      }

      return { ...state, [action.payload.slug]: action.payload };
    default:
      return state;
  }
}

export default authorPage;
