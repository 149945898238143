import React, { FC } from 'react';
import { Svg } from '../base';

type Props = {
  className?: string;
};

const HealthCareOptions: FC<Props> = ({ className = '' }) => (
  <Svg
    width="100%"
    height="100%"
    viewBox="0 0 2006 1401"
    fill="transparent"
    className={className}
  >
    <desc>Illustration of various health care options.</desc>
    <path
      d="M1608.43 360.528L1434.67 206.207C1426.63 199.063 1414.32 199.792 1407.17 207.836L1104.88 548.196C1097.74 556.24 1098.47 568.553 1106.51 575.697L1280.26 730.017C1288.31 737.161 1300.62 736.432 1307.77 728.388L1610.06 388.028C1617.2 379.984 1616.47 367.672 1608.43 360.528Z"
      fill="#FFC2B4"
    />
    <path
      d="M1449.9 886.804C1473.45 885.5 1491.49 865.345 1490.19 841.787C1488.88 818.23 1468.73 800.19 1445.17 801.495C1421.61 802.8 1403.57 822.955 1404.88 846.512C1406.18 870.07 1426.34 888.109 1449.9 886.804Z"
      fill="white"
    />
    <path
      d="M1435.11 803.26C1443.97 830.32 1452.83 857.383 1461.68 884.45"
      stroke="#F7ECEA"
      strokeWidth="5.45"
      strokeMiterlimit="10"
    />
    <path
      d="M1532.08 719.652C1555.41 716.079 1571.42 694.277 1567.84 670.956C1564.27 647.634 1542.47 631.624 1519.15 635.197C1495.82 638.77 1479.81 660.573 1483.39 683.894C1486.96 707.216 1508.76 723.225 1532.08 719.652Z"
      fill="white"
    />
    <path
      d="M1565.05 661.3L1486.81 695.58"
      stroke="#F7ECEA"
      strokeWidth="5.45"
      strokeMiterlimit="10"
    />
    <path
      d="M698.673 1234.1C721.995 1230.53 738.005 1208.72 734.432 1185.4C730.859 1162.08 709.056 1146.07 685.735 1149.64C662.413 1153.22 646.404 1175.02 649.977 1198.34C653.55 1221.66 675.352 1237.67 698.673 1234.1Z"
      fill="white"
    />
    <path
      d="M731.631 1175.72L653.391 1210"
      stroke="#F7ECEA"
      strokeWidth="5.45"
      strokeMiterlimit="10"
    />
    <path
      d="M1431.52 329.834C1454.84 326.261 1470.85 304.459 1467.28 281.138C1463.71 257.816 1441.9 241.807 1418.58 245.379C1395.26 248.952 1379.25 270.755 1382.82 294.076C1386.4 317.398 1408.2 333.407 1431.52 329.834Z"
      fill="white"
    />
    <path
      opacity="0.41"
      d="M1456.16 318.25L1394.61 259"
      stroke="#FFC2B4"
      strokeWidth="5.45"
      strokeMiterlimit="10"
    />
    <path
      d="M1299.99 677.763C1323.31 674.19 1339.32 652.388 1335.74 629.066C1332.17 605.745 1310.37 589.735 1287.05 593.308C1263.73 596.881 1247.72 618.683 1251.29 642.005C1254.86 665.326 1276.66 681.336 1299.99 677.763Z"
      fill="white"
    />
    <path
      opacity="0.41"
      d="M1324.61 666.17L1263.05 606.93"
      stroke="#FFC2B4"
      strokeWidth="5.45"
      strokeMiterlimit="10"
    />
    <path
      d="M1531.46 423.776C1554.78 420.203 1570.79 398.401 1567.22 375.08C1563.65 351.758 1541.85 335.749 1518.52 339.322C1495.2 342.895 1479.19 364.697 1482.77 388.018C1486.34 411.34 1508.14 427.349 1531.46 423.776Z"
      fill="white"
    />
    <path
      opacity="0.41"
      d="M1556.1 412.19L1494.55 352.94"
      stroke="#FFC2B4"
      strokeWidth="5.45"
      strokeMiterlimit="10"
    />
    <path
      d="M1379.7 597.466C1403.02 593.893 1419.03 572.091 1415.46 548.77C1411.88 525.448 1390.08 509.439 1366.76 513.012C1343.44 516.585 1327.43 538.387 1331 561.708C1334.57 585.03 1356.38 601.039 1379.7 597.466Z"
      fill="white"
    />
    <path
      opacity="0.41"
      d="M1404.33 585.87L1342.78 526.63"
      stroke="#FFC2B4"
      strokeWidth="5.45"
      strokeMiterlimit="10"
    />
    <path
      d="M1454.9 510.599C1478.23 507.026 1494.23 485.224 1490.66 461.903C1487.09 438.581 1465.29 422.572 1441.97 426.145C1418.64 429.717 1402.63 451.52 1406.21 474.841C1409.78 498.163 1431.58 514.172 1454.9 510.599Z"
      fill="white"
    />
    <path
      opacity="0.41"
      d="M1479.54 499.01L1417.99 439.76"
      stroke="#FFC2B4"
      strokeWidth="5.45"
      strokeMiterlimit="10"
    />
    <path
      d="M1199.43 596.497C1222.75 592.924 1238.76 571.122 1235.19 547.8C1231.61 524.479 1209.81 508.469 1186.49 512.042C1163.17 515.615 1147.16 537.417 1150.73 560.739C1154.3 584.06 1176.11 600.069 1199.43 596.497Z"
      fill="white"
    />
    <path
      opacity="0.41"
      d="M1232.41 538.13L1154.16 572.42"
      stroke="#FFC2B4"
      strokeWidth="5.45"
      strokeMiterlimit="10"
    />
    <path
      d="M1281.65 506.731C1304.97 503.158 1320.98 481.356 1317.4 458.035C1313.83 434.713 1292.03 418.704 1268.71 422.276C1245.39 425.849 1229.38 447.652 1232.95 470.973C1236.52 494.295 1258.32 510.304 1281.65 506.731Z"
      fill="white"
    />
    <path
      opacity="0.41"
      d="M1270.86 423.05C1273.96 451.356 1277.06 479.666 1280.15 507.98"
      stroke="#FFC2B4"
      strokeWidth="5.45"
      strokeMiterlimit="10"
    />
    <path
      d="M1365.33 420.097C1388.66 416.524 1404.66 394.722 1401.09 371.4C1397.52 348.079 1375.72 332.069 1352.4 335.642C1329.07 339.215 1313.06 361.017 1316.64 384.339C1320.21 407.66 1342.01 423.67 1365.33 420.097Z"
      fill="white"
    />
    <path
      opacity="0.41"
      d="M1385.14 412.82L1333.25 344.95"
      stroke="#FFC2B4"
      strokeWidth="5.45"
      strokeMiterlimit="10"
    />
    <path
      d="M1332.02 940.764L1379.99 1010.93L1112.01 1194.14C1102.71 1200.5 1091.26 1202.91 1080.19 1200.83C1069.11 1198.75 1059.32 1192.35 1052.96 1183.05V1183.05C1046.6 1173.75 1044.19 1162.3 1046.27 1151.22C1048.35 1140.15 1054.75 1130.35 1064.05 1123.99L1332.03 940.781L1332.02 940.764Z"
      fill="#FFC2B4"
    />
    <path
      d="M1395.72 1012.87L1335.74 925.131L1309.08 943.355L1369.07 1031.09L1395.72 1012.87Z"
      fill="#F8AEA4"
    />
    <path
      d="M1410.83 989.814L1362.87 919.662L1341.83 934.048L1389.79 1004.2L1410.83 989.814Z"
      fill="#D07C67"
    />
    <path
      d="M1228.21 1011.75L1276.18 1081.91L1112.03 1194.14C1102.73 1200.5 1091.28 1202.91 1080.2 1200.83C1069.13 1198.75 1059.33 1192.35 1052.97 1183.05V1183.05C1046.61 1173.75 1044.21 1162.3 1046.29 1151.22C1048.37 1140.15 1054.76 1130.35 1064.07 1123.99L1228.22 1011.76L1228.21 1011.75Z"
      fill="#58A0FF"
    />
    <path
      d="M372.371 929.89C375.823 929.89 378.621 927.091 378.621 923.64C378.621 920.188 375.823 917.39 372.371 917.39C368.919 917.39 366.121 920.188 366.121 923.64C366.121 927.091 368.919 929.89 372.371 929.89Z"
      fill="#F5C3B6"
    />
    <path
      d="M529.133 858.73C537.191 858.73 543.723 852.198 543.723 844.14C543.723 836.082 537.191 829.55 529.133 829.55C521.075 829.55 514.543 836.082 514.543 844.14C514.543 852.198 521.075 858.73 529.133 858.73Z"
      fill="#F5C3B6"
    />
    <path
      d="M923.269 507.98C929.709 507.98 934.929 502.759 934.929 496.32C934.929 489.88 929.709 484.66 923.269 484.66C916.83 484.66 911.609 489.88 911.609 496.32C911.609 502.759 916.83 507.98 923.269 507.98Z"
      fill="#F5C3B6"
    />
    <path
      d="M1240.17 817.84C1240.17 820.726 1239.32 823.548 1237.72 825.948C1236.12 828.349 1233.84 830.22 1231.17 831.326C1228.51 832.432 1225.57 832.722 1222.74 832.161C1219.91 831.599 1217.31 830.21 1215.27 828.17C1213.23 826.13 1211.84 823.53 1211.27 820.7C1210.71 817.869 1211 814.935 1212.1 812.269C1213.21 809.602 1215.08 807.323 1217.48 805.719C1219.87 804.116 1222.7 803.26 1225.58 803.26C1229.45 803.26 1233.16 804.796 1235.9 807.53C1238.63 810.264 1240.17 813.972 1240.17 817.84V817.84Z"
      fill="#F5C3B6"
    />
    <path
      d="M601.86 346.38C597.92 346.89 568.18 351.15 553.69 375.57C552.42 377.71 539.26 400.46 549.1 413.43C549.87 414.43 553.77 419.36 560.21 420.43C576.58 423.03 582.49 396.28 604.67 389.86C628.42 382.99 646.75 406.41 658.85 396.86C665.94 391.24 665.85 378.31 662.75 370.07C654.99 349.34 622.92 343.69 601.86 346.38Z"
      fill="#F5C3B6"
    />
    <path
      d="M574.63 1005.44C546.7 1002.6 515.06 1030.61 509.79 1062.18C507.49 1075.97 508.45 1101.01 524.68 1110.47C530.24 1113.71 540.57 1116.91 548.24 1112.28C562.07 1103.94 550.52 1082.18 564.99 1066.44C577.18 1053.19 593.39 1059.89 601.76 1045.26C603.655 1041.92 604.799 1038.21 605.111 1034.39C605.424 1030.56 604.898 1026.71 603.57 1023.11C597.61 1008.02 576.39 1005.62 574.63 1005.44Z"
      fill="#F5C3B6"
    />
    <path
      d="M422.212 824.95C418.072 826.15 416.082 837.8 419.782 846.79C423.702 856.3 434.631 864.67 445.891 862.5C456.061 860.5 464.152 850.24 462.292 846.2C460.792 842.95 454.062 846.35 444.532 842.9C430.272 837.75 427.052 823.56 422.212 824.95Z"
      fill="#F5C3B6"
    />
    <path
      d="M493.791 937.17C485.581 935.55 472.321 950.37 473.611 965.02C474.521 975.35 482.731 986.29 490.131 985.77C498.701 985.17 504.881 969.27 503.881 956.77C503.351 950.12 500.331 938.5 493.791 937.17Z"
      fill="#F5C3B6"
    />
    <path
      d="M551.571 550.43C555.891 544.09 546.111 536.71 548.571 524.99C550.981 513.61 562.361 510.36 560.571 504.61C559.171 500.03 550.67 497.99 544.41 498.77C533.33 500.14 525.021 510.87 522.751 521.03C519.751 534.48 526.361 551.27 537.401 554.15C542.051 555.35 548.781 554.5 551.571 550.43Z"
      fill="#F5C3B6"
    />
    <path
      d="M439.497 640.54L468.25 680.217L422.192 713.593L393.439 673.916C389.013 667.809 387.195 660.193 388.384 652.745C389.573 645.296 393.672 638.625 399.78 634.199V634.199C402.804 632.008 406.23 630.433 409.863 629.566C413.496 628.698 417.264 628.555 420.952 629.144C424.64 629.733 428.176 631.042 431.358 632.997C434.54 634.953 437.305 637.515 439.497 640.54Z"
      fill="#B7D3FF"
    />
    <path
      d="M460.375 670.789L489.128 710.466C493.554 716.574 495.373 724.19 494.184 731.638C492.995 739.086 488.895 745.757 482.788 750.183V750.183C476.68 754.609 469.065 756.428 461.616 755.239C454.168 754.05 447.496 749.951 443.07 743.843L414.317 704.166L460.375 670.789Z"
      fill="#D07C67"
    />
    <path
      d="M834.529 311.671L878.374 333.55L852.976 384.445L809.132 362.566C802.383 359.198 797.248 353.287 794.857 346.133C792.466 338.98 793.015 331.169 796.383 324.42V324.42C799.751 317.671 805.662 312.536 812.816 310.145C819.97 307.754 827.78 308.303 834.529 311.671V311.671Z"
      fill="#D07C67"
    />
    <path
      d="M872.148 330.479L910.704 349.719C917.173 352.947 922.095 358.613 924.386 365.47C926.678 372.327 926.152 379.813 922.924 386.282L921.683 388.77C918.454 395.239 912.788 400.161 905.931 402.452C899.074 404.744 891.588 404.218 885.119 400.989L846.563 381.749L872.152 330.47L872.148 330.479Z"
      fill="#B7D3FF"
    />
    <path
      d="M1176.06 391.099L1139.64 423.88L1101.58 381.604L1138 348.823C1143.61 343.777 1150.99 341.164 1158.52 341.56C1166.06 341.956 1173.12 345.328 1178.17 350.934V350.934C1183.21 356.541 1185.83 363.922 1185.43 371.454C1185.04 378.987 1181.66 386.053 1176.06 391.099V391.099Z"
      fill="#D07C67"
    />
    <path
      d="M1147.75 416.284L1111.33 449.065C1105.72 454.111 1098.34 456.723 1090.81 456.327C1083.27 455.931 1076.21 452.559 1071.16 446.953V446.953C1066.12 441.347 1063.5 433.965 1063.9 426.433C1064.3 418.901 1067.67 411.834 1073.27 406.788L1109.69 374.007L1147.75 416.284Z"
      fill="#B7D3FF"
    />
    <path
      d="M1056.92 281.758L1021.57 287.206L1015.24 246.17L1050.6 240.723C1056.04 239.883 1061.6 241.242 1066.05 244.5C1070.49 247.758 1073.46 252.649 1074.3 258.096V258.096C1075.13 263.537 1073.76 269.085 1070.5 273.521C1067.25 277.958 1062.36 280.92 1056.92 281.758Z"
      fill="#D07C67"
    />
    <path
      d="M1029.54 285.815L994.182 291.263C988.735 292.102 983.178 290.744 978.733 287.486C974.288 284.228 971.32 279.337 970.48 273.89V273.89C969.641 268.443 970.999 262.886 974.257 258.441C977.515 253.996 982.406 251.027 987.853 250.188L1023.21 244.74L1029.54 285.815Z"
      fill="#B7D3FF"
    />
    <path
      d="M604.05 678.03C594.863 703.43 585.67 728.83 576.47 754.23C571.911 766.824 570.336 780.304 571.87 793.61C573.404 806.915 578.005 819.684 585.309 830.91L753.59 1089.65C758.21 1096.75 764.428 1102.68 771.747 1106.95C779.066 1111.22 787.282 1113.72 795.74 1114.25C841.97 1117.16 928.23 1113.41 1012.46 1057.64C1083.36 1010.69 1119.98 948.64 1137.62 909.64C1141.25 901.61 1142.88 892.817 1142.36 884.02C1141.83 875.222 1139.18 866.683 1134.62 859.14L979.449 602.7C972.249 590.793 962.284 580.798 950.4 573.56C938.516 566.322 925.063 562.055 911.179 561.12L820 554.98L604.05 678.03Z"
      fill="#B7D3FF"
    />
    <path
      d="M560.82 613.89L594.88 670.66C598.906 677.386 604.842 682.761 611.934 686.101C619.026 689.44 626.951 690.592 634.7 689.41C708.128 678.235 775.498 642.206 825.56 587.34C831.335 580.982 834.879 572.914 835.653 564.359C836.428 555.805 834.39 547.231 829.85 539.94L797.74 488.23L560.82 613.89Z"
      fill="#58A0FF"
    />
    <path
      d="M693.57 578.202C758.888 543.222 804.871 501.853 796.275 485.801C787.678 469.749 727.759 485.093 662.441 520.072C597.123 555.051 551.14 596.42 559.737 612.472C568.333 628.524 628.252 613.181 693.57 578.202Z"
      fill="#7DBAFF"
    />
    <path
      d="M605.109 861.42C683.813 856.842 760.382 834.066 828.792 794.884C897.203 755.701 955.59 701.18 999.359 635.61L1100.05 802.12C1083.58 836.04 1042.37 909.12 957.399 964.7C854.019 1032.35 750.349 1031.97 713.929 1030.15L605.109 861.42Z"
      fill="#58A0FF"
    />
    <path
      d="M875.958 809.718L824.871 840.84L897.484 960.034L948.571 928.912L875.958 809.718Z"
      fill="#E1F0F2"
    />
    <path
      d="M933.235 819.715L808.141 895.923L840.855 949.623L965.949 873.415L933.235 819.715Z"
      fill="#E1F0F2"
    />
    <path
      opacity="0.09"
      d="M698.002 577.844C699.444 576.965 701.177 576.695 702.819 577.094C704.461 577.493 705.877 578.527 706.756 579.97L749.022 649.349L738.151 655.972L695.884 586.593C695.006 585.151 694.736 583.421 695.133 581.78C695.53 580.14 696.562 578.724 698.002 577.844Z"
      fill="black"
    />
    <path
      opacity="0.09"
      d="M792.762 618.58L782.912 626.87L741.262 558.5C740.384 557.058 740.114 555.327 740.511 553.686C740.909 552.045 741.941 550.629 743.382 549.75V549.75C744.824 548.871 746.555 548.601 748.195 548.999C749.836 549.396 751.252 550.429 752.132 551.87L792.762 618.58Z"
      fill="black"
    />
    <path
      opacity="0.09"
      d="M829.762 581.82L821.122 592.1L780.622 525.65C779.743 524.207 779.473 522.474 779.871 520.832C780.27 519.19 781.304 517.774 782.747 516.895C784.189 516.016 785.923 515.746 787.565 516.144C789.206 516.543 790.623 517.577 791.502 519.02L829.762 581.82Z"
      fill="black"
    />
    <path
      opacity="0.09"
      d="M699.051 673.29L686.461 677.09L646.531 611.56C645.721 610.122 645.5 608.424 645.917 606.826C646.333 605.228 647.354 603.855 648.764 602.995C650.173 602.135 651.862 601.856 653.474 602.217C655.085 602.578 656.493 603.551 657.401 604.93L699.051 673.29Z"
      fill="black"
    />
    <path
      opacity="0.09"
      d="M646.32 686.95C643.832 687.953 641.253 688.713 638.62 689.22C636.83 689.564 635.019 689.791 633.2 689.9C620.073 668.34 606.937 646.78 593.79 625.22C592.911 623.777 592.641 622.044 593.039 620.402C593.438 618.761 594.472 617.344 595.915 616.465C597.358 615.586 599.091 615.316 600.732 615.714C602.374 616.113 603.791 617.147 604.67 618.59L646.32 686.95Z"
      fill="black"
    />
  </Svg>
);

export default HealthCareOptions;
