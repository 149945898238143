import {
  ResultAsync as ResultAsync_,
  Result as Result_,
  fromPromise,
  ok,
  err,
  okAsync,
  errAsync,
} from 'neverthrow';

export { ok, err, okAsync, errAsync };
export const combineAsync = ResultAsync_.combine;
export const combineAsyncWithAllErrors = ResultAsync_.combineWithAllErrors;
export const combine = Result_.combine;
export const combineWithAllErrors = Result_.combineWithAllErrors;
export type ResultAsync<T> = ResultAsync_<T, Error>;
export type Result<T> = Result_<T, Error>;
export enum ResultPropType {
  OK = 'ok',
  ERR = 'err',
}

export type OkResult<T> = { type: ResultPropType.OK; value: T };

/**
 * A serializable Result type for use in components
 */
export type ResultProp<T> =
  | OkResult<T>
  | { type: ResultPropType.ERR; error: string };

/**
 * Converts an unserialized Result type to a ResultProp
 */
export function toResultProp<T>(result: Result<T>): ResultProp<T> {
  if (result.isOk()) {
    return { type: ResultPropType.OK, value: result.value };
  } else {
    return { type: ResultPropType.ERR, error: `${result.error}` };
  }
}

/**
 * Decorates an exception throwing fn to return a Result
 *
 * Normal Usage:
 * myFn(args): T -> could raise exception
 *
 * toResult Usage:
 * toResult(myFn)(args) -> Result<T>
 *
 */
export const toResult = <T>(fn: (...args: any[]) => T) => {
  return (...args: any[]): Result<T> => {
    try {
      return ok(fn(...args));
    } catch (error: unknown) {
      return err(new Error(`${error}`));
    }
  };
};

/**
 * Decorates a Promise returning fn to return a ResultAsync
 *
 * Normal Usage:
 * myFn(args): Promise<T>
 *
 * toResultAsync Usage:
 * toResultAsync(myFn)(args) -> ResultAsync<T>
 *
 */
export const toResultAsync = <T>(fn: (...args: any[]) => Promise<T>) => {
  return (...args: any[]): ResultAsync<T> =>
    fromPromise(fn(...args), (e) => new Error(`${e}`));
};
