import React, { RefObject, useEffect, useState } from 'react';

import BlockContent from '@sanity/block-content-to-react';

import { Button, NotificationBarCloseIcon } from 'styled';
import { Container, Svg } from './style';

import usePrevious from 'hooks/usePrevious';
import useLanguage from 'hooks/useLanguage';
import {
  ListItem,
  ExternalLink,
  InternalLink,
} from 'constants/PortableTextSerializer';

import { NotificationBar as INotificationBar } from 'types';
import useTopNavPixelHeight from 'hooks/useTopNavPixelHeight';

export interface Props {
  closeNotificationBar(): void;
  elemRef: RefObject<HTMLDivElement>;
  notificationBar: INotificationBar;
  notificationBarIsActive: boolean | null;
}

export interface State {
  removeWithAnimation: boolean;
}

function NotificationBar(props: Props) {
  const {
    closeNotificationBar,
    elemRef,
    notificationBar,
    notificationBarIsActive,
  } = props;

  const Language = useLanguage();
  const prevProps = usePrevious(props);
  const [state, setState] = useState({
    removeWithAnimation: false,
  });
  const [navHidden, setNavHidden] = useState(true);
  const [scrollPosition, setScrollPosition] = useState(0);
  const topNavHeight = useTopNavPixelHeight() || 0;

  useEffect(() => {
    if (prevProps?.notificationBarIsActive && !props.notificationBarIsActive) {
      setState((s) => ({
        ...s,
        removeWithAnimation: true,
      }));
    }
  }, [prevProps, props]);

  const handleScroll = () => {
    const position = window.scrollY;
    setNavHidden(position === 0 || position < scrollPosition);
    setScrollPosition(position);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [scrollPosition]);

  const { removeWithAnimation } = state;

  return (
    <Container
      data-testid="notification-bar"
      className={`fixed left-0 right-0 px-12 py-5 text-center`}
      aria-hidden={!notificationBarIsActive}
      ref={elemRef}
      notificationBarIsActive={notificationBarIsActive}
      removeWithAnimation={removeWithAnimation}
      style={{
        top: navHidden ? `${topNavHeight}px` : 0,
      }}
    >
      <BlockContent
        blocks={notificationBar.text}
        serializers={{
          listItem: ListItem,
          marks: { link: ExternalLink, internalLink: InternalLink },
        }}
      />
      <Button
        variant="notification-bar-close"
        ariaLabel={Language.t('NotificationBar.closeButtonAriaLabel')}
        onClick={closeNotificationBar}
      >
        <Svg as={NotificationBarCloseIcon} />
      </Button>
    </Container>
  );
}

export default NotificationBar;
