import { Action } from 'types';

export interface ApplicationUIReducer {
  popUpIsActive: boolean;
  notificationBarIsActive: boolean | null;
  notificationBarHeight: number;
  researchPopUpIsActive: boolean;
  testerRecruitmentPopUpIsActive: boolean;
}

const initialState: ApplicationUIReducer = {
  popUpIsActive: false,
  notificationBarIsActive: null,
  // THIS NEEDS TO BE CHANGED ANY TIME NOTIFICATION BAR TEXT IS CHANGED
  notificationBarHeight: 0,
  researchPopUpIsActive: false,
  testerRecruitmentPopUpIsActive: false,
};

function applicationUI(
  state: ApplicationUIReducer = initialState,
  action: Action,
): ApplicationUIReducer {
  switch (action.type) {
    case 'OPEN_POP_UP':
      return {
        ...state,
        popUpIsActive: true,
      };
    case 'CLOSE_POP_UP':
      return {
        ...state,
        popUpIsActive: false,
      };
    case 'CLOSE_NOTIFICATION_BAR':
      return {
        ...state,
        notificationBarIsActive: false,
      };
    case 'OPEN_NOTIFICATION_BAR':
      return {
        ...state,
        notificationBarIsActive: true,
      };
    case 'SET_NOTIFICATION_BAR_HEIGHT':
      return {
        ...state,
        notificationBarHeight: action.payload,
      };
    case 'OPEN_RESEARCH_POP_UP':
      return {
        ...state,
        researchPopUpIsActive: true,
      };
    case 'CLOSE_RESEARCH_POP_UP':
      return {
        ...state,
        researchPopUpIsActive: false,
      };
    case 'OPEN_TESTER_RECRUITMENT_POP_UP':
      return {
        ...state,
        testerRecruitmentPopUpIsActive: true,
      };
    case 'CLOSE_TESTER_RECRUITMENT_POP_UP':
      return {
        ...state,
        testerRecruitmentPopUpIsActive: false,
      };
    default:
      return state;
  }
}

export default applicationUI;
