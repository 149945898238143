import React, { FC } from 'react';
import { Svg } from '../base';

type Props = {
  className?: string;
};

const HealthCareWorkerSwabbingIndividual: FC<Props> = ({ className = '' }) => (
  <Svg
    width="100%"
    height="100%"
    viewBox="0 0 2000 1400"
    fill="transparent"
    className={className}
  >
    <desc>Illustration of a health care worker swabbing an individual.</desc>
    <path
      d="M564.196 587.77C565.091 619.613 553.9 650.611 532.883 674.51L643.385 762.21C658.429 724.098 668.39 684.163 673.012 643.44L564.196 587.77Z"
      fill="#EEB7AB"
    />
    <path
      d="M601.026 370C576.938 341.7 527.564 346.67 498.357 358.86C447.965 379.86 433.007 432.67 430.073 443.04C417.55 487.24 439.304 495.36 433.067 542.5C423.637 613.43 370.711 622.92 364.933 695.41C361.74 735.41 375.271 764.89 378.265 771.12C410.396 838.19 487.111 854.71 499.734 857.23C514.612 836.72 547.71 784.78 541.993 717.81C540.719 703.067 537.673 688.532 532.922 674.52C540.342 666.03 546.616 656.599 551.582 646.47C558.388 632.55 561.301 620.13 563.427 607.82C570.472 567.08 570.062 520.64 569.763 504.73C619.666 462.28 626.491 399.91 601.026 370Z"
      fill="#064FD7"
    />
    <path
      d="M751.533 511.26C759.959 490.068 762.012 466.868 757.44 444.52C755.674 436 748.689 407.17 723.364 383.65C677.732 341.26 614.967 355.54 609.299 356.93C600.747 359.02 551.862 370.99 529.191 416.13C506.789 460.74 518.873 520.01 560.504 561.93L751.533 511.26Z"
      fill="#006DFF"
    />
    <path
      d="M758.578 485C751.296 469.483 740.814 455.69 727.824 444.53C720.779 438.53 691.602 414.37 650.75 416.14C648.993 416.22 647.616 416.33 646.758 416.4C639.025 417.04 597.205 421.64 573.426 456.74C554.596 484.54 558.388 514.74 559.655 522.74C558.658 522.42 544.817 518.1 534.44 526.81C522.336 536.98 527.215 554.61 527.614 555.96C531.366 568.69 543.79 578.07 558.967 579.59C561.681 589.22 571.55 619.59 601.705 640.59C608.69 645.44 630.463 660.21 661.177 659.76C676.365 659.76 688.01 656.39 696.731 652.34C742.552 631 766.172 564.22 758.578 485Z"
      fill="#F5C3B6"
    />
    <path
      d="M551.543 646.46C559.306 650.46 567.229 654.84 575.242 659.75C610.32 681.372 641.461 708.832 667.324 740.95C679.522 746.438 692.644 749.569 706.001 750.18C712.457 750.46 751.653 752.18 777.846 726.18C795.139 709 789.062 697.28 807.313 683.73C827.27 668.9 850.6 670.4 855.21 670.81C880.525 673.06 896.421 688.46 906.779 698.5C913.604 705.11 935.956 727.41 939.928 763.09C940.846 771.33 942.722 790.33 933.482 810.15C925.369 827.56 914.033 834.15 900.333 847.06C876.255 869.83 883.13 876.18 855.21 912.58C829.499 945.953 797.995 974.408 762.21 996.58C752.86 1002.33 744.807 1006.58 739.189 1009.5C752.985 1102.53 747.334 1197.42 722.595 1288.15H394.77C390.918 1185.29 384.791 1103.36 380.031 1049.15C370.362 937.95 361.9 887.02 385.56 816.6C408.8 747.43 445.541 711.52 455.539 702.18C483.02 676.7 515.813 657.666 551.543 646.46V646.46Z"
      fill="#BBD1F9"
    />
    <path
      d="M1001.8 594.22C1004.47 586.207 1005.34 577.7 1004.34 569.31C1003.57 562.889 1001.7 556.649 998.822 550.86L1008.98 532.4C1014.87 536.314 1021.43 539.129 1028.32 540.71C1039.9 543.33 1056.13 543.16 1057.78 537.94C1058.92 534.34 1052.14 531.49 1039.36 518.56C1023.64 502.63 1025.33 498.23 1015.42 492.72C1009.43 489.39 1001.6 488.04 985.949 485.34H985.7C980.163 484.393 974.469 485.443 969.632 488.305C964.794 491.166 961.125 495.654 959.277 500.97L952.801 519.52C960.784 549.36 968.766 579.197 976.749 609.03L1001.8 594.22Z"
      fill="#064FD7"
    />
    <path
      d="M987.027 675.44C995.993 673.289 1004.25 668.837 1010.98 662.52C1018.53 655.32 1021.75 647.74 1024.8 640.37C1029.81 628.072 1032.31 614.893 1032.16 601.61C1034.94 593.672 1038.33 585.959 1042.29 578.54C1051.13 561.98 1059.37 553.39 1056.1 547.17C1053.87 542.93 1047.64 542.3 1047.07 542.25C1035.22 541.25 1021.98 557.25 1012.82 581.31C998.972 583.199 985.74 588.249 974.145 596.07C978.422 622.53 982.716 648.986 987.027 675.44Z"
      fill="#006DFF"
    />
    <path
      d="M883.419 709.9C882.845 706.192 883.215 702.398 884.495 698.872C885.776 695.345 887.925 692.201 890.744 689.73C899.977 681.61 909.29 673.153 918.684 664.36C925.955 657.56 933.016 650.793 939.868 644.06C939.311 629.644 936.835 615.368 932.504 601.61C927.864 587.09 922.525 578.06 924.212 563.78C924.781 558.78 926.736 553.7 930.658 543.48C935.274 531.22 941.135 519.468 948.15 508.41C955.135 497.49 958.628 492.03 963.118 489.97C971.56 486.05 975.97 491.11 1000.52 495.56C1020 499.1 1024.47 497.22 1032 502.87C1034.1 504.45 1037.99 509.48 1045.81 519.48C1057.93 535.07 1058.43 536.74 1057.78 537.94C1055.54 542.03 1040.26 539.83 1029.24 532.4C1024.88 529.45 1021.13 525.692 1018.19 521.33L987.795 522.25L973.057 539.78C975.322 546.53 979.044 557.23 984.103 570.24C994.82 597.67 999.48 604.3 1002.52 620.99C1005.95 639.79 1003.95 652.99 1003.44 656.06C1001.85 665.715 998.737 675.057 994.231 683.74C984.932 699.794 974.144 714.934 962.01 728.96C955.185 736.84 948.38 743.86 941.814 750.07C938.658 753.048 934.705 755.039 930.437 755.801C926.17 756.563 921.773 756.062 917.786 754.36C910.076 751.124 903.168 746.232 897.549 740.03C889.996 731.591 885.082 721.113 883.419 709.9V709.9Z"
      fill="#006DFF"
    />
    <path
      d="M996.055 560.15L1168.24 502"
      stroke="#6B6B6B"
      strokeWidth="3"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M1174.7 493.991L1132.28 508.033L1135.86 518.905L1178.28 504.863L1174.7 493.991Z"
      fill="#BBD1F9"
    />
    <path
      d="M1197.1 319.54C1183.97 343.745 1174.85 369.926 1170.09 397.06C1161.24 448.14 1160.81 546.15 1213.71 570.7C1222.75 574.9 1244.35 582.05 1281.15 567.5L1287.96 590.23L1401.83 590L1388.63 486.88L1197.1 319.54Z"
      fill="#F0A893"
    />
    <path
      d="M1172.23 217.38C1146.77 247.21 1138.36 285.6 1151.48 316.85C1159.46 335.76 1173.26 347.25 1181.91 353.16C1197.34 354.73 1206.46 360.88 1210.74 364.35C1228.33 378.63 1230.84 401.95 1232.7 419.21C1233.71 428.616 1233.71 438.104 1232.7 447.51L1247.43 420.44C1248.48 419.44 1255.78 412.88 1265.85 414.28C1276.82 415.81 1282.11 426.05 1283.03 427.82C1290.11 441.38 1283.17 462.47 1265.54 480.14C1268.69 488.6 1278.13 510.42 1301.09 524.68C1329.67 542.43 1370.22 542.92 1393.26 523.15C1404.35 513.65 1398.4 507.15 1417.36 459.15C1426.92 434.92 1429.33 433.71 1433.49 419.3C1436.56 408.69 1443.46 384.88 1440.18 357.68C1433.35 300.89 1386.81 267.96 1381.31 264.17C1372.03 257.83 1348.45 241.72 1316.23 240.79C1280.3 239.79 1268.68 259.93 1238.88 258.01C1222.77 257 1199.44 249.51 1172.23 217.38Z"
      fill="#162D5B"
    />
    <path
      d="M987.157 1164.82C953.39 1169.13 924.292 1172.32 901.212 1174.67C838.836 1181 832.36 1179.89 826.323 1178.36C805.887 1173.17 790.211 1163.06 771.072 1169.75C763.489 1172.53 756.731 1177.18 751.424 1183.28C747.774 1181.72 743.754 1181.23 739.839 1181.89C735.924 1182.54 732.278 1184.31 729.331 1186.97C720.85 1194.76 723.085 1206.15 723.185 1206.66C718.325 1207.96 710.652 1210.87 707.219 1217.73C704.485 1223.22 706.291 1227.58 708.446 1241.11C711.929 1262.99 710.083 1269.64 713.356 1270.64C718.455 1272.18 728.074 1257.69 732.694 1245.48C735.534 1237.93 737.021 1229.94 737.085 1221.88C742.336 1221.86 747.566 1222.53 752.641 1223.88C774.804 1229.8 777.438 1244.63 796.846 1250.95C806.705 1254.16 815.696 1253.09 833.677 1250.95C844.946 1249.61 856.051 1247.14 866.826 1243.57L989.562 1253.41L987.157 1164.82Z"
      fill="#F0A893"
    />
    <path
      d="M1241.3 605C1241.04 610.27 1244.82 615.8 1244.98 630.83C1245.04 636.189 1244.63 641.543 1243.75 646.83C1227.09 656.148 1211.81 667.742 1198.33 681.28C1181.12 698.6 1163.48 722.68 1144.31 773.56C1127.86 817.19 1119.42 856.37 1112.38 901.56C1103.16 961.028 1097.82 1021.04 1096.41 1081.2C1081.45 1092.58 1064.91 1101.7 1047.31 1108.27C1020.48 1118.27 1011.53 1114.53 996.965 1124.27C968.197 1143.49 964.395 1183.79 963.816 1193.17C962.08 1221.17 971.081 1263.26 998.192 1275.61C1007.17 1279.68 1007.62 1275.78 1057.13 1273.15C1065.55 1272.7 1089.71 1271.53 1118.51 1271.92C1135.36 1272.14 1149.33 1272.84 1159.09 1273.45L1160.25 1294.06L1495.43 1288.2C1511.28 1176.1 1524.17 1073.38 1534.72 981.55C1542.7 912.24 1547.54 864.55 1537.17 801.91C1529.45 755.672 1515.2 710.77 1494.86 668.55C1486.33 650.483 1480.3 631.328 1476.96 611.62C1476.2 607.261 1474.98 602.993 1473.33 598.89C1462.85 572.89 1438.58 563.07 1432.82 560.75C1415.34 553.75 1400.14 555.22 1368.96 560.75C1309.57 571.25 1242.35 583.61 1241.3 605Z"
      fill="#8248EE"
    />
    <path
      d="M723.802 417.05C710.553 439.692 693.619 459.955 673.7 477C655.409 492.58 615.744 526.36 565.612 523C562.02 522.763 558.443 522.339 554.895 521.73C527.125 475.47 540.376 415.73 582.676 386.63C627.739 355.63 692.849 368.45 723.802 417.05Z"
      fill="#006DFF"
    />
    <path
      opacity="0.12"
      d="M464.152 921.05C472.045 914.12 536.087 1017.54 643.396 1026.45C681.135 1029.59 714.134 1020.19 739.19 1009.45C740.657 1019.32 741.965 1029.62 743.052 1040.34C744.31 1052.63 745.178 1064.46 745.736 1075.77C715.192 1082 651.15 1090.23 585.651 1059.07C496.902 1016.92 457.277 927.09 464.152 921.05Z"
      fill="black"
    />
    <path
      opacity="0.19"
      d="M1159.1 1273.4C1187.49 1274.93 1236.54 1272.91 1270 1240.23C1294.81 1216 1298.56 1187.11 1311.47 1115.53C1318.23 1078.1 1328.95 1020.91 1344.06 949.26C1354.43 1024.66 1357.9 1116.88 1341.07 1219.44C1336.96 1244.58 1331.93 1268.44 1326.27 1291.11L1160.24 1294.01L1159.1 1273.4Z"
      fill="black"
    />
    <path
      d="M1251.85 425.92C1254.38 423.652 1257.55 422.228 1260.93 421.847C1264.3 421.465 1267.71 422.144 1270.69 423.79C1280.95 429.79 1277.5 446.79 1277.07 448.79C1274.53 460.47 1264.62 472.59 1257.61 470.72C1252.62 469.38 1250.52 461.3 1249.72 457.01C1251.38 456.665 1252.85 455.695 1253.82 454.299C1254.79 452.903 1255.18 451.187 1254.93 449.506C1254.67 447.825 1253.78 446.306 1252.44 445.263C1251.1 444.22 1249.41 443.732 1247.72 443.9C1246.55 440.832 1246.32 437.483 1247.05 434.281C1247.79 431.078 1249.45 428.168 1251.85 425.92Z"
      fill="#D88B79"
    />
    <path
      opacity="0.58"
      d="M1281.15 567.5C1295.49 558.914 1307.11 546.432 1314.66 531.5C1315.71 545.786 1312.86 560.09 1306.43 572.88C1303.16 573.62 1299.83 574.413 1296.45 575.26C1292.34 576.3 1288.35 577.37 1284.48 578.47C1283.36 574.823 1282.25 571.167 1281.15 567.5Z"
      fill="#D88B79"
    />
    <path
      d="M554.954 529.48C548.558 525.17 540.455 531.21 539.907 531.63C533.301 536.71 532.483 546.14 533.81 552.34C536.734 566.07 552.619 575.74 558.127 572.44C560.343 571.11 561.999 567 559.714 554.94C558.303 554.804 556.989 554.162 556.012 553.132C555.035 552.102 554.462 550.754 554.398 549.335C554.334 547.916 554.782 546.521 555.662 545.407C556.541 544.293 557.792 543.534 559.185 543.27C560.712 537.69 558.946 532.13 554.954 529.48Z"
      fill="#E2ACA3"
    />
    <path
      d="M712.166 517.33C717.58 522.126 721.99 527.952 725.139 534.47C728.512 541.151 730.298 548.523 730.357 556.01C730.332 557.937 730.159 559.859 729.838 561.76C729.493 563.7 728.962 565.602 728.252 567.44C726.805 571.312 724.207 574.646 720.808 576.99C717.442 579.04 713.57 580.103 709.632 580.06C705.984 580.053 702.368 579.375 698.965 578.06C700.751 577.92 702.497 577.83 704.214 577.6C705.893 577.383 707.559 577.069 709.203 576.66C712.253 575.951 715.102 574.556 717.535 572.58C719.651 570.547 721.196 567.991 722.015 565.17C722.919 562.177 723.373 559.066 723.362 555.94C723.285 549.339 722.17 542.793 720.059 536.54C717.881 529.959 715.244 523.54 712.166 517.33Z"
      fill="#E2A89F"
    />
    <path
      d="M1166.83 420.39C1151.53 443.16 1150.99 464.25 1159.18 470.25C1167.64 476.46 1185.12 466.25 1187.02 465.13C1188.2 464.063 1189.15 462.771 1189.83 461.33C1194.31 451.58 1184.48 435.51 1166.83 420.39Z"
      fill="#F0A893"
    />
    <path
      d="M712.959 604C707.535 607.451 701.387 609.597 694.997 610.27C691.77 610.62 688.515 610.62 685.288 610.27L682.873 609.94C682.075 609.8 681.277 609.62 680.479 609.46C679.683 609.298 678.896 609.091 678.123 608.84L675.779 608.14C672.704 607.052 669.757 605.634 666.988 603.91C665.581 603.09 664.313 602.06 662.996 601.12C661.727 600.119 660.517 599.044 659.374 597.9C654.739 593.465 651.162 588.041 648.906 582.03C652.438 587.103 656.656 591.66 661.439 595.57C662.607 596.57 663.864 597.39 665.062 598.3C666.259 599.21 667.566 599.98 668.913 600.65C671.522 602.121 674.253 603.362 677.076 604.36C682.77 606.324 688.766 607.265 694.788 607.14C700.965 606.987 707.087 605.929 712.959 604V604Z"
      fill="#F0A893"
    />
    <path
      d="M663.723 616C665.906 617.314 668.173 618.483 670.508 619.5C675.004 621.462 679.853 622.483 684.757 622.5C687.302 622.484 689.842 622.293 692.361 621.93C690.132 623.427 687.606 624.423 684.957 624.85C682.275 625.3 679.533 625.239 676.874 624.67C674.211 624.117 671.673 623.073 669.39 621.59C667.128 620.149 665.196 618.244 663.723 616V616Z"
      fill="#F0A893"
    />
    <path
      d="M565.156 596.4C571.614 619.502 584.568 640.256 602.472 656.184C620.375 672.111 642.473 682.542 666.129 686.23C667.732 677.25 669.336 668.27 670.939 659.29C655.773 660.694 640.484 658.755 626.145 653.61C586.8 639.31 569.297 605.11 565.156 596.4Z"
      fill="#F0A893"
    />
    <path
      opacity="0.12"
      d="M1243.76 646.83C1255.73 642.76 1273.81 637.11 1296.12 631.95C1351.41 619.15 1390.23 619.05 1390.13 616.02C1390.05 613.41 1361.32 611.02 1334.14 612.55C1314.46 613.68 1304.63 614.24 1293.66 616.71C1278.19 620.18 1269.85 624.37 1259.48 620.87C1250.5 617.87 1244.77 610.65 1241.3 605C1246.19 618.402 1247.04 632.947 1243.76 646.83Z"
      fill="black"
    />
  </Svg>
);

export default HealthCareWorkerSwabbingIndividual;
