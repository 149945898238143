import memoize from 'lodash/memoize';
import get from 'utils/get';
import { DrugReview } from 'types';

export default memoize(
  (drugReview: unknown, pageNumber: number): DrugReview => {
    return {
      id: get(drugReview, 'id'),
      drugPageId: get(drugReview, 'article_id'),
      drugRating: get(drugReview, 'rating', 0),
      description: get(drugReview, 'review', ''),
      duration: get(drugReview, 'duration'),
      firstNameInitial: get(drugReview, 'first_initial'),
      sideEffectManagement: get(drugReview, 'side_effect_management', ''),
      sideEffects: get(drugReview, 'side_effects', []).map(
        (sideEffect: unknown) => ({
          label: get(sideEffect, 'name', ''),
          value: get(sideEffect, 'name', ''),
        }),
      ),
      submittedDate: get(drugReview, 'submitted', '').split(' ')[0],
      pageNumber,
      ratings: {
        likes: get(drugReview, 'upvotes', 0),
      },
    };
  },
);
