import { z } from 'zod';

const linkSchema = z.object({
  _key: z.string(),
  label: z.string(),
  url: z.string().url(),
});

const educationAndTrainingSchema = z.object({
  _key: z.string(),
  details: z.string(),
  logo: z.any().optional(),
});

const practiceNameSchema = z.object({
  _key: z.string(),
  name: z.string(),
  logo: z.any().optional(),
});

const bioChildSchema = z.object({
  _type: z.string(),
  text: z.string(),
  marks: z.array(z.string()).optional(),
});

const bioBlockSchema = z.object({
  _key: z.string(),
  _type: z.string(),
  style: z.string(),
  markDefs: z.array(z.any()),
  children: z.array(bioChildSchema),
});

export const AuthorSchema = z.object({
  _id: z.string(),
  firstName: z.string().optional(),
  lastName: z.string().optional(),
  honorifics: z.string().optional(),
  fullName: z.string().optional(),
  specialty: z.string().optional(),
  slug: z.string().optional(),
  image: z.any().optional(),
  bio: z.array(bioBlockSchema).optional(),
  links: z.array(linkSchema).optional(),
  educationAndTraining: z.array(educationAndTrainingSchema).optional(),
  practiceNames: z.array(practiceNameSchema).optional(),
  certifications: z.array(z.any()).optional(),
  awards: z.array(z.any()).optional(),
  publications: z.array(z.any()).optional(),
});

export const ReviewerSchema = AuthorSchema;

export type AuthorSchema = z.infer<typeof AuthorSchema>;
export type ReviewerSchema = z.infer<typeof ReviewerSchema>;
