import memoize from 'lodash/memoize';
import get from 'utils/get';

import sanitizeSideNavOption from 'state/sanitizers/articleBodyModules/sanitizeSideNavOption';

import { HighlightedText } from 'types';

export default memoize((module: unknown): HighlightedText => {
  return {
    type: get(module, '_type', 'highlightedText'),
    id: get(module, '_key', ''),
    sideNav: sanitizeSideNavOption(module),
    text: get(module, 'text', []),
  };
});
