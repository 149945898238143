import memoize from 'lodash/memoize';
import get from 'utils/get';

import sanitizeAuthor from 'state/sanitizers/sanitizeAuthor';
import sanitizeArticleLinks from 'state/sanitizers/sanitizeArticleLinks';
import sanitizeImage from 'state/sanitizers/sanitizeImage';

import { EmphasizeModuleColorMap } from 'constants/ColorScheme';

import { DrugPage, DrugReviews, DrugSideEffectUgc } from 'types';

export default memoize(
  (
    drugPage: any,
    reviews: DrugReviews,
    ugc: DrugSideEffectUgc[],
    pageRatings: {
      likes: number;
      dislikes: number;
    },
  ): DrugPage | null => {
    const id = get(drugPage, '_id');

    if (!id) {
      return null;
    }

    return {
      id,
      type: get(drugPage, '_type', 'drug'),
      name: get(drugPage, 'name', ''),
      slug: get(drugPage, 'slug', ''),
      pronunciation: get(drugPage, 'pronunciation', []),
      headline: get(drugPage, 'headline', ''),
      author: sanitizeAuthor(get(drugPage, 'author')),
      readingTime: get(drugPage, 'readingTime', 0),
      publishedDate: get(drugPage, '_createdAt', ''),
      modifiedDate: get(drugPage, 'modifiedDate', '') || '',
      pricingUrl: get(drugPage, 'pricingUrl', ''),
      relatedDrugs: get(drugPage, 'relatedDrugs', []).map((drug: unknown) => ({
        id: get(drug, '_id'),
        name: get(drug, 'name'),
        slug: get(drug, 'slug'),
      })),
      relatedDiagnoses: sanitizeArticleLinks(
        get(drugPage, 'relatedDiagnoses', []),
      ),
      heroColor:
        EmphasizeModuleColorMap[get(drugPage, 'heroColor', '#fdefc8')] ||
        'LIGHTEST_ORANGE',
      overview: get(drugPage, 'overview', []),
      dosage: get(drugPage, 'dosageTablet', []),
      dosageTitle: get(drugPage, 'dosageTitle', ''),
      similarDrugs: get(drugPage, 'similarDrugs', []),
      basics: {
        descriptionTitle: get(drugPage, 'basicsDescriptionTitle', ''),
        description: get(drugPage, 'basicsDescription', []),
        genericOrBrandName: get(drugPage, 'genericName', ''),
        nameLabel: get(drugPage, 'nameLabel', 'generic'),
        prescriptionRequirement: get(drugPage, 'prescriptionRequirement', []),
        warning: get(drugPage, 'warning', []),
      },
      howToTakeIt: {
        whenAndHow: get(drugPage, 'whenAndHow', []),
        dos: get(drugPage, 'dos', []),
        donts: get(drugPage, 'donts', []),
      },
      sideEffects: {
        sideEffectsDiscussWithDoctor: get(
          drugPage,
          'sideEffectsDiscussWithDoctor',
          [],
        ),
        sideEffectsFdaData: {
          type: get(drugPage, 'sideEffectsFdaDataType', null),
          quantitative: get(drugPage, 'sideEffectsFdaDataQuantitative', []).map(
            (sideEffect: unknown) => ({
              id: get(sideEffect, '_key'),
              sideEffectNameSingular: get(sideEffect, 'nameSingular'),
              sideEffectNamePlural:
                get(sideEffect, 'namePlural') ||
                get(sideEffect, 'nameSingular'),
              percentage: get(sideEffect, 'percentage'),
            }),
          ),
          qualitativeCommon: get(
            drugPage,
            'sideEffectsFdaDataQualitativeCommon',
            [],
          ),
          qualitativeSerious: get(
            drugPage,
            'sideEffectsFdaDataQualitativeSerious',
            [],
          ),
        },
        fullListSideEffects: get(drugPage, 'fullListSideEffects', []),
        ugc,
      },
      longTermComplications: get(drugPage, 'longTermComplications', []),
      safetyNotes: get(drugPage, 'safetyNotes', []),
      additionalSafetyNotes: get(drugPage, 'additionalSafetyNotes', []),
      interactions: {
        interactionsWithOtherDrugs: get(
          drugPage,
          'interactionsWithOtherDrugs',
          [],
        ),
        interactionsWithPreExistingConditions: get(
          drugPage,
          'interactionsWithPreExistingConditions',
          [],
        ),
      },
      references: get(drugPage, 'references', []),
      reviews,
      seo: {
        canonicalUrl: get(drugPage, 'canonicalUrl', ''),
        description: get(drugPage, 'metaDescription', ''),
        image: sanitizeImage(get(drugPage, 'metaImage')),
        keywords: get(drugPage, 'keywords', []),
        noindex: get(drugPage, 'noindex', false),
        structuredData: get(drugPage, 'structuredData.code', null),
        title: get(drugPage, 'metaTitle', ''),
      },
      ratings: pageRatings,
      disableAds: drugPage.disableAds || false,
    };
  },
);
