import theme from '@buoyhealth/common.buoy-theme';
import { createTheme } from '@mui/material';
import { Theme } from 'styled';

/**
 * This is a stub of a custom theme passed to the MUI ThemeProvider.
 * When buoy-theme is upgraded to use MUI5, we can transition to that ThemeProvider directly.
 */
export const materialTheme = createTheme({
  palette: theme.palette,
  typography: {
    fontFamily: Theme.typography['gt-eesti-display'],
  },
});
