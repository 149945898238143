import ApiClient from 'lib/SanityClient/ApiClient';

import {
  FetchGlobalSettingsAction,
  FetchTrendingArticlesImagesAction,
} from 'state/actions/types/globalSettingsActionsTypes';

export const fetchGlobalSettings = (): FetchGlobalSettingsAction => ({
  type: 'FETCH_GLOBAL_SETTINGS',
  payload: ApiClient.fetchGlobalSettings(),
});

export const fetchTrendingArticlesImages =
  (): FetchTrendingArticlesImagesAction => ({
    type: 'FETCH_TRENDING_ARTICLES_IMAGES',
    payload: ApiClient.fetchTrendingArticlesImages(),
  });
