import { useEffect, useState } from 'react';
import Polyglot from 'node-polyglot';
import { useSelector } from 'react-redux';
import en from 'constants/Language/Locales/en-US.json';

import type { GlobalState } from 'types';

export type Language = Polyglot;

export function createDictionary(locale?: Record<string, unknown>) {
  const language = new Polyglot();

  language.extend(en);

  if (locale) {
    language.extend(locale);
  }

  return language;
}

function useLanguage() {
  const [language, setLanguage] = useState(createDictionary());
  const i18n = useSelector((state: GlobalState) => state.i18n.locale);

  useEffect(() => {
    async function loadPhrases() {
      const phrases = await import(`constants/Language/Locales/${i18n}.json`);

      setLanguage(createDictionary(phrases));
    }

    loadPhrases();
  }, [i18n]);

  return language;
}

export default useLanguage;
