import { useEffect, useState } from 'react';
import { CurrentBreakpoint, Breakpoints } from 'lib/withBreakpoints';
import throttle from 'lodash/throttle';

const useBreakpoint = (): string | null => {
  const [breakpoint, setBreakpoint] = useState<string | null>(null);

  const setCurrentBreakpoint = () => {
    const currentViewportWidth =
      typeof window === 'undefined' ? null : Math.round(window.innerWidth);

    const getCurrentBreakpoint = (): CurrentBreakpoint => {
      if (!currentViewportWidth) return null;

      return (
        Object.keys(Breakpoints).find(
          (key) =>
            Breakpoints[key].lowerBound <= currentViewportWidth &&
            Breakpoints[key].upperBound > currentViewportWidth
        ) || null
      );
    };

    setBreakpoint(getCurrentBreakpoint());
  };

  useEffect(() => {
    setCurrentBreakpoint();

    window.addEventListener('resize', throttle(setCurrentBreakpoint, 300));

    return () =>
      window.removeEventListener('resize', throttle(setCurrentBreakpoint, 300));
  }, []);

  return breakpoint;
};

export default useBreakpoint;
