import styled, { createGlobalStyle } from 'styled-components';
import {
  flexbox,
  space,
  layout,
  typography,
  color,
  position,
  background,
} from 'styled-system';
import Theme from 'styled/theme';
import { BaseProps, SvgProps } from './types';
import Arrows from 'constants/Arrows';

type VimeoPlayerContainerProps = {
  ratio: number | null;
};

type CardTileImgContainerProps = {
  bgColor?: string;
};

export const Base = styled.div<BaseProps>`
  ${flexbox}
  ${space}
  ${layout}
  ${typography}
  ${color}
  ${position}
  ${background}
`;

export const Svg = styled.svg.attrs({
  version: '1.1',
  xmlns: 'http://www.w3.org/2000/svg',
  xmlnsXlink: 'http://www.w3.org/1999/xlink',
})<SvgProps>`
  ${color}
`;

export const AnchorLabel = styled.span``;

export const VimeoPlayerContainer = styled(Base)<VimeoPlayerContainerProps>`
  ${({ theme }) => theme.transitions.transition};
  opacity: ${({ ratio }) => (!ratio ? 0 : 1)};
  padding-top: ${({ ratio }) => (ratio ? `${ratio}%` : undefined)};
  position: relative;
  width: 100%;

  > iframe {
    position: absolute;
    top: 0;
    left: 0;
    border: 0;
    width: 100%;
    height: 100%;

    body {
      overflow: hidden;
    }
  }
`;

export const PopUpVideo = styled(Base)`
  margin-bottom: -1px;
  display: flex;
  justify-content: center;
  overflow-x: hidden;

  ${VimeoPlayerContainer} {
    min-width: 800%;
    height: 100%;

    ${Theme.mediaQueries.sm} {
      min-width: unset;
    }
  }
`;

export const CardTile = styled(Base)`
  width: 100%;
  display: flex;
  flex-direction: column;
  filter: drop-shadow(0px 0px 12px rgba(0, 0, 0, 0.1));
  border-radius: 0.25rem;
`;

export const CardTileImgContainer = styled(Base)<CardTileImgContainerProps>`
  background-color: ${({ bgColor }) => bgColor};
  border-radius: 0.25rem 0.25rem 0rem 0rem;
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${({ theme }) => theme.sizes.cardThumbnailImageHeight};

  * {
    height: ${({ theme }) => theme.sizes.cardThumbnailImageHeight};
  }

  img {
    margin: 0 auto;
    border-radius: 0.25rem 0.25rem 0rem 0rem;
  }
`;

export const CardTileTitleContainer = styled(Base)`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  a,
  .Link__container {
    width: 100%;
    display: flex;
    align-items: center;

    ::after {
      content: '${Arrows.RIGHT_ARROW}';
      color: ${({ theme }) => theme.palette.primary.main};
      margin-left: 2rem;
    }
  }

  span {
    flex: 1;
  }
`;

export const CardTileList = styled(Base)`
  display: grid;
  row-gap: 1rem;
  grid-template-columns: 1fr;

  ${Theme.mediaQueries.sm} {
    gap: 2.5rem;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: auto;
  }

  ${Theme.mediaQueries.md} {
    grid-template-columns: repeat(3, 1fr);
  }
`;

export const CardTileListTitle = styled.h2`
  ${({ theme }) => theme.typography.variants.primary.md};
  margin: 0rem 1rem;

  ${Theme.mediaQueries.sm} {
    ${({ theme }) => theme.typography.variants.primary['md-lg']};
    margin: 0rem 4rem;
  }
`;

export const GlobalStyle = createGlobalStyle`
  html,
  body,
  div,
  span,
  applet,
  object,
  iframe,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  blockquote,
  pre,
  a,
  abbr,
  acronym,
  address,
  big,
  cite,
  code,
  del,
  dfn,
  em,
  img,
  ins,
  kbd,
  q,
  s,
  samp,
  small,
  strike,
  strong,
  sub,
  sup,
  tt,
  var,
  b,
  u,
  i,
  center,
  dl,
  dt,
  dd,
  ol,
  ul,
  li,
  fieldset,
  form,
  label,
  legend,
  table,
  caption,
  tbody,
  tfoot,
  thead,
  tr,
  th,
  td,
  article,
  aside,
  canvas,
  details,
  embed,
  figure,
  figcaption,
  footer,
  header,
  hgroup,
  menu,
  nav,
  output,
  ruby,
  section,
  summary,
  time,
  mark,
  audio,
  video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
    white-space: normal;
  }
  article,
  aside,
  details,
  figcaption,
  figure,
  footer,
  header,
  hgroup,
  menu,
  nav,
  section {
    display: block;
  }
  * {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    box-sizing: border-box;
  }
  button {
    appearance: none;
    border: none;
    padding: initial;
  }

  li {
    list-style-type: none;
  }

  a {
    color: inherit;
    text-decoration: none;
  }
`;
