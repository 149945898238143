import Head from 'next/head';
import { useRouter } from 'next/router';
import React, { FC } from 'react';
import type { ProviderPage } from 'types';
import baseSchema, { type BaseSchema } from './baseSchema';

type Props = {
  providerPage: ProviderPage;
};

const ProviderPageStructuredDataSchema: FC<Props> = ({ providerPage }) => {
  const { asPath } = useRouter();

  const schema: BaseSchema = {
    ...baseSchema(asPath),
    headline: providerPage.seo.title,
    description: providerPage.seo.description,
  };

  return (
    <Head>
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: JSON.stringify(schema),
        }}
      />
    </Head>
  );
};

export default ProviderPageStructuredDataSchema;
