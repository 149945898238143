import memoize from 'lodash/memoize';
import get from 'utils/get';

import sanitizeArticleLink from 'state/sanitizers/sanitizeArticleLink';

import { ArticleLink } from 'types';

export default memoize((articleLinks: unknown): ArticleLink[] => {
  if (!get(articleLinks, 'length', 0)) return [];

  const sanitizedArticleLinks = (articleLinks as unknown[]).map(
    (articleLink: unknown) => sanitizeArticleLink(articleLink),
  );

  /*
   * Sort by newest first -
   * This can be removed after modifiedDate bug is fixed,
   * and we can depend solely on the sort order in GROQ statement
   */
  return sanitizedArticleLinks.sort((a, b) =>
    b.modifiedDate.localeCompare(a.modifiedDate),
  );
});
