import React from 'react';
import Script from 'next/script';

function GoogleConsentDefault() {
  /*
   * To be GDPR compliant, explicitly set default consent states to denied to ensure that
   * no Google tags can fire without consent. Consent is managed via <ConsentManagement/>
   */
  return (
    <Script data-cookieconsent="ignore">
      {`
        window.dataLayer = window.dataLayer || [];

        function gtag() {
            dataLayer.push(arguments);
        }

        gtag("consent", "default", ${JSON.stringify({
          ad_personalization: 'granted',
          ad_storage: 'granted',
          ad_user_data: 'granted',
          analytics_storage: 'granted',
          functionality_storage: 'granted',
          personalization_storage: 'granted',
          security_storage: 'granted',
          wait_for_update: 500,
        })});

        gtag("set", "ads_data_redaction", true);
        gtag("set", "url_passthrough", true);
      `}
    </Script>
  );
}

export default GoogleConsentDefault;
