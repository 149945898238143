import memoize from 'lodash/memoize';
import get from 'utils/get';
import validateSchema from 'utils/validateSchema';
import { QuizSlug } from 'lib/SanityClient/schemas/quiz';

import sanitizeQuizModules from './modules/sanitizeQuizModules';
import sanitizeImage from 'state/sanitizers/sanitizeImage';
import sanitizeAuthor from './sanitizeAuthor';

import type { Quiz } from 'types';

export default memoize((quiz: any): Quiz | null => {
  const id = get(quiz, '_id');

  if (!id) {
    return null;
  }

  const parsedSlug = validateSchema({
    schema: QuizSlug,
    data: quiz.slug,
  });

  const slug = parsedSlug.current;

  const title = get(quiz, 'title');
  const conditionName = title.replace(' Quiz', '');

  return {
    id,
    title: title,
    conditionName: conditionName,
    slug: slug,
    type: get(quiz, '_type', 'quiz'),
    quizType: get(quiz, 'quizType'),
    modules: sanitizeQuizModules(quiz.quizBodyModuleRepeater),
    seo: {
      canonicalUrl: get(quiz, 'canonicalUrl', ''),
      description: get(quiz, 'metaDescription', ''),
      image: sanitizeImage(get(quiz, 'metaImage')),
      keywords: get(quiz, 'keywords', []),
      noindex: get(quiz, 'noindex', false),
      structuredData: get(quiz, 'structuredData.code', null),
      title: get(quiz, 'metaTitle', ''),
    },
    meta: {
      author: sanitizeAuthor(get(quiz, 'author')),
      reviewer: sanitizeAuthor(get(quiz, 'reviewer')),
      publishedDate:
        get(quiz, 'publishedDate', '') || get(quiz, '_createdAt', '') || '',
      // Temporary fix for modifiedDate not being set using _updatedAt when "Publish" button is clicked
      modifiedDate:
        get(quiz, 'modifiedDate', '') || get(quiz, '_updatedAt', '') || '',
    },
  };
});
