import localForage from 'localforage';

export const openPopUp = () => ({
  type: 'OPEN_POP_UP',
});

export const closePopUp = () => {
  localForage.setItem('popUpDismissDate', new Date(), (err) =>
    console.error(err)
  );

  return {
    type: 'CLOSE_POP_UP',
  };
};

export const openNotificationBar = () => ({
  type: 'OPEN_NOTIFICATION_BAR',
});

export const closeNotificationBar = () => {
  localForage.setItem('notificationBarDismissDate', new Date(), (err) =>
    console.error(err)
  );

  return {
    type: 'CLOSE_NOTIFICATION_BAR',
  };
};

export const setNotificationBarHeight = (height: number) => ({
  type: 'SET_NOTIFICATION_BAR_HEIGHT',
  payload: height,
});

export const openResearchPopUp = () => ({
  type: 'OPEN_RESEARCH_POP_UP',
});

export const closeResearchPopUp = () => ({
  type: 'CLOSE_RESEARCH_POP_UP',
});

export const openTesterRecruitmentPopUp = () => ({
  type: 'OPEN_TESTER_RECRUITMENT_POP_UP',
});

export const closeTesterRecruitmentPopUp = () => ({
  type: 'CLOSE_TESTER_RECRUITMENT_POP_UP',
});
