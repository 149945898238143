import type {
  Organization,
  Audience,
  WithContext,
  MedicalWebPage,
} from 'schema-dts';

const publisher: Organization = {
  '@type': 'Organization',
  name: 'Buoy Health, Inc.',
  logo: {
    '@type': 'ImageObject',
    url: 'https://www.buoyhealth.com/static/images/app_icons/ios-icon-192x192.png',
  },
};

const audience: Audience = {
  '@type': 'Audience',
  name: 'https://schema.org/Patient',
};

function baseSchema(path: string): WithContext<MedicalWebPage> {
  return {
    '@context': 'https://schema.org',
    '@type': 'MedicalWebPage',
    publisher,
    audience,
    headline: 'Buoy Health: Check Symptoms & Find the Right Care',
    description:
      'Check your symptoms and clarify your options for care. The Buoy A.I. health assistant guides you on your way to well, the moment you feel sick.',
    image: [
      'https://cdn.sanity.io/images/0b678gck/buoy-public-site/e56d3d48f9be2ea73150ed52331a61bca6d6c8e3-2501x1668.png',
    ],
    url: `https://www.buoyhealth.com${path}`,
  };
}

export type BaseSchema = ReturnType<typeof baseSchema>;

export default baseSchema;
