import memoize from 'lodash/memoize';
import get from 'utils/get';

import { DefaultImage } from 'constants/Default';

import { Image } from 'types';

export default memoize((image: unknown, alt?: string): Image => {
  const id = get(image, '_id', '');

  if (!id) {
    return DefaultImage;
  }

  return {
    id: get(image, '_id', ''),
    src: get(image, 'url', ''),
    alt: alt || get(image, 'alt', ''),
    crop: get(image, 'crop', null),
    hotspot: get(image, 'hotspot', null),
    metadata: get(image, 'metadata', null),
  };
});
