import React, { FC } from 'react';

import cx from 'classnames';
import sanityImgUtil from 'utils/sanityImgUtil';
import sanityImgSrcSetUtil from 'utils/sanityImgSrcSetUtil';
import withBreakpoints, {
  InjectedProps as WithBreakpointsProps,
} from 'lib/withBreakpoints';

import { Img } from 'styled';

import { ArticleImage as IArticleImage } from 'types';

interface PassedProps {
  articleImage: IArticleImage | null;
}

type Props = PassedProps & WithBreakpointsProps;

const ArticleImage: FC<Props> = ({ articleImage, currentBreakpoint }) => {
  if (!articleImage) {
    return null;
  }

  const { desktopImage, mobileImage, caption, isFullWidth } = articleImage;

  const breakpointIsSmDown = ['EXTRA_SMALL', 'SMALL'].includes(
    currentBreakpoint
  );
  const image =
    breakpointIsSmDown && mobileImage.id ? mobileImage : desktopImage;

  return (
    <div
      className={cx('ArticleImage my3', {
        'article-inner-text-full-width-md-up-extra-wide': isFullWidth,
      })}
    >
      <Img
        alt={image.alt}
        className="w100"
        sizes="(max-width: 768px) 768px, (max-width: 1024px) 920px, 1500px"
        src={sanityImgUtil(image, 768)}
        srcSet={sanityImgSrcSetUtil(image, 768, 920, 1500)}
        preloadContainerClassName="w100"
        dimensions={image.metadata?.dimensions}
        crop={image.crop}
      />
      <div className="caption-max-width mt_75">
        <span className="text-xs color-gray-100">{caption}</span>
      </div>
    </div>
  );
};

export default withBreakpoints(ArticleImage);
