import memoize from 'lodash/memoize';

import { IGoogleFormModule } from 'types';

export default memoize((googleFormModule: any): IGoogleFormModule | null => {
  const id = googleFormModule._key || '';
  const url = googleFormModule.googleFormUrl || '';

  if (!id || !url) {
    return null;
  }

  return {
    height: googleFormModule.height || null,
    id,
    trustedFormEnabled: googleFormModule.trustedFormEnabled || false,
    type: googleFormModule._type,
    url,
    width: googleFormModule.width || null,
  };
});
