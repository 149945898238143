import React, { FC } from 'react';
import { withRouter } from 'next/router';
import { WithRouterProps } from 'next/dist/client/with-router';

import sanityImgUtil from 'utils/sanityImgUtil';
import sanityImgSrcSetUtil from 'utils/sanityImgSrcSetUtil';
import { isContentHubOrArticlePage } from 'utils/determinePageVariant';

import { Button, Img, Link } from 'styled';
import {
  ImageContainer,
  ImageText,
  Container,
  Inner,
  MenuContainer,
  Menu,
  MenuTitle,
  MenuLink,
  InfoContainer,
  InfoLinks,
  InfoLink,
  CopyrightContainer,
} from './style';

import useLanguage from 'hooks/useLanguage';

import { Footer as IFooter, FooterImage as IFooterImage } from 'types';

export interface FooterProps {
  footer: IFooter;
  pageHasMinimalNav: boolean;
}

interface FooterImageProps {
  footerImage: IFooterImage;
  renderAltImage: boolean;
}

const FooterImage: FC<FooterImageProps> = ({ footerImage, renderAltImage }) => {
  if (!footerImage.image || !footerImage.alternateImage) {
    return null;
  }

  return (
    <ImageContainer
      position="relative"
      overflow="hidden"
      display={['none', 'none', 'none', 'flex']}
      justifyContent="center"
      alignItems="center"
      mt="auto"
      flexGrow={1}
    >
      {renderAltImage ? (
        <Img
          alt={footerImage.alternateImage.alt}
          sizes="(max-width: 768px) 768px, (max-width: 1024px) 1024px, 1990px"
          src={sanityImgUtil(footerImage.alternateImage, 768)}
          srcSet={sanityImgSrcSetUtil(
            footerImage.alternateImage,
            768,
            1024,
            1990,
          )}
          crop={footerImage.alternateImage.crop}
        />
      ) : (
        <Img
          alt={footerImage.image.alt}
          sizes="(max-width: 768px) 768px, (max-width: 1024px) 1024px, 1990px"
          src={sanityImgUtil(footerImage.image, 768)}
          srcSet={sanityImgSrcSetUtil(footerImage.image, 768, 1024, 1990)}
          crop={footerImage.image.crop}
        />
      )}
      {footerImage.text && (
        <ImageText position="absolute" bottom="0" mb={1.5}>
          <span>{footerImage.text}</span>
        </ImageText>
      )}
    </ImageContainer>
  );
};

const Footer: FC<FooterProps & WithRouterProps> = ({
  footer,
  pageHasMinimalNav,
  router,
}) => {
  const Language = useLanguage();
  const renderAltImage = isContentHubOrArticlePage(router);

  return (
    <Container pageHasMinimalNav={pageHasMinimalNav}>
      {footer.footerImage && (
        <FooterImage
          footerImage={footer.footerImage}
          renderAltImage={renderAltImage}
        />
      )}
      <Inner
        pt={[0, 0, 0, '2.2125rem']}
        px={[1.5, 1.5, 1.5, 2.25]}
        pb={[1.5, 1.5, 1.5, 1.25]}
      >
        <MenuContainer mb={[2.25, 2.25, 2.25, 3]}>
          {footer.menu.map((menu) => {
            return (
              <Menu key={menu.title}>
                <MenuTitle>{menu.title}</MenuTitle>
                <ul>
                  {menu.list.map((list) => (
                    <li key={list.id}>
                      <MenuLink
                        as={Link}
                        variant="hover-underline-blue"
                        ariaLabel={list.label}
                        label={list.label}
                        to={list.slug}
                        wrap={true}
                      />
                    </li>
                  ))}
                </ul>
              </Menu>
            );
          })}
          {/* Temporarily remove account section per client request */}
          {/* <Menu ml={['initial', 'initial', 'initial', 'auto']}>
            <MenuTitle>{Language.t('Footer.account')}</MenuTitle>
            <ul>
              <li>
                <MenuLink
                  as={LinkOrButton}
                  variant="hover-underline-blue"
                  ariaLabel={Language.t('Footer.signIn')}
                  label={Language.t('Footer.signIn')}
                  to={RouteMap.SIGN_IN.path}
                  wrap={true}
                />
              </li>
              <li>
                <MenuLink
                  as={LinkOrButton}
                  variant="hover-underline-blue"
                  ariaLabel={Language.t('Footer.createAccount')}
                  label={Language.t('Footer.createAccount')}
                  to={RouteMap.SIGN_UP.path}
                  wrap={true}
                />
              </li>
            </ul>
          </Menu> */}
          <Menu>
            <MenuTitle>{Language.t('Footer.social')}</MenuTitle>
            <ul>
              {footer.social.map((social) => (
                <li key={social.label}>
                  <MenuLink
                    as={Button}
                    variant="hover-underline-blue"
                    ariaLabel={Language.t('Footer.socialAriaLabel', {
                      socialMediaPlatform: social.label,
                    })}
                    label={social.label}
                    to={social.url}
                    wrap={true}
                  />
                </li>
              ))}
            </ul>
          </Menu>
        </MenuContainer>
        <InfoContainer>
          <InfoLinks display="flex" mt={[2.25, 2.25, 2, 2.25]}>
            <InfoLink
              as={Link}
              variant="hover-underline-gray"
              ariaLabel={Language.t('Footer.terms')}
              label={Language.t('Footer.terms')}
              to={footer.termsSlug}
            />
            <InfoLink
              as={Link}
              variant="hover-underline-gray"
              ml={0.75}
              ariaLabel={Language.t('Footer.privacy')}
              label={Language.t('Footer.privacy')}
              to={footer.privacySlug}
            />
            <InfoLink
              as={Link}
              variant="hover-underline-gray"
              ml={0.75}
              ariaLabel={Language.t('Footer.cookies')}
              label={Language.t('Footer.cookies')}
              to={footer.cookiesSlug}
            />
          </InfoLinks>
          <CopyrightContainer mt={['3.625rem', '3.625rem', '1.8125rem', 2.25]}>
            <p>
              {Language.t('Footer.copyrightTitle', {
                year: new Date().getFullYear(),
              })}
            </p>
            <p>{Language.t('Footer.copyrightDescription')}</p>
          </CopyrightContainer>
        </InfoContainer>
      </Inner>
    </Container>
  );
};

export default withRouter(Footer);
