import React, { FC } from 'react';
import theme from '@buoyhealth/common.buoy-theme';
import styled from 'styled-components';
import { Svg } from 'styled';
import { Color } from 'styled/theme/colors';

type Props = {
  className?: string;
  primaryColor?: Color;
  secondaryColor?: Color;
};

const PlayIcon: FC<Props> = ({
  className = '',
  primaryColor = theme.palette.common.white,
  secondaryColor = theme.palette.gray[100],
}) => (
  <Svg
    width="90px"
    height="90px"
    viewBox="0 0 90 90"
    className={className}
    fill="transparent"
  >
    <desc>Play Icon.</desc>
    <circle cx="45" cy="45" r="45" fill={primaryColor} />
    <path
      d="M61.3203 44.5973L37.2382 58.5418L37.2382 30.6528L61.3203 44.5973Z"
      fill={secondaryColor}
    />
  </Svg>
);

export default styled(PlayIcon)`
  circle {
    ${({ theme }) => theme.transitions['transition']};
    opacity: 0.7;
  }

  :hover {
    circle {
      opacity: 1;
    }
  }
`;
