/**
 * Returns a numeric pixel value when passed an rem string
 * @example
 * convertRemStringToPixels("4rem")
 *
 *  convertRemStringToPixels("4rem", 64)
 * */
const convertRemStringToPixels = (rem: string, defaultValue?: number) => {
  const remNum = parseFloat(rem);
  if (isNaN(remNum)) {
    return undefined;
  }

  if (typeof window !== 'undefined') {
    return (
      remNum *
      parseFloat(window.getComputedStyle(document.documentElement).fontSize)
    );
  }

  return defaultValue;
};

export default convertRemStringToPixels;
