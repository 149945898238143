import SanityClient from 'lib/SanityClient';

import { LanguagesSchema } from 'lib/SanityClient/schemas/languages';
import validateSchema from 'utils/validateSchema';
import type { Languages } from 'lib/SanityClient/schemas/languages';

type GetPaginationIndiciesOptions = {
  pageNumber: number;
  limit: number;
};
export function getPaginationIndicies(options: GetPaginationIndiciesOptions) {
  const start = options.limit * (options.pageNumber - 1);
  const end = start + (options.limit - 1);
  return { start, end };
}

export async function getLanguages(query: string) {
  const languages = (await SanityClient.fetch(query)) as Languages;
  const validatedLanguages: Languages = validateSchema({
    schema: LanguagesSchema,
    data: languages,
    defaultValue: ['en'],
  });
  return validatedLanguages;
}
