import React, { lazy, Suspense } from 'react';

const DynamicFeatherIcon = ({
  name,
  ...rest
}: {
  name: string;
  fill?: string;
  stroke?: string;
  className?: string;
  size?: number;
}) => {
  const LazyIcon = lazy(async () => {
    const module = await import(`react-feather`);
    // @ts-ignore
    return { default: module[name] };
  });

  if (!LazyIcon) {
    return null;
  }

  return (
    <Suspense fallback={null}>
      <LazyIcon {...rest} />
    </Suspense>
  );
};

export default DynamicFeatherIcon;
