import memoize from 'lodash/memoize';
import get from 'utils/get';

import sanitizeInternalLink from 'state/sanitizers/sanitizeInternalLink';
import sanitizeImage from 'state/sanitizers/sanitizeImage';
import sanitizeModuleOptions from 'state/sanitizers/modules/sanitizeModuleOptions';

import { EmphasizeModuleColorMap } from 'constants/ColorScheme';

import { LinkCardWithImageModule } from 'types';

export default memoize((module: unknown): LinkCardWithImageModule => {
  return {
    type: get(module, '_type', 'linkCardWithImage'),
    id: get(module, '_key', ''),
    anchor: get(module, 'anchor', ''),
    linkCard: {
      id: get(module, '_key', ''),
      title: get(module, 'contentTitle', ''),
      text: get(module, 'contentText', []),
      button: {
        label: get(module, 'buttonLabel', ''),
        url: sanitizeInternalLink(get(module, 'buttonUrl', '')),
        variant: get(module, 'buttonVariant', 'underline-black'),
        gtmTrackerEventName: get(module, 'gtmTrackerEventName', ''),
      },
    },
    image: sanitizeImage(get(module, 'image')),
    fillImageContainer: get(module, 'fillImageContainer', false),
    imageContainerBackgroundColor:
      EmphasizeModuleColorMap[
        get(module, 'imageContainerBackgroundColor', '#ffffff')
      ] || 'WHITE',
    desktopImagePosition: get(module, 'desktopImagePosition', 'left'),
    moduleOptions: sanitizeModuleOptions(module),
    desktopMaxHeight: get(module, 'desktopMaxHeight', 0),
  };
});
