import React, { FC } from 'react';
import theme from '@buoyhealth/common.buoy-theme';
import { Svg } from 'styled/components/base';
import { Color } from 'styled/theme/colors';

type Props = {
  className?: string;
  color?: Color;
};

const NavOpenIcon: FC<Props> = ({
  className = '',
  color = theme.palette.primary.main,
}) => (
  <Svg
    width="36px"
    height="36px"
    viewBox="0 0 36 36"
    className={className}
    fill="transparent"
  >
    <desc>Nav Open Icon.</desc>
    <rect x="3" y="3" width="30" height="6" rx="1" fill={color} />
    <rect x="3" y="15" width="30" height="6" rx="1" fill={color} />
    <rect x="3" y="27" width="30" height="6" rx="1" fill={color} />
  </Svg>
);

export default NavOpenIcon;
