import React, {
  useCallback,
  useEffect,
  useState,
  lazy,
  useContext,
  Suspense,
} from 'react';
import Cookies from 'js-cookie';

import useFeatureFlags from 'hooks/useFeatureFlags';
import { COOKIE, DEFAULT_DELAY } from './constants';
import useBreakpointIsMobile from 'hooks/useBreakpointIsMobile';
import usePage from 'hooks/usePage';
import PolarisPopUpContext from 'contexts/PolarisPopUpContext';

const EmailCollectionDialog = lazy(() => import('./components/Dialog'));

const EmailCollectionForm = () => {
  const { isPopupOpen: isPolarisPopupOpen } = useContext(PolarisPopUpContext);
  const [showDialog, setShowDialog] = useState(false);

  const featureFlags = useFeatureFlags();

  const emailCollectionEnabled =
    featureFlags?.['growth-public-site-email-collection-enabled'];

  const isMobile = useBreakpointIsMobile();

  const page = usePage();

  const openDialog = useCallback(() => {
    setShowDialog(true);
  }, []);

  useEffect(() => {
    const userHasPreviouslyDismissedModal = Cookies.get(
      COOKIE.EMAIL_PROMPT_DISMISSED,
    );

    const userHasAlreadySubmitted = Cookies.get(COOKIE.EMAIL_SUBMITTED);

    if (
      userHasAlreadySubmitted ||
      userHasPreviouslyDismissedModal ||
      !emailCollectionEnabled
    ) {
      return;
    }

    if (isMobile) {
      const timeout = setTimeout(() => {
        openDialog();
      }, DEFAULT_DELAY);

      return () => {
        clearTimeout(timeout);
      };
    } else {
      const timeout = setTimeout(() => {
        document.addEventListener('mouseleave', openDialog);
      }, DEFAULT_DELAY);

      return () => {
        document.removeEventListener('mouseleave', openDialog);

        clearTimeout(timeout);
      };
    }
  }, [emailCollectionEnabled, featureFlags, isMobile, openDialog]);

  const handleClose = useCallback(() => {
    setShowDialog(false);
    document.removeEventListener('mouseleave', openDialog);
  }, [openDialog]);

  if (
    isPolarisPopupOpen ||
    !showDialog ||
    !emailCollectionEnabled ||
    !page?.article
  ) {
    return null;
  }

  // TODO do we want to disable this for cost pages?

  return (
    <Suspense fallback={null}>
      <EmailCollectionDialog onClose={handleClose} article={page.article} />;
    </Suspense>
  );
};

export default EmailCollectionForm;
