import styled from 'styled-components';
import { Base } from 'styled/components/base';
import NavChevron from 'styled/components/svg/NavChevron';
import theme from 'styled/theme';

export const MenuDropdown = styled.li`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
`;

export const MenuDropdownItems = styled.ul`
  align-items: flex-start !important;
  background-color: ${theme.palette.common.white};
  display: flex;
  flex-direction: column;
  height: auto;
  justify-content: flex-start !important;
  max-height: 0;
  overflow-x: hidden;
  overflow-y: hidden;
  padding: 0px;
  position: static;
  top: 95px;
  width: 100vw;

  &.open {
    max-height: 100vh;
    transition: max-height 0.5s ease-in-out 0.1s;
  }

  &.close {
    max-height: 0;
    transition: max-height 0.5s ease-in-out 0;
  }
`;

export const MenuIconDown = styled(Base).attrs({ as: NavChevron })`
  margin-left: auto !important;
`;

export const MenuIconUp = styled(Base).attrs({ as: NavChevron })`
  margin-left: auto !important;
  transform: rotate(-180deg);
`;
