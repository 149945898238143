import { IOrganization, IPlan } from '@buoyhealth/common.buoy-types';
import { Server, Request, ModelInstance, Model, belongsTo } from 'miragejs';
import Schema from 'miragejs/orm/schema';
import { FeatureFlags } from 'types';

export const PlanModel = Model.extend({
  careCollection: belongsTo(),
  careResource: belongsTo(),
});

export type MockPlan = Partial<typeof PlanModel> & IPlan;

type PlanCollection<Collection> = Collection & {
  add: (p: MockPlan) => MockPlan;
  models: MockPlan[];
};

export type Predictor = {
  slug: string;
  predictor_id: number;
};

export type PublicSiteRegistry = {
  careCollection: {
    token: string;
    name: string;
    plans: PlanCollection<ModelInstance<MockPlan>>;
    add: (p: MockPlan) => MockPlan;
  };
  organization: IOrganization;
  plan: MockPlan;
  featureFlagSet: FeatureFlags;
  predictor: Predictor;
};

export type PublicSiteSchema = Schema<PublicSiteRegistry>;

export interface IServerRoute {
  [url: string]: (
    server: Server<PublicSiteRegistry>,
    request?: Request,
  ) => void;
}
