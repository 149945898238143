import { useEffect, useState } from 'react';
import { useTheme } from 'styled-components';
import convertRemStringToPixels from 'utils/convertRemStringToPixels';
import useBreakpointIsMobile from './useBreakpointIsMobile';

/** Returns current top nav height as a value in pixels based on window size */
const useTopNavPixelHeight = () => {
  const theme = useTheme();
  const isMobile = useBreakpointIsMobile();

  const [topNavHeight, setTopNavHeight] = useState<number>();

  useEffect(() => {
    const height = isMobile
      ? theme.sizes.topBarHeightMobile
      : theme.sizes.topBarHeightTablet;
    setTopNavHeight(convertRemStringToPixels(height));
  }, [isMobile]);

  return topNavHeight;
};

export default useTopNavPixelHeight;
