import memoize from 'lodash/memoize';

import type { SanityDocument } from 'types';

export default memoize((pages: unknown[]): SanityDocument[] => {
  if (!pages?.length) {
    return [];
  }

  return pages.map((page: any) => ({
    articleType: page?.type || null,
    categories: page?.categories || [],
    firstName: page?.firstName || '',
    honorifics: page?.honorifics || null,
    language: page?.language || null,
    lastName: page?.lastName || '',
    name: page?.name || '',
    slug: page.slug,
    title: page.title,
    type: page?._type || null,
  }));
});
