import getHost from 'utils/getHost';
import Environments from 'constants/Environments';

const isProd = () => {
  /*
   * Server
   */
  if (typeof window === 'undefined') {
    // If this env var is defined, app is running on vercel
    if (process.env.VERCEL !== undefined || process.env.ENVIRONMENT === 'stage')
      return false;

    return process.env.NODE_ENV === 'production';
  }

  /*
   * Browser
   */
  return getHost() === Environments.PRODUCTION.host;
};

export default isProd;
