import get from 'utils/get';

import sanitizeImage from 'state/sanitizers/sanitizeImage';

import {
  CalloutColor,
  CalloutColorMap,
  GeneralModuleColor,
  GeneralModuleColorMap,
  EmphasizeModuleColor,
  EmphasizeModuleColorMap,
  SplitCtaColor,
  SplitCtaColorMap,
} from 'constants/ColorScheme';

import { ModuleOptions } from 'types';

function sanitizeModuleOptions(module: unknown): ModuleOptions {
  const calloutColor: CalloutColor =
    CalloutColorMap[get(module, 'moduleOptions.moduleColor', '#bed4fc')] ||
    'BLUE';
  const generalModuleColor: GeneralModuleColor =
    GeneralModuleColorMap[
      get(module, 'moduleOptions.moduleColor', '#ffffff')
    ] || 'WHITE';
  const emphasizeModuleColor: EmphasizeModuleColor =
    EmphasizeModuleColorMap[
      get(module, 'moduleOptions.moduleColor', '#ffffff')
    ] || 'WHITE';
  const splitCtaModuleColor: SplitCtaColor =
    SplitCtaColorMap[get(module, 'moduleOptions.moduleColor', '#ffffff')] ||
    'WHITE';

  const determineModuleColorByType = () => {
    switch (get(module, '_type', '')) {
      case 'splitCta':
        return splitCtaModuleColor;
      case 'emphasisLinkCard':
      case 'singleBlockCta':
        return emphasizeModuleColor;
      case 'testerRecruitmentCta':
        return calloutColor;
      case 'cta':
      default:
        return generalModuleColor;
    }
  };

  return {
    color: determineModuleColorByType(),
    showBorderTop: get(module, 'moduleOptions.showBorderTop', false),
    showBorderBottom: get(module, 'moduleOptions.showBorderBottom', false),
    callout: {
      isActive: get(module, 'moduleOptions.displayWithCallout', false),
      color:
        CalloutColorMap[get(module, 'moduleOptions.calloutColor', '#bed4fc')] ||
        'BLUE',
      infoSectionIsActive: !get(
        module,
        'moduleOptions.displayWithoutInfoSection',
        false,
      ),
      variant: get(module, 'moduleOptions.calloutVariant', 'right'),
      infoSectionImage: sanitizeImage(
        get(module, 'moduleOptions.infoSectionImage'),
      ),
      infoSectionText: get(module, 'moduleOptions.infoSectionText', []),
      supportingSectionImage: sanitizeImage(
        get(module, 'moduleOptions.supportingSectionImage'),
      ),
      supportingSectionText: get(
        module,
        'moduleOptions.supportingSectionText',
        [],
      ),
    },
  };
}

export default sanitizeModuleOptions;
