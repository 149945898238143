import memoize from 'lodash/memoize';

import { ProsAndConsList } from 'types';

export default memoize((module: any): ProsAndConsList => {
  return {
    type: module._type || 'prosAndConsList',
    id: module._key || '',
    cons: module.cons || [],
    pros: module.pros || [],
  };
});
