import { EN_US } from 'constants/LocaleCodes';

import type { Action } from 'types';

export type I18nReducer = {
  locale: string;
};

function getLocale() {
  /**
   * The following code is a workaround to get the locale from the HTML element.
   * TODO: Create a context provider to resolve locale, remove client vs server side conditional, and support
   * client-side language switching.
   */

  // client-side
  if (typeof window !== 'undefined' && document.documentElement.lang) {
    return document.documentElement.lang === 'en'
      ? EN_US
      : document.documentElement.lang;
  }

  // server side
  return EN_US;
}

const INITIAL_STATE = {
  locale: getLocale(),
};

function i18nReducer(state = INITIAL_STATE, action: Action): I18nReducer {
  switch (action?.type) {
    case 'LOCALE_UPDATED':
      return {
        ...state,
        locale:
          action?.payload === 'en'
            ? EN_US
            : action?.payload
            ? action.payload
            : state.locale,
      };
    default:
      return state;
  }
}

export default i18nReducer;
