import Script from 'next/script';
import React from 'react';
import { shouldHideDevTools } from '../dev/DevTools/utils';
import useFeatureFlags from '../hooks/useFeatureFlags';

const AdShield = () => {
  const featureFlags = useFeatureFlags();

  if (
    shouldHideDevTools() &&
    featureFlags?.['growth-public-site-ad-shield-enabled']
  ) {
    console.log('Rendering Ad Shield');
    return (
      <Script
        key="adshield"
        id="iFSmm"
        data-sdk="1.0.0"
        data-cfasync="false"
        src="//html-load.com/loader.min.js"
        charset="UTF-8"
        data="fkad3th5sgweqh5s5h55hjsy7sihqsfhmhjss\h5qsgslhjhmqbhmhmsg\h5hqsgslswhmss\h5sghmgsgmjqq\h5hqbsfhms7ss\h5ws2os9sgs5s2hjw7si\h5u5mhjsy7siuh58su"
        onload="let l=!1,k=Math.random().toString(),a='html-load.com';window.addEventListener('message',e=>{e.data===k+'_as_res'&&(l=!0)}),window.postMessage(k+'_as_req','*'),new Promise(e=>{let t=Math.random().toString(),r=Math.random().toString();window.addEventListener('message',e=>e.data===t&&window.postMessage(r,'*')),window.addEventListener('message',t=>t.data===r&&e()),window.postMessage(t,'*')}).then(()=>{window.setTimeout(()=>{if(!l){let e='Failed to load website properly since '+a+' is blocked. Please allow '+a;throw alert(e),fetch('https://error-report.com/report?type=loader_light&url='+btoa(location.href)+'&error='+btoa(e),{method:'POST'}).then(e=>e.text()).then(t=>{let r=document.createElement('iframe');r.src='https://info.error-report.com/modal?eventId='+t+'&error='+btoa(e),r.setAttribute('style','width: 100vw; height: 100vh; z-index: 2147483647; position: fixed; left: 0; top: 0;'),document.body.appendChild(r);let o=e=>{'close-error-report'===e.data&&(r.remove(),window.removeEventListener('message',o))};window.addEventListener('message',o)}).catch(e=>{confirm(navigator.language.toLowerCase().startsWith('ko')?'잘못된 애드블록 필터로 웹페이지 로딩에 실패하였습니다. 애드블록을 비활성화한 후 웹사이트를 이용해주세요. (자세한 내용은 \'확인\' 버튼을 눌러 확인해주세요)':'This page could not be loaded properly due to incorrect / bad filtering rule(s) of adblockers in use. Please disable all adblockers to continue using the website. (click OK if you\'d like to learn more)')?location.href='https://info.error-report.com/modal?eventId=&error='+btoa(e.toString()):location.reload()}),Error(e)}},1e3)});"
        onerror="let e=document.getElementById('iFSmm'),t=document.createElement('script');for(let r=0;r<e.attributes.length;r++)t.setAttribute(e.attributes[r].name,e.attributes[r].value);let s=new window.URL(e.src),a=['html-load.com','fb.html-load.com','content-loader.com','fb.content-loader.com'];if(void 0===window.as_retry&&(window.as_retry=0),window.as_retry>=a.length){let o='Failed to load website properly since '+a[0]+' is blocked. Please allow '+a[0];throw alert(o),fetch('https://error-report.com/report?type=loader_light&url='+btoa(location.href)+'&error='+btoa(o),{method:'POST'}).then(e=>e.text()).then(e=>{let r=document.createElement('iframe');r.src='https://info.error-report.com/modal?eventId='+e+'&error='+btoa(o),r.setAttribute('style','width: 100vw; height: 100vh; z-index: 2147483647; position: fixed; left: 0; top: 0;'),document.body.appendChild(r);let f=e=>{'close-error-report'===e.data&&(r.remove(),window.removeEventListener('message',f))};window.addEventListener('message',f)}).catch(o=>{let l=navigator.language.toLowerCase().startsWith('ko')?'잘못된 애드블록 필터로 웹페이지 로딩에 실패하였습니다. 애드블록을 비활성화한 후 웹사이트를 이용해주세요. (자세한 내용은 \'확인\' 버튼을 눌러 확인해주세요)':'This page could not be loaded properly due to incorrect / bad filtering rule(s) of adblockers in use. Please disable all adblockers to continue using the website. (click OK if you\'d like to learn more)';confirm(l)?location.href='https://info.error-report.com/modal?eventId=&error='+btoa(o.toString()):location.reload()}),Error(o)}s.host=a[window.as_retry++],t.setAttribute('src',s.href),t.setAttribute('as-async','true'),e.setAttribute('id', e.getAttribute('id')+'_'),e.parentNode.insertBefore(t,e),e.remove();"
      />
    );
  }

  return null;
};

export default AdShield;
