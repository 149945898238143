import { DefaultSeoSettings } from 'constants/Default';

import {
  Action,
  ErrorPage,
  Footer,
  NavList,
  NotificationBar,
  PopUp,
  SeoSettings,
  TrendingArticleImage,
} from 'types';

export interface GlobalSettingsReducer {
  navigation: NavList;
  trendingArticleImages: TrendingArticleImage[];
  notificationBar: NotificationBar;
  footer: Footer;
  popUp: PopUp;
  errorPage: ErrorPage;
  seo: SeoSettings;
  instagramToken: string;
}

const initialState: GlobalSettingsReducer = {
  navigation: {},
  trendingArticleImages: [],
  notificationBar: {
    isActive: false,
    text: [],
  },
  footer: {
    social: [],
    menu: [],
    termsSlug: '',
    privacySlug: '',
    cookiesSlug: '',
    footerImage: null,
  },
  popUp: {
    isActive: false,
    text: [],
    continueButtonLabel: '',
    secondaryButtonLabel: '',
    secondaryButtonLink: '',
  },
  errorPage: {
    title: '',
  },
  seo: {
    title: DefaultSeoSettings.title,
    description: DefaultSeoSettings.description,
    image: DefaultSeoSettings.image,
    structuredData: DefaultSeoSettings.structuredData,
    canonicalUrl: DefaultSeoSettings.canonicalUrl,
  },
  instagramToken: '',
};

function globalSettings(
  state: GlobalSettingsReducer = initialState,
  action: Action,
): GlobalSettingsReducer {
  switch (action.type) {
    case 'FETCH_GLOBAL_SETTINGS_FULFILLED':
      return { ...state, ...action.payload };
    case 'FETCH_TRENDING_ARTICLES_IMAGES_FULFILLED': {
      return {
        ...state,
        trendingArticleImages: action.payload,
      };
    }
    default:
      return state;
  }
}

export default globalSettings;
