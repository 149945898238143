import memoize from 'lodash/memoize';
import get from 'utils/get';
import { DxListModule } from 'types';
import sanitizeImage from 'state/sanitizers/sanitizeImage';

// Sanitize Dx List Module as generic Round Icon List Module
export default memoize((module: unknown): DxListModule => {
  return {
    type: get(module, '_type', 'dxList'),
    id: get(module, '_key', ''),
    moduleTitle: get(module, 'moduleTitle', ''),
    items: get(module, 'referencedArticles', []).map(
      (item: unknown, itemIndex: number) => {
        const itemType = get(item, '_type');

        if (itemType === 'article') {
          return {
            id: get(item, '_id', ''),
            title: get(item, 'shortTitle', '') || get(item, 'title', ''),
            diagnosisDescription: get(item, 'diagnosisDescription', ''),
            commonSymptoms: get(item, 'commonSymptoms', []),
            image: sanitizeImage(get(item, 'image')),
            imageBgColor: get(item, 'imageBgColor', ''),
            slug: get(item, 'slug', ''),
            symptomCheckerButtonUrl: get(item, 'symptomCheckerButtonUrl', ''),
          };
        } else {
          const manualItem = get(module, `manualItems[${itemIndex}]`);

          return {
            id: get(manualItem, '_id', ''),
            title: get(manualItem, 'title', ''),
            image: sanitizeImage(get(manualItem, 'image', '')),
          };
        }
      },
    ),
  };
});
