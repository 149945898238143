import { SurveyResponsePagesById, SurveyResponseRequestBodyPage } from 'types';

const formatSurveyResponseRequestBody = (
  surveyResponsePages: SurveyResponsePagesById
): SurveyResponseRequestBodyPage[] => {
  return Object.values(surveyResponsePages).reduce(
    (pages: SurveyResponseRequestBodyPage[], page) => {
      const formattedPage = {
        id: page.id,
        questions: Object.values(page.questions),
      };

      // If no answers are set on the page, we should not send an empty array
      if (!formattedPage.questions.length) return pages;

      return pages.concat(formattedPage);
    },
    []
  );
};

export default formatSurveyResponseRequestBody;
