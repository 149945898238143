import { RouteMap } from 'constants/Routes';
import { ArticleType } from 'types';

const getArticleBase = (articleType?: ArticleType | null) => {
  switch (articleType) {
    case ArticleType.Cost:
      return RouteMap.COST.base;
    default:
      return RouteMap.ARTICLE.base;
  }
};

const getDefaultArticleUrl = ({
  slug,
  language,
  articleType,
}: {
  slug: string;
  language?: string;
  articleType?: ArticleType | null;
}): string => {
  return `${getArticleBase(articleType)}${
    language && language !== 'en' ? `/${language}` : ''
  }${slug}`;
};

export default getDefaultArticleUrl;
