import { createSelector } from 'reselect';

import toCamelCase from 'utils/toCamelCase';

import { GlobalState, NavList } from 'types';

export default createSelector(
  (state: GlobalState) => state.globalSettings.navigation,
  (navigation: NavList): string[] => {
    return Object.values(navigation)
      .filter((item) => !item.parent)
      .sort((a, b) => a.order - b.order)
      .map((item) => toCamelCase(item.label));
  },
);
