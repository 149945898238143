import { createContext } from 'react';
import noop from 'lodash/noop';

import type {
  IEventEmitter,
  MiniInterviewType,
} from '@buoy-components/polaris/types';

const PolarisPopUpContext = createContext<{
  activeInterview?: MiniInterviewType | null;
  eventEmitter?: IEventEmitter | null;
  isPolarisDisabled: boolean;
  isPopupOpen: boolean;
  setActiveInterview: (interview: MiniInterviewType) => void;
  setIsPolarisDisabled: (open: boolean) => void;
  setIsPopupOpen: (open: boolean) => void;
}>({
  activeInterview: null,
  eventEmitter: null,
  isPolarisDisabled: true,
  isPopupOpen: false,
  setActiveInterview: noop,
  setIsPolarisDisabled: noop,
  setIsPopupOpen: noop,
});

export default PolarisPopUpContext;
