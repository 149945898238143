import memoize from 'lodash/memoize';
import get from 'utils/get';

import sanitizeAccordionListModule from 'state/sanitizers/modules/sanitizeAccordionListModule';
import sanitizeAccordionListWithAnimationModule from 'state/sanitizers/modules/sanitizeAccordionListWithBlueAccentsModule';
import sanitizeAnimationAndTextModule from 'state/sanitizers/modules/sanitizeAnimationAndTextModule';
import sanitizeCarouselModule from 'state/sanitizers/modules/sanitizeCarouselModule';
import sanitizeEmphasisLinkCardModule from 'state/sanitizers/modules/sanitizeEmphasisLinkCardModule';
import sanitizeFourColumnVideoModule from 'state/sanitizers/modules/sanitizeFourColumnVideoModule';
import sanitizeGoBackModule from 'state/sanitizers/modules/sanitizeGoBackModule';
import sanitizeGoogleFormModule from './modules/sanitizeGoogleFormModule';
import sanitizeInsuranceFormModule from './modules/sanitizeInsuranceFormModule';
import sanitizeHighlightedImagesModule from 'state/sanitizers/modules/sanitizeHighlightedImagesModule';
import sanitizeImageCardsModule from 'state/sanitizers/modules/sanitizeImageCardsModule';
import sanitizeInstagramFeedModule from 'state/sanitizers/modules/sanitizeInstagramFeedModule';
import sanitizeJobPostingsModule from 'state/sanitizers/modules/sanitizeJobPostingsModule';
import sanitizeLargeTextModule from 'state/sanitizers/modules/sanitizeLargeTextModule';
import sanitizeLinkCardsModule from 'state/sanitizers/modules/sanitizeLinkCardsModule';
import sanitizeLinkCardWithImageModule from 'state/sanitizers/modules/sanitizeLinkCardWithImageModule';
import sanitizeLinksListModule from 'state/sanitizers/modules/sanitizeLinksListModule';
import sanitizeListItemsAndLogosModule from 'state/sanitizers/modules/sanitizeListItemsAndLogosModule';
import sanitizeLogosModule from 'state/sanitizers/modules/sanitizeLogosModule';
import sanitizeNumberedListWithImageModule from 'state/sanitizers/modules/sanitizeNumberedListWithImageModule';
import sanitizeQuoteModule from 'state/sanitizers/modules/sanitizeQuoteModule';
import sanitizeSingleBlockCtaModule from 'state/sanitizers/modules/sanitizeSingleBlockCtaModule';
import sanitizeSliderModule from 'state/sanitizers/modules/sanitizeSliderModule';
import sanitizeSplitCtaModule from 'state/sanitizers/modules/sanitizeSplitCtaModule';
import sanitizeSupplierCtaModule from './modules/sanitizeSupplierCtaModule';
import sanitizeTeamListModule from 'state/sanitizers/modules/sanitizeTeamListModule';
import sanitizeTextModule from 'state/sanitizers/modules/sanitizeTextModule';
import sanitizeThreeColumnAnimationModule from './modules/sanitizeThreeColumnAnimationModule';
import sanitizeThreeColumnInfoModule from 'state/sanitizers/modules/sanitizeThreeColumnInfoModule';
import sanitizeThumbnailCardsModule from 'state/sanitizers/modules/sanitizeThumbnailCardsModule';
import sanitizeVideoModule from 'state/sanitizers/modules/sanitizeVideoModule';
import sanitizeVideoAndListItemsModule from 'state/sanitizers/modules/sanitizeVideoAndListItemsModule';
import sanitizeVideoCtaModule from 'state/sanitizers/modules/sanitizeVideoCtaModule';
import sanitizeTypeformModule from './modules/sanitizeTypeformModule';

import { Module, PageHeroModule } from 'types';

export default memoize((modules: unknown[]): Module[] | PageHeroModule[] => {
  return modules.map((module) => {
    const type = get(module, '_type');

    if (!type) {
      return null;
    }

    switch (type) {
      case 'accordionList':
        return sanitizeAccordionListModule(module);
      case 'accordionListWithAnimation':
        return sanitizeAccordionListWithAnimationModule(module);
      case 'animationAndTextModule':
        return sanitizeAnimationAndTextModule(module);
      case 'carousel':
        return sanitizeCarouselModule(module);
      case 'emphasisLinkCard':
        return sanitizeEmphasisLinkCardModule(module);
      case 'goBack':
        return sanitizeGoBackModule(module);
      case 'fourColumnVideo':
        return sanitizeFourColumnVideoModule(module);
      case 'googleFormModule':
        return sanitizeGoogleFormModule(module);
      case 'insuranceFormModule':
        return sanitizeInsuranceFormModule(module);
      case 'highlightedImages':
        return sanitizeHighlightedImagesModule(module);
      case 'imageCards':
        return sanitizeImageCardsModule(module);
      case 'instagramFeed':
        return sanitizeInstagramFeedModule(module);
      case 'jobPostings':
        return sanitizeJobPostingsModule(module);
      case 'largeText':
        return sanitizeLargeTextModule(module);
      case 'linkCards':
        return sanitizeLinkCardsModule(module);
      case 'linkCardWithImage':
        return sanitizeLinkCardWithImageModule(module);
      case 'linksList':
        return sanitizeLinksListModule(module);
      case 'listItemsAndLogosModule':
        return sanitizeListItemsAndLogosModule(module);
      case 'logos':
        return sanitizeLogosModule(module);
      case 'numberedListWithImage':
        return sanitizeNumberedListWithImageModule(module);
      case 'quote':
        return sanitizeQuoteModule(module);
      case 'singleBlockCta':
        return sanitizeSingleBlockCtaModule(module);
      case 'splitCta':
        return sanitizeSplitCtaModule(module);
      case 'slider':
        return sanitizeSliderModule(module);
      case 'supplierCtaModule':
        return sanitizeSupplierCtaModule(module);
      case 'teamList':
        return sanitizeTeamListModule(module);
      case 'textModule':
        return sanitizeTextModule(module);
      case 'threeColumnAnimationModule':
        return sanitizeThreeColumnAnimationModule(module);
      case 'threeColumnInfo':
        return sanitizeThreeColumnInfoModule(module);
      case 'thumbnailCards':
        return sanitizeThumbnailCardsModule(module);
      case 'typeformModule':
        return sanitizeTypeformModule(module);
      case 'videoModule':
        return sanitizeVideoModule(module);
      case 'videoAndListItemsModule':
        return sanitizeVideoAndListItemsModule(module);
      case 'videoCtaModule':
        return sanitizeVideoCtaModule(module);
      default:
        return null;
    }
  });
});
