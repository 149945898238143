import memoize from 'lodash/memoize';
import get from 'utils/get';

import sanitizeImage from 'state/sanitizers/sanitizeImage';

import { TeamMember } from 'types';

export default memoize((teamMember: unknown): TeamMember | null => {
  const id = get(teamMember, '_id');

  if (!id) {
    return null;
  }

  const firstName = get(teamMember, 'firstName', '');
  const lastName = get(teamMember, 'lastName', '');

  return {
    id: get(teamMember, '_id', ''),
    firstName,
    lastName,
    fullName: `${firstName} ${lastName}`,
    slug: get(teamMember, 'slug', ''),
    jobTitle: get(teamMember, 'jobTitle', ''),
    image: sanitizeImage(get(teamMember, 'image')),
    bio: get(teamMember, 'bio', []),
  };
});
