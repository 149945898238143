import transitions from './transitions';
import theme from '@buoyhealth/common.buoy-theme';
import { Color } from './colors';

const { palette } = theme;

const LINK_BORDER_BOTTOM_HIDDEN = {
  backgroundSize: '0% 1px',
};

const LINK_BORDER_BOTTOM = {
  backgroundSize: '100% 1px',
};

const composeBase = (color: Color) => {
  return {
    backgroundImage: `linear-gradient(${color}, ${color})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'left 90%',
    ...transitions['transition-underline'],
  };
};

const linkBorderBottom = (color: Color) => {
  return {
    ...composeBase(color),
    ...LINK_BORDER_BOTTOM,
  };
};

const linkBorderBottomHidden = (color: Color) => {
  return {
    ...composeBase(color),
    ...LINK_BORDER_BOTTOM_HIDDEN,
  };
};

export default {
  linkBorderBottom,
  linkBorderBottomHidden,
  boxShadowBlue: {
    boxShadow: `0px 0px 4px ${palette.blue[40]}, inset 0px 0px 4px ${palette.blue[40]}`,
  },
  boxShadowGray: {
    boxShadow: 'inset 0px 0px 6px rgba(0, 0, 0, 0.1)',
  },
};
