import { useSelector } from 'react-redux';
import { GlobalState } from 'types';

export function useGlobalSettings() {
  const globalSettingsFromState = useSelector(
    (state: GlobalState) => state.globalSettings
  );

  return globalSettingsFromState;
}
