import React, { useCallback, useContext } from 'react';
import { useDispatch } from 'react-redux';
import { fetchTrendingArticlesImages } from 'state/actions/globalSettingsActions';
import { useGlobalSettings } from 'state/selectors/globalSettings';
import { AnchorLabel, Base } from 'styled/components/base';
import { NavigationContext } from '../contexts';
import { NavBarLink } from '../DesktopNavBar.style';

import type { FC, MouseEvent } from 'react';
import type { TrendingArticle } from '../../types';

type IDesktopNavLinkProps = {
  articles: TrendingArticle[];
  icon?: FC<{
    className?: string;
    isReverse?: boolean;
  }> | null;
  iconIsReverse?: boolean;
  id: string;
  label: string;
  slug: string;
  tabIndex?: number;
  onClick?: () => void;
  onHover?: () => void;
};

function DesktopNavLink(props: IDesktopNavLinkProps) {
  const {
    articles,
    icon,
    iconIsReverse = false,
    id,
    label,
    slug,
    tabIndex,
    onClick,
    onHover,
  } = props;

  const dispatch = useDispatch();
  const { trendingArticleImages } = useGlobalSettings();

  const { setActiveMenu, setTrending } = useContext(NavigationContext);

  const handleMouseOver = useCallback(
    (event: React.FocusEvent<HTMLElement, Element> | MouseEvent) => {
      event.stopPropagation();

      onHover?.();

      // When nav opens, fetch trending article images if we don't already have them
      if (!trendingArticleImages?.length) {
        const action = fetchTrendingArticlesImages();
        dispatch(action);
      }

      setActiveMenu(id);
      setTrending({
        articles,
        label,
        slug,
      });
    },
    [
      articles,
      dispatch,
      id,
      label,
      onHover,
      setActiveMenu,
      setTrending,
      slug,
      trendingArticleImages?.length,
    ],
  );

  return (
    <NavBarLink
      $underlineOnHover={!!slug}
      ariaLabel={label}
      onFocus={handleMouseOver}
      onMouseEnter={handleMouseOver}
      tabIndex={tabIndex}
      to={slug}
      onClick={onClick}
    >
      <AnchorLabel>{label}</AnchorLabel>
      {icon && <Base as={icon} isReverse={iconIsReverse} />}
    </NavBarLink>
  );
}

export default DesktopNavLink;
