import styled from 'styled-components';

type ArticleImgProps = {
  src?: string;
};

export const ArticleItemImage = styled.span<ArticleImgProps>`
  align-items: center;
  background-color: ${({ color, theme }) => color || theme.palette.gray[10]};
  display: flex;
  height: 48px;
  justify-content: center;
  min-height: 48px;
  min-width: 48px;
  overflow: hidden;
  padding: 0 !important;
  width: 48px;
  align-self: flex-start;
  border-radius: 4px;

  img {
    height: auto;
    margin: 0 auto;
    width: 100%;
  }

  svg {
    height: 100%;
    margin: 0;
    width: 100%;
  }
`;
