import React, { useCallback, useContext, useEffect, useState } from 'react';
import { AnchorLabel } from 'styled/components/base';
import { AccordionContext } from '../contexts';
import { NavLink } from '../MobileNavBar.style';
import {
  MenuDropdown,
  MenuDropdownItems,
  MenuIconDown,
  MenuIconUp,
} from './MobileMenuDropdown.style';

import type { PropsWithChildren } from 'react';

type Props = PropsWithChildren<{
  id: string;
  label: string;
  trackMenuOpen: () => void;
}>;

function MobileMenuDropdown(props: Props) {
  const { children, id, label, trackMenuOpen } = props;

  const { activeAccordionId, setActiveAccordionId } =
    useContext(AccordionContext);
  const [open, setOpen] = useState(false);

  const handleClick = useCallback(() => {
    setActiveAccordionId(id);
    setOpen(!open);
    if (!open) {
      trackMenuOpen();
    }
  }, [id, open, setActiveAccordionId, trackMenuOpen]);

  useEffect(() => {
    if (activeAccordionId !== id) {
      setOpen(false);
    }
  }, [activeAccordionId, id]);

  return (
    <MenuDropdown>
      <NavLink ariaLabel={label} onClick={handleClick}>
        <AnchorLabel>{label}</AnchorLabel>
        {open ? <MenuIconUp /> : <MenuIconDown />}
      </NavLink>

      <MenuDropdownItems className={open ? 'open' : 'close'}>
        {children}
      </MenuDropdownItems>
    </MenuDropdown>
  );
}

export default MobileMenuDropdown;
