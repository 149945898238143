import memoize from 'lodash/memoize';
import get from 'utils/get';

import sanitizeDrugLink, { IDrugLink } from 'state/sanitizers/sanitizeDrugLink';

export default memoize((drugLinks: unknown): IDrugLink[] => {
  if (!get(drugLinks, 'length', 0)) return [];

  const sanitizedDrugLinks = (drugLinks as unknown[]).map((drugLink: unknown) =>
    sanitizeDrugLink(drugLink),
  );

  return sanitizedDrugLinks;
});
