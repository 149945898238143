import getConfig from 'next/config';

const { publicRuntimeConfig = {}, serverRuntimeConfig = {} } =
  getConfig() || {};

export const AWS_RUM_ENABLED = publicRuntimeConfig.AWS_RUM_ENABLED;
export const ENVIRONMENT = publicRuntimeConfig.ENVIRONMENT;
export const LAUNCHDARKLY_LOGS_ENABLED =
  publicRuntimeConfig.LAUNCHDARKLY_LOGS_ENABLED === 'true';
export const LAUNCHDARKLY_SDK_KEY = serverRuntimeConfig.LAUNCHDARKLY_SDK_KEY;
export const REDUX_LOGS_ENABLED =
  publicRuntimeConfig.REDUX_LOGS_ENABLED === 'true';
export const SENTRY_DSN = publicRuntimeConfig.SENTRY_DSN;
export const SENTRY_ENABLED = publicRuntimeConfig.SENTRY_ENABLED;
export const SNOWPLOW_COLLECTOR_URL =
  publicRuntimeConfig.SNOWPLOW_COLLECTOR_URL || 'localhost:9090';
export const SANITY_DATASET =
  publicRuntimeConfig.SANITY_DATASET || 'buoy-public-site';
