import memoize from 'lodash/memoize';
import get from 'utils/get';

import sanitizeModuleOptions from 'state/sanitizers/modules/sanitizeModuleOptions';

import { DataSetVisualsModule } from 'types';

export default memoize((module: unknown): DataSetVisualsModule => {
  return {
    type: get(module, '_type', 'dataSetVisuals'),
    id: get(module, '_key', ''),
    moduleTitle: get(module, 'moduleTitle', ''),
    disclaimer: get(module, 'disclaimer', []),
    dataDescription: get(module, 'dataDescription', []),
    dataList: get(module, 'dataList', []).map((data: unknown) => {
      return {
        id: get(data, '_key', ''),
        name: get(data, 'name', ''),
        percentage: get(data, 'percentage', 0),
        description: get(data, 'description', ''),
        section: get(data, 'section', ''),
      };
    }),
    additionalDataLabel: get(module, 'additionalDataLabel', ''),
    additionalData: get(module, 'additionalData', []),
    moduleOptions: sanitizeModuleOptions(module),
  };
});
