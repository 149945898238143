import { DEFAULT_ENABLED_ENDPOINTS } from 'dev/mock-api/server';
import { IncomingMessage } from 'http';
import ClientCookies from 'js-cookie';
import cookies from 'next-cookies';
import isBuoyStaging from 'utils/isBuoyStaging';
import isCypress from 'utils/isCypress';
import isProd from 'utils/isProd';

const COOKIE_NAME = 'dev-tools';

type DevToolsCookie = {
  enabledEndpoints?: string[];
  featureFlags?: { [key: string]: boolean | string }[];
  hideDevToolsButton?: boolean;
  isMockApiEnabled?: boolean;
  isMockFeatureFlagEnabled?: boolean;
};

/** Environment check for loading dev tools; only enable locally or in vercel. */
export const shouldHideDevTools = () =>
  isCypress() || isProd() || isBuoyStaging();

const getDefaultValues: () => DevToolsCookie = () => ({
  isMockApiEnabled: !shouldHideDevTools(),
  enabledEndpoints: DEFAULT_ENABLED_ENDPOINTS,
});

/** Fetches and parses the dev tools cookie. Returns default values if cookie is not set. */
export function getCookie(req?: IncomingMessage) {
  let cookieValue = getDefaultValues();

  if (typeof window !== 'undefined') {
    /** Client */
    const value = ClientCookies.get(COOKIE_NAME);
    if (value) {
      cookieValue = JSON.parse(value) as DevToolsCookie;
    }
  } else if (req) {
    /** Server */
    const value = cookies({ req })[COOKIE_NAME];
    if (value) {
      cookieValue = value as DevToolsCookie;
    }
  }

  return cookieValue;
}

/** Set dev tools cookie and reload page */
export const setCookie = (updatedValues: DevToolsCookie) => {
  const value: DevToolsCookie = {
    ...getCookie(),
    ...updatedValues,
  };

  ClientCookies.set(COOKIE_NAME, JSON.stringify(value), {
    expires: 7,
  });

  window.location.reload();
};

/** Mirage only intercepts client-side api calls. This helper checks if we should manually load mock data for server-side api calls. */
export const shouldManuallyLoadMockData = (
  ep: string,
  req?: IncomingMessage,
) => {
  if (shouldHideDevTools() || !req) {
    /*  If req is _not_ passed, this is a client-side call.
        In this case, do not manually load mock data because Mirage will intercept it.*/
    return false;
  }
  const { isMockApiEnabled, enabledEndpoints } = getCookie(req);
  return isMockApiEnabled && enabledEndpoints?.includes(ep);
};
