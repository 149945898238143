import styled from 'styled-components';
import { Base } from 'styled/components/base';
import { Anchor, AnchorInner } from '../Link/style';

export const StyledButton = styled(Anchor)`
  background-color: transparent;
`;

export const StyledBaseButton = styled(Base)`
  background-color: transparent;
`;

export const StyledAnchor = styled(Anchor)``;

export const StyledAnchorInner = styled(AnchorInner)``;
