const isCypress = () => {
  /*
   * Server
   */
  if (typeof window === 'undefined') {
    return false;
  }

  /*
   * Browser
   */
  return !!window.Cypress;
};

export default isCypress;
