import memoize from 'lodash/memoize';
import get from 'utils/get';

import sanitizeModuleOptions from 'state/sanitizers/modules/sanitizeModuleOptions';

import { FontSizes } from 'styled/theme/typography';

import { QuoteModule } from 'types';

export default memoize((module: unknown): QuoteModule => {
  return {
    type: get(module, '_type', 'quote'),
    id: get(module, '_key', ''),
    quote: get(module, 'quote', []),
    quoteDesktopFontSize: get(module, 'quoteDesktopFontSize', FontSizes.xl),
    attribution: get(module, 'attribution', ''),
    moduleOptions: sanitizeModuleOptions(module),
  };
});
